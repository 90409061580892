/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import "../../CSS/DataModal.css";
import { Spinner, Image } from 'react-bootstrap';
import {Event} from "../GoogleAnalytics";
import GetAppIcon from '@mui/icons-material/GetApp';

const csvBaseName = "FalsePositiveAlertCounts";
function convertToCSV(objArray) {
    let csv = "Alert Type,Alert Type Count\n";

    objArray.forEach(item => {
        csv += `${item.alertTypeName},${item.alertTypeCount}\n`;
    });

    return csv;
};

function downloadCSV(data, assetType, startTime, endTime) {
    Event("Download Data Clicked", "User clicked download data button on False Positive Alert Counts", "Download Data Clicked");
    const formattedStartDate = new Date(startTime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).replace(/\//g, '-');
    const formattedEndDate = new Date(endTime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).replace(/\//g, '-');

    const dateRange = `${formattedStartDate} - ${formattedEndDate}`;
    let filename = assetType + "_" + csvBaseName + "_" + dateRange + ".csv";
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(data));

    const link = document.createElement("a");
    link.setAttribute("href", csvContent); // Set csvContent directly
    link.setAttribute("download", filename || "data.csv");
    document.body.appendChild(link);
    link.click();
};

function FalsePositiveTypeModal(props) {
    const { classes, show, selectedAssetType, startTime, endTime, fpData, loading, onClick, _isMounted } = props;

    const onModalClick = (e) => {
        onClick && onClick(e);
    };

    const onCancelClick = () => {

    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        // Pad single-digit month and day with leading zero
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedMonth}/${formattedDay}/${year}`;
    }

    let alertTypeHeader =
        <div style={{ height: '24px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
            <div style={{ display: "flex", margin: "0 auto", border: "2px solid #ebeef0" }}>
                        <span style={{}}>
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ margin: '10px 10px 0 10px' }}><b>ASSET TYPE:</b></div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0 10px 10px 10px' }}>{selectedAssetType}</div>
                            </div>
                        </span>
            </div>
            <div style={{ display: "flex", margin: "0 auto", border: "2px solid #ebeef0" }}>
                        <span style={{}}>
                            <div style={{ textAlign: 'left' }}>
                                <div style={{ margin: '10px 10px 0 10px' }}><b>DATE RANGE:</b></div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ margin: '0 10px 10px 10px' }}>{`${formatDate(startTime)} - ${formatDate(endTime)}`}</div>
                            </div>
                        </span>
            </div>
        </div>;

    let alertTypeContent = loading ?
        <div style={{ display: "block" }}>
            <div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div></div>
        :
        fpData.length ?
            <tbody>
            {fpData.map((entry, index) =>
                [<tr className="alerts-table-row" key={`${entry.alertTypeId}-tableRow`} data={index} value={index} >
                    <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                        {entry.alertTypeName}
                    </td>
                    <td className="alerts-td-right">{entry.alertTypeCount}</td>
                </tr>
                ])}

            </tbody>
            : <tbody>
            <tr>
                <td>{"No False Positive Alert Types"}</td>
            </tr>
            </tbody>;

    return (
        <div className="modal" style={{display: show ? "block" : "none"}}>
            <div className="modal-wrapper"
                 style={{
                     transform: show ? 'translateY(0vh)' : 'translateY(-100vh)',
                     opacity: show ? '1' : '0'
                 }}>

                <div className="modal-header">
                    <h3>False Positive Alert Type Table</h3>
                    <button className="primary-btn btn-small"
                          onClick={() => downloadCSV(fpData, selectedAssetType, startTime, endTime)}>
                        <GetAppIcon />
                    </button>

                    <span className="close-modal-btn" onClick={e => {
                        onModalClick(e);
                        onCancelClick();
                    }}>×</span>
                </div>

                <div className="modal-body">
                    <br/>
                    {alertTypeHeader}
                    <br/><br/>
                    <table className="table-hover" style={{width: '100%'}}>

                        <thead>
                        <tr className="alerts-table-row">
                            <th style={{padding: '0px 0px 0px 10px'}}>
                                <span style={{cursor: 'pointer'}}>ALERT TYPE</span>
                            </th>
                            <th>
                                <span style={{cursor: 'pointer'}}>ALERT COUNT</span>
                            </th>
                            <th className="table-shrink" style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                        </tr>
                        </thead>
                        {alertTypeContent}
                    </table>
                </div>

                <div className="modal-footer">
                    <button className="primary-btn btn-small" onClick={e => {
                        onModalClick(e);
                        onCancelClick();
                    }}>Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FalsePositiveTypeModal;