/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import AssetService from '../../services/asset.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import FullScreenChartModal from './FullScreenChartModal';
import AlertEditModal from './AlertEditModal';
import CreateJobModal from './CreateJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import DevicesModal from './DevicesModal';
import memoize from 'memoize-one';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import IconTooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotificationIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Tooltip from '@mui/material/Tooltip';
import ScheduledIcon from '@mui/icons-material/DateRange';
import UnscheduledIcon from '@mui/icons-material/EventBusy';
import ServicedIcon from '@mui/icons-material/EventAvailable';
import GroupsIcon from '@mui/icons-material/People';
import InstallIcon from '@mui/icons-material/SystemUpdate';
import DeviceDownIcon from '@mui/icons-material/MobileOff';
import SignalIcon from '@mui/icons-material/SpeakerPhone';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterIcon from '@mui/icons-material/FilterList';

import DatePicker from 'react-datepicker';
import { Event, Timing, ModalView } from '../GoogleAnalytics';
import {
    jsAsset,
    alertCustomer,
    jsAlert,
    jsGraph,
    jsOpportunity,
    jsUpdateOpportunity,
    jsGraphData,
    jsSource,
    Equipment,
    TagIdsEnum
} from "../../componentObjects";
import JobDetailModal from './JobDetailModal';

import HighchartGraph from '../Graph/HighchartGraph';
import TriageAlertsTable from '../TriageAlerts/TriageAlertsTable';

import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import { TimeRightPanel } from '../Graph/TimeRightPanel';
import { RunRightPanel } from '../Graph/RunRightPanel';
import { ACRightPanel } from '../Graph/ACRightPanel';
import {Link} from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import JobRightPanel from "../Graph/JobRightPanel";

class BaseAlertPage extends Component {
    _intervalId = null;
    constructor(props) {
        super(props);

        this.onTableTabClick = this.onTableTabClick.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.onAlertClick = this.onAlertClick.bind(this);
        this.runAnalytic = this.runAnalytic.bind(this);
        this.runDailyAnalytic = this.runDailyAnalytic.bind(this);

        this.openFullRunModal = this.openFullRunModal.bind(this);
        this.showFullRunModal = this.showFullRunModal.bind(this);
        this.openFullDailyModal = this.openFullDailyModal.bind(this);
        this.showFullDailyModal = this.showFullDailyModal.bind(this);
        this.openFullMultiModal = this.openFullMultiModal.bind(this);
        this.showFullMultiModal = this.showFullMultiModal.bind(this);

        this.openJobDetailModal = this.openJobDetailModal.bind(this);
        this.showJobDetailModal = this.showJobDetailModal.bind(this);

        this.editAlert = this.editAlert.bind(this);
        this.onEditAlert = this.onEditAlert.bind(this);
        this.openEditAlertModal = this.openEditAlertModal.bind(this);
        this.showEditAlertModal = this.showEditAlertModal.bind(this);

        this.createJob = this.createJob.bind(this);
        this.onCreateJob = this.onCreateJob.bind(this);
        this.openCreateJobModal = this.openCreateJobModal.bind(this);
        this.showCreateJobModal = this.showCreateJobModal.bind(this);

        this.closeAlert = this.closeAlert.bind(this);
        this.onCloseAlert = this.onCloseAlert.bind(this);
        this.openCloseAlertModal = this.openCloseAlertModal.bind(this);
        this.showCloseAlertModal = this.showCloseAlertModal.bind(this);

        this.viewDevices = this.viewDevices.bind(this);
        this.onViewDevices = this.onViewDevices.bind(this);
        this.openDevicesModal = this.openDevicesModal.bind(this);
        this.showDevicesModal = this.showDevicesModal.bind(this);

        this.onPriorityChange = this.onPriorityChange.bind(this);
        this.onPriorityOpportunityChange = this.onPriorityOpportunityChange.bind(this);
        this.onMessageChange = this.onMessageChange.bind(this);
        this.onRecommendationChange = this.onRecommendationChange.bind(this);
        this.onFalsePositiveChange = this.onFalsePositiveChange.bind(this);
        this.onCommentsChange = this.onCommentsChange.bind(this);
        this.onSubjectChange = this.onSubjectChange.bind(this);
        this.onChangeStart = this.onChangeStart.bind(this);
        this.onChangeEnd = this.onChangeEnd.bind(this);
        this.onViewGraph = this.onViewGraph.bind(this);

        this.getJobGraphFromCache = this.getJobGraphFromCache.bind(this);
        this.getJobGraphData = this.getJobGraphData.bind(this);
        this.onChangeJobStart = this.onChangeJobStart.bind(this);
        this.onChangeJobEnd = this.onChangeJobEnd.bind(this);
        this.onResetJob = this.onResetJob.bind(this);
        this.resetJobGraph = this.resetJobGraph.bind(this);
        
        this.onLeftRunClick = this.onLeftRunClick.bind(this);
        this.onRightRunClick = this.onRightRunClick.bind(this);
        this.onChangeRun = this.onChangeRun.bind(this);

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.renderACGraph = this.renderACGraph.bind(this);

        this.getHighchartsData = this.getHighchartsData.bind(this);

        this.state = {
            alertArgs: {
                queryId: "custom",
                startTime: null,
                endTime: null,
                isActive: true,
                priority: 3
            },
            currentCustomer: alertCustomer,
            currentAlert: jsAlert,
            updateAlert: jsAlert,
            editAlert: jsAlert,
            currentOpportunity: jsUpdateOpportunity,
            currentAsset: jsAsset,
            currentGraph: {
                run: {},
                daily: {}
            },
            dailyGraph: {},
            isSelected: -1,
            alertType: "Active",
            alertClassId: "5",
            alertDetailLoading: false,
            graphLoading: false,
            alertSelected: false,
            acSelected: false,
            acGraph: false,
            acHvacSystem: [],
            currentRunName: "",
            currentSource: jsSource,
            currentMax: 0,
            runList: [],
            selectedRun: 0,
            numRuns: 0,
            runGraphData: jsGraphData,
            dailyGraphData: jsGraphData,
            runGraphDataAnnotated: jsGraphData,
            dailyGraphDataAnnotated: jsGraphData,
            runGraphAnnotations: [],
            runGraphArrowAnnotations: [],
            dailyGraphAnnotations: [],
            dailyGraphArrowAnnotations: [],
            currentGraphAnnotations: [],
            currentGraphArrowAnnotations: [],
            showRunAnnotations: false,
            showDailyAnnotations: false,
            canLeftClick: false,
            canRightClick: true,
            untrainedRun: false,
            graphModalType: "Run",
            modalTitle: "",
            modalMessage: "",
            message: "",
            showRunModal: false,
            showDailyModal: false,
            showMultiModal: false,
            currentAnalyticProps: {},
            showEditConfirm: false,
            showCreateJobConfirm: false,
            showCloseConfirm: false,
            showDevicesConfirm: false,
            unsetProperties: [],
            showJobDetailConfirm: false,
            casesLoading: false,
            alertCases: [],
            multiseriesGraph: {},

            runDataList: [],
            timelineDataList: [],
            multiDataList: [],

            property_dictionary: { "75": [766057, 754696], "77": [754702, 768809], "76": [754686, 779446, 779443, 782721, 782722] },
            startDateTimeStr: "",
            endDateTimeStr: "",
            dpStartDateTime: null,
            dpEndDateTime: null,
            dpStartStatus: null,
            dpEndStatus: null,
            selectedStartTime: null,
            selectedEndTime: null,
            showZoomButton: false,
            hasAnalytics: false,
            visibility: {
                analytics: false,
                furnace: true,
                temp: true,
                rla: false,
                lra: false
            },
            tabIndex: 0,
            tableIndex: 0,

            jobStartDateTimeStr: "",
            jobEndDateTimeStr: "",
            dpJobStartDateTime: null,
            dpJobEndDateTime: null,
            dpJobStartStatus: null,
            dpJobEndStatus: null,
            selectedJobStartTime: null,
            selectedJobEndTime: null,
            jobStartClicked: false,
            jobEndClicked: false,
            autoGraph: false,
            jobAlert: jsAlert,
            alertMsgObj: {}
        }
    }

    handleCheckbox(e) {
        const checkedVal = e.target.value;

        if (e.target.checked) {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: true
                }
            }));
        }
        else {
            this.setState(prevState => ({
                visibility: {
                    ...prevState.visibility,
                    [checkedVal]: false
                }
            }));
        }
    }

    async onChangeRun(e) {
        Event("Run Dropdown Click", "User selected a run from the dropdown", "Run Drowndown Clicked");
        let index = parseInt(e.target.value);
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.state.currentGraph.runNamesList[index],
            currentSource: this.state.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    async onLeftRunClick() {
        Event("Run Left Click", "User clicked left arrow to change run", "Left Run Clicked");
        let index = this.state.selectedRun - 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.state.currentGraph.runNamesList[index],
            currentSource: this.state.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });
    }

    async onRightRunClick() {
        Event("Run Right Click", "User clicked right arrow to change run", "Right Run Clicked");
        let index = this.state.selectedRun + 1;
        let canLeft = index > 0 ? true : false;
        let canRight = index < this.state.numRuns - 1 ? true : false;

        this.setState({
            currentRunName: this.state.currentGraph.runNamesList[index],
            currentSource: this.state.currentGraph.timeSeriesDataSources[index],
            clearPreviusState: true,
            selectedRun: index,
            canLeftClick: canLeft,
            canRightClick: canRight
        }, () => {
            var runChar = this.state.currentRunName.charAt(0);
            if (runChar == 'g' || runChar == 'r')
                this.setState({ untrainedRun: false });
            else
                this.setState({ untrainedRun: true });
        });

    }

    showFullRunModal() {
        this.setState({
            showRunModal: !this.state.showRunModal
        }, () => {
                if (this.state.showRunModal)
                    ModalView("fullscreenRunGraph");
        });
        window.scrollTo(0, 0);
    }

    openFullRunModal() {
        this.setState({ modalTitle: "Run Graph" }, () => {
            this.showFullRunModal();
        });
    }

    showFullDailyModal() {
        this.setState({
            showDailyModal: !this.state.showDailyModal
        }, () => {
                if (this.state.showDailyModal)
                    ModalView("fullscreenDailyGraph");
        });
        window.scrollTo(0, 0);
    }

    openFullDailyModal() {
        this.setState({ modalTitle: "Timeline Graph" }, () => {
            this.showFullDailyModal();
        });
    }

    showFullMultiModal() {
        this.setState({
            showMultiModal: !this.state.showMultiModal
        }, () => {
            if (this.state.showMultiModal)
                ModalView("fullscreenMultiseriesGraph");
        });
        window.scrollTo(0, 0);
    }

    openFullMultiModal() {
        this.setState({ modalTitle: "MultiSeries Graph" }, () => {
            this.showFullMultiModal();
        });
    }

    runAnalytic() {
        this.setState({ showRunAnnotations: !this.state.showRunAnnotations });
    }

    runDailyAnalytic() {
        this.setState({ showDailyAnnotations: !this.state.showDailyAnnotations });
    }

    openJobDetailModal(alert) {
        this.setState({ editAlert: alert }, () => { this.showJobDetailModal(null) });
    }

    showJobDetailModal() {
        this.setState({
            showJobDetailConfirm: !this.state.showJobDetailConfirm
        }, () => {
                if (this.state.showJobDetailConfirm)
                    ModalView("jobDetailConfirm");
        });
        window.scrollTo(0, 0);
    }

    async getAlertCases(alertId) {
        await AlertService.getalertcases(alertId)
            .then(response => {
                if (this.props._isMounted)
                    this.setState({ alertCases: response.data });
            })
            .catch(e => {
                console.log(e);
            });
    }

    openEditAlertModal(alert) {
        this.setState({ modalTitle: "Edit Alert", editAlert: alert }, () => { this.showEditAlertModal(null) });
    }

    showEditAlertModal() {
        this.setState({
            showEditConfirm: !this.state.showEditConfirm
        }, () => {
                if (this.state.showEditConfirm)
                    ModalView("editAlertConfirm");
        });
        window.scrollTo(0, 0);
    }

    onEditAlert() {
        this.editAlert();
        this.setState({ showEditConfirm: !this.state.showEditConfirm });
    }

    async editAlert() {
        await AlertService.updatealert(this.state.editAlert)
            .then(async response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: "The alert was updated successfully!"
                    });
                    Event("Alert Update Clicked", "User clicked update alert button", "Alert Update Clicked");

                    this.reloadTable();
                }
                else {
                    this.setState({
                        message: "Failed to update the alert!"
                    });
                }

                this.setState({ loading: true }, () => {
                    this.props.reloadTable();
                });

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    openCreateJobModal(alert, opportunity) {
        this.setState({ modalTitle: "Create Job", editAlert: alert, currentOpportunity: opportunity },
            () => {
                this.setState(prevState => ({
                    currentOpportunity: {
                        ...prevState.currentOpportunity,
                        subject: this.state.editAlert.message
                    }
                }));
                this.showCreateJobModal(null);
            });
    }

    showCreateJobModal() {
        this.setState({
            showCreateJobConfirm: !this.state.showCreateJobConfirm
        }, () => {
                ModalView("createJobModal");
        });
        window.scrollTo(0, 0);
    }

    onCreateJob() {
        this.createJob();
        this.setState({ showCreateJobConfirm: !this.state.showCreateJobConfirm });
    }

    async createJob() {
        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                priority: 1,
                priorityName: "High",
                isFalsePositive: false 
            }
        }), async () => {
            await AlertService.updatealert(this.state.editAlert)
                .then(response => {
                    if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                        this.setState(prevState => ({
                            currentOpportunity: {
                                ...prevState.currentOpportunity
                            }
                        }), async () => {
                            await AlertService.createjob(this.state.editAlert.alertId, this.state.currentOpportunity)
                                .then(response2 => {
                                    if (response2.status === 200 && response2.data !== undefined && response2.data !== null && response2.data !== false) {
                                        var localeDateAlert = new Date(this.state.editAlert.startTime);
                                        var msg = `Job created for alert occurring on ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()}.`;
                                        this.setState({
                                            message: msg
                                        });
                                        Event("Job Created from Alert", "User clicked create job button", "Job Created from Alert");

                                        this.reloadTable();
                                    }
                                    else {
                                        this.setState({
                                            message: "Failed to create job from the alert!"
                                        });
                                    }

                                    setTimeout(() => {
                                        this.setState({
                                            message: ""
                                        });
                                    }, 3000);
                                });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        );

    }

    openCloseAlertModal(alert) {
        var localeDateAlert = new Date(alert.startTime);
        this.setState({
            modalTitle: "Close Alert",
            modalMessage: `You are about to close this alert from ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()}. Are you sure?`,
            editAlert: alert
        }, () => { this.showCloseAlertModal(null) });
    }

    showCloseAlertModal() {
        this.setState({
            showCloseConfirm: !this.state.showCloseConfirm
        });
        window.scrollTo(0, 0);
    }

    onCloseAlert() {
        this.closeAlert();
        this.setState({ showCloseConfirm: !this.state.showCloseConfirm });
    }

    async closeAlert() {
        var localeDateAlert = new Date(this.state.editAlert.startTime);
        await AlertService.closealert(this.state.editAlert.alertId)
            .then(response => {
                if (response.status === 200 && response.data !== undefined && response.data !== null && response.data !== false) {
                    this.setState({
                        message: `Alert occurring on ${localeDateAlert.toLocaleDateString()}, ${localeDateAlert.toLocaleTimeString()} closed.`
                    });
                    Event("Close Alert Clicked", "User clicked button to close alert", "Close Alert Clicked");

                    this.reloadTable();
                }
                else {
                    this.setState({
                        message: "Failed to close the alert!"
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    openDevicesModal(alert) {
        this.setState({
            modalTitle: "View Devices",
            modalMessage: `You are about to be taken to the Devices page for the alert customer, ${alert.organizationName}. Would you like to continue?`,
            editAlert: alert
        }, () => { this.showDevicesModal(null) });
    }

    showDevicesModal() {
        this.setState({
            showDevicesConfirm: !this.state.showDevicesConfirm
        });
        window.scrollTo(0, 0);
    }

    onViewDevices() {
        this.viewDevices();
        this.setState({ showDevicesConfirm: !this.state.showDevicesConfirm });
    }

    viewDevices() {
        if (this.props.showButtons) {
            this.props.setCurrentPartnerId(this.state.currentCustomer.parentOrganizationId, this.setPartnerCallback());
        }
        else {
            this.props.setActivePath("/devices");
        }
    }

    onPriorityChange(e) {
        const priority = e.target.value;

        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                priority: parseInt(priority)
            }
        }));
    }

    onPriorityOpportunityChange(e) {
        const priority = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                priority: parseInt(priority)
            }
        }));
    }

    onFalsePositiveChange(e) {
        const isFalsePositive = e.target.value || null;
        var isFalsePositiveBool = false;

        if (isFalsePositive != null) {
            if (isFalsePositive === "true")
                isFalsePositiveBool = true;
            else
                isFalsePositiveBool = false;

            this.setState(prevState => ({
                editAlert: {
                    ...prevState.editAlert,
                    isFalsePositive: isFalsePositiveBool
                }
            }));
        }
        else {
            this.setState(prevState => ({
                editAlert: {
                    ...prevState.editAlert,
                    isFalsePositive: isFalsePositive
                }
            }));
        }
    }

    onMessageChange(e) {
        const message = e.target.value;

        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                message: message
            }
        }));
    }

    onCommentsChange(e) {
        const comment = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                comment: comment
            }
        }));
    }

    onSubjectChange(e) {
        const subject = e.target.value;

        this.setState(prevState => ({
            currentOpportunity: {
                ...prevState.currentOpportunity,
                subject: subject
            },
            editAlert: {
                ...prevState.editAlert,
                message: subject
            }
        }));
    }

    onRecommendationChange(e) {
        const recommendation = e.target.value;

        this.setState(prevState => ({
            editAlert: {
                ...prevState.editAlert,
                comment: recommendation
            }
        }));
    }

    onChangeStart(e) {
        var start = moment(e).toDate();
        var startStr = moment(e).format();
        this.setState({ dpStartDateTime: start, dpStartStatus: start, startDateTimeStrPlus: startStr }, () => {
            this.setState(prevState => ({
                currentAlert: {
                    ...prevState.currentAlert,
                    startTime: start
                }
            }), () => {
                    this.setState({ updateAlert: this.state.currentAlert });
            });
        });
    }

    onChangeEnd(e) {
        var end = moment(e).toDate();
        var endStr = moment(e).format();
        this.setState({ dpEndDateTime: end, endDateTimeStrPlus: endStr }, () => {
            this.setState(prevState => ({
                currentAlert: {
                    ...prevState.currentAlert,
                    endTime: end
                }
            }), () => {
                    this.setState({ updateAlert: this.state.currentAlert });
            });
        });
    }

    async onViewGraph() {
        let selectedAlert = this.state.isSelected;
        this.setState({ selectedStartTime: this.state.dpStartDateTime, selectedEndTime: this.state.dpEndDateTime }, async () => {
            await this.onAlertClick(selectedAlert, true);
            this.setState({ dpStartStatus: null, dpEndStatus: null }); 
        });
    }
    
    getJobGraphData() {
        let now = moment().utc();
        let end = now.toDate();

        this.setState(prevState => ({
            jobEndDateTimeStr: now.format(),
            jobAlert: {
                ...prevState.jobAlert,
                endTime: end
            }
        }), async () => {
            if (this.state.acGraph) {
                let request = {
                    assetId: this.state.currentAlert.assetId,
                    startTime: this.state.jobAlert.startTime,
                    endTime: this.state.jobAlert.endTime,
                    hvacId: this.state.currentAlert.pathIds[1],
                    alertId: this.state.currentAlert.alertId,
                    cacheStart: false, 
                    hvacSys: this.state.acHvacSystem.length ? this.state.acHvacSystem : []
                }
                await AlertService.getacjobgraphdata(request)
                    .then(response => {
                        if (this.props._isMounted) {
                            this.setState(prevState => ({
                                currentGraph: {
                                    ...prevState.currentGraph,
                                    run: response?.data?.run,
                                    highchartsRun: response?.data?.highchartsRun
                                },
                                hasAnalytics: this.state.currentAsset.hasAnalytics,
                                acGraph: true,
                                acSelected: true
                            }), () => {
                                this.getHighchartsData();
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
            else {
                let assetType = this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_HANDLER) ? Equipment.AIR_HANDLER : Equipment.FURNACE;
                if (assetType === Equipment.AIR_HANDLER) {
                    await AlertService.getassetjobalertdata(this.state.currentAsset.offHigh, assetType, this.state.currentAsset.acCurrentPropId, false, this.state.jobAlert)
                        .then(response2 => {
                            this.setState(prevState => ({
                                currentGraph: {
                                    ...prevState.currentGraph,
                                    run: response2?.data?.run
                                }
                            }), async () => {
                                await this.getHighchartsData();
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }
                else {
                    await AlertService.getjobgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.jobAlert, false)
                        .then(response3 => {
                            if (this.props._isMounted) {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response3?.data
                                    },
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: false
                                }), () => {
                                    this.getHighchartsData();
                                });
                            }
                        });
                }
            }
        });
        
    }

    onChangeJobStart() {
        Event("Start Job Clicked", "User clicked start job button in Current Job graph options", "Start Job Clicked");
        
        let now = moment().utc();
        let before = moment().utc().add(-10, 'minutes');
        let start = now.toDate();
        let startStr = now.format();
        let preStart = before.toDate();
        let preStartStr = before.format();
        this.setState({ 
            jobStartClicked: true, 
            dpJobStartDateTime: start, dpJobStartStatus: start, 
            jobStartDateTimeStrPlus: startStr,
            jobStartDateTimeStr: preStartStr,
            dpJobPreStartDateTime: preStart, dpJobPreStartStatus: preStart, 
            jobPreStartDateTimeStrPlus: preStartStr }, () => {
            
            this.setState(prevState => ({
                jobAlert: {
                    ...prevState.currentAlert,
                    startTime: preStart,
                    endTime: start
                }
            }), async () => {
                if (this.state.acGraph) {
                    let request = {
                        assetId: this.state.currentAlert.assetId,
                        startTime: this.state.jobAlert.startTime,
                        endTime: this.state.jobAlert.endTime,
                        hvacId: this.state.currentAlert.pathIds[1],
                        alertId: this.state.currentAlert.alertId,
                        cacheStart: true,
                        hvacSys: this.state.acHvacSystem.length ? this.state.acHvacSystem : []
                    }
                    await AlertService.getacjobgraphdata(request)//this.state.currentAlert.assetId, preStartStr, startStr, this.state.currentAlert.pathIds[1], this.state.currentAlert.alertId, true, this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                        .then(response => {
                            if (this.props._isMounted) {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response.data.run,
                                        highchartsRun: response.data.highchartsRun
                                    },
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: true,
                                    acSelected: true
                                }), () => {
                                    this.getHighchartsData();

                                    this.setState({
                                        alertDetailLoading: false,
                                        alertSelected: true
                                    }, () => {
                                        this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                    });
                                });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
                else {
                    let assetType = this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_HANDLER) ? Equipment.AIR_HANDLER : Equipment.FURNACE;
                    if (assetType === Equipment.AIR_HANDLER) {
                        await AlertService.getassetjobalertdata(this.state.currentAsset.offHigh, assetType, this.state.currentAsset.acCurrentPropId, true, this.state.jobAlert)
                            .then(response2 => {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response2?.data?.run
                                    },
                                    multiseriesGraph: null,
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: false,
                                    acSelected: false,
                                    thermostatSelected: false,
                                    thermGraph: false,
                                    currentAssetType: assetType
                                }), async () => {
                                    await this.getHighchartsData();

                                    this.setState({
                                        alertDetailLoading: false,
                                        alertSelected: true
                                    }, () => {
                                        this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                    });
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                    else {
                        await AlertService.getjobgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.jobAlert, true)
                            .then(response3 => {
                                if (this.props._isMounted) {
                                    this.setState(prevState => ({
                                        currentGraph: {
                                            ...prevState.currentGraph,
                                            run: response3?.data
                                        },
                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                        acGraph: false
                                    }), () => {
                                        this.getHighchartsData();

                                        this.setState({
                                            alertDetailLoading: false,
                                            alertSelected: true
                                        }, () => {
                                            this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                        });
                                    });
                                }
                            });   
                    }
                }
            });
        });
    }

    onChangeJobEnd() {
        Event("End Job Clicked", "User clicked end job button in Current Job graph options", "End Job Clicked");
        clearInterval(this._intervalId);
        let now = moment().utc();
        let end = now.toDate();
        let endStr = now.format();
        this.setState({ jobEndDateTimeStr: endStr, jobEndClicked: true, dpJobEndDateTime: end, jobEndDateTimeStrPlus: endStr }, () => {
            
            this.setState(prevState => ({
                jobAlert: {
                    ...prevState.jobAlert,
                    endTime: end
                }
            }), async () => {
                AlertService.clearjobcachedstart(this.state.currentAlert.alertId)
                    .then(clearResponse => {
                        let clearSuccess = false;
                        if (clearResponse !== null && clearResponse !== undefined && clearResponse.data !== null && clearResponse.data !== undefined) {
                            clearSuccess = true;
                        }
                    });
                if (this.state.acGraph) {
                    let request = {
                        assetId: this.state.currentAlert.assetId,
                        startTime: this.state.jobAlert.startTime,
                        endTime: this.state.jobAlert.endTime,
                        hvacId: this.state.currentAlert.pathIds[1],
                        alertId: this.state.currentAlert.alertId,
                        cacheStart: false,
                        hvacSys: this.state.acHvacSystem.length ? this.state.acHvacSystem : []
                    }
                    await AlertService.getacjobgraphdata(request)
                        .then(response => {
                            if (this.props._isMounted) {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response?.data?.run,
                                        highchartsRun: response?.data?.highchartsRun
                                    },
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: true,
                                    acSelected: true
                                }), () => {
                                    this.getHighchartsData();
                                });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
                else {
                    let assetType = this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_HANDLER) ? Equipment.AIR_HANDLER : Equipment.FURNACE;
                    if (assetType === Equipment.AIR_HANDLER) {
                        await AlertService.getassetjobalertdata(this.state.currentAsset.offHigh, assetType, this.state.currentAsset.acCurrentPropId, false, this.state.jobAlert)
                            .then(response2 => {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response2?.data?.run
                                    },
                                    multiseriesGraph: null,
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: false,
                                    acSelected: false,
                                    thermostatSelected: false,
                                    thermGraph: false,
                                    currentAssetType: assetType
                                }), async () => {
                                    await this.getHighchartsData();
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                    else {
                        await AlertService.getjobgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.jobAlert, false)
                            .then(response3 => {
                                if (this.props._isMounted) {
                                    this.setState(prevState => ({
                                        currentGraph: {
                                            ...prevState.currentGraph,
                                            run: response3?.data
                                        },
                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                        acGraph: false
                                    }), () => {
                                        this.getHighchartsData();
                                    });
                                }
                            });  
                    }
                }
            });
        });
    }

    onResetJob() {
        Event("Reset Job Clicked", "User clicked reset job button in Current Job job graph", "Reset Job Clicked");
        let now = moment().utc();
        let before = moment.utc().add(-10, 'minutes');
        let start = now.toDate();
        let startStr = now.format();
        let preStart = before.toDate();
        let preStartStr = before.format();
        this.setState({ 
            jobStartClicked: true, 
            jobEndClicked: false, 
            dpJobStartDateTime: start, dpJobStartStatus: start, 
            jobStartDateTimeStrPlus: startStr, jobStartDateTimeStr: preStartStr,
            dpJobPreStartDateTime: preStart, dpJobPreStartStatus: preStart, jobPreStartDateTimeStrPlus: preStartStr }, () => {
            
            this.setState(prevState => ({
                jobAlert: {
                    ...prevState.currentAlert,
                    startTime: preStart,
                    endTime: start
                }
            }), async () => {
                if (this.state.acGraph) {
                    let request = {
                        assetId: this.state.currentAlert.assetId,
                        startTime: this.state.jobAlert.startTime,
                        endTime: this.state.jobAlert.endTime,
                        hvacId: this.state.currentAlert.pathIds[1],
                        alertId: this.state.currentAlert.alertId,
                        cacheStart: true,
                        hvacSys: this.state.acHvacSystem.length ? this.state.acHvacSystem : []
                    }
                    await AlertService.getacjobgraphdata(request)
                        .then(response => {
                            if (this.props._isMounted) {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response?.data?.run,
                                        highchartsRun: response?.data?.highchartsRun
                                    },
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: true,
                                    acSelected: true
                                }), () => {
                                    this.getHighchartsData();

                                    this.setState({
                                        alertDetailLoading: false,
                                        alertSelected: true
                                    }, () => {
                                        clearInterval(this._intervalId);
                                        this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                    });
                                });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
                else {
                    let assetType = this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_HANDLER) ? Equipment.AIR_HANDLER : Equipment.FURNACE;
                    if (assetType === Equipment.AIR_HANDLER) {
                        await AlertService.getassetjobalertdata(this.state.currentAsset.offHigh, assetType, this.state.currentAsset.acCurrentPropId, true, this.state.jobAlert)
                            .then(response2 => {
                                this.setState(prevState => ({
                                    currentGraph: {
                                        ...prevState.currentGraph,
                                        run: response2?.data?.run
                                    },
                                    multiseriesGraph: null,
                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                    acGraph: false,
                                    acSelected: false,
                                    thermostatSelected: false,
                                    thermGraph: false,
                                    currentAssetType: assetType
                                }), async () => {
                                    await this.getHighchartsData();

                                    this.setState({
                                        alertDetailLoading: false,
                                        alertSelected: true
                                    }, () => {
                                        this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                    });
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    }
                    else {
                        await AlertService.getjobgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.jobAlert, true)
                            .then(response3 => {
                                if (this.props._isMounted) {
                                    this.setState(prevState => ({
                                        currentGraph: {
                                            ...prevState.currentGraph,
                                            run: response3?.data
                                        },
                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                        acGraph: false
                                    }), () => {
                                        this.getHighchartsData();

                                        this.setState({
                                            alertDetailLoading: false,
                                            alertSelected: true
                                        }, () => {
                                            clearInterval(this._intervalId);
                                            this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                        });
                                    });
                                }
                            });
                    }
                }
            });
        });
    }

    async reloadTable() {
        this.setState({
            alertTableLoading: true,
            isSelected: -1,
            alertSelected: false,
            alertList: [],
            currentCustomer: alertCustomer,
            currentAsset: jsAsset,
            currentAlert: jsAlert,
            currentGraph: {},
            runGraphData: jsGraphData,
            dailyGraphData: jsGraphData,
            runGraphDataAnnotated: jsGraphData,
            dailyGraphDataAnnotated: jsGraphData,
            multiseriesGraph: {},
            runDataList: [],
            timelineDataList: [],
            multiDataList: [],
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: this.state.alertType === "Inactive" ? false : true
            }
        }));

        this.props.reloadTable();
        //this.props.getAlerts(this.props.currentCustomerId);
    }

    changeRadio(e) {
        let x = e.target.value;

        this.setState({
            alertType: x,
            isSelected: -1,
            selectedRun: 0,
            numRuns: 0,
            acGraph: false,
            acSelected: false,
            alertSelected: false,
            alertList: [],
            runList: [],
            runNamesList: [],
            currentCustomer: alertCustomer,
            currentAsset: jsAsset,
            currentAlert: jsAlert,
            currentGraph: {},
            //currentRun: null,
            currentRunName: "",
            currentSource: jsSource,
            runGraphData: jsGraphData,
            dailyGraphData: jsGraphData,
            runGraphDataAnnotated: jsGraphData,
            dailyGraphDataAnnotated: jsGraphData,
            multiseriesGraph: {},
            runDataList: [],
            timelineDataList: [],
            multiDataList: [],
            canLeftClick: false,
            canRightClick: false
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: x === "Inactive" ? false : true
            }
        }));

        this.setState({ alertTableLoading: true }, () => {
            this.props.changeRadio(x); 
        });
    }

    onTableTabClick = (index) => {
        let x = index;

        this.setState({
            tableIndex: x
        });
    }
    
    resetJobGraph() {
        this.setState({
            jobStartDateTimeStr: "",
            jobEndDateTimeStr: "",
            dpJobStartDateTime: null,
            dpJobEndDateTime: null,
            dpJobStartStatus: null,
            dpJobEndStatus: null,
            selectedJobStartTime: null,
            selectedJobEndTime: null,
            jobStartClicked: false,
            jobEndClicked: false,
            autoGraph: false,
            jobAlert: jsAlert
        });
    }
    
    async getJobGraphFromCache(startStr, endStr) {
        if (this.state.acGraph) {
            let request = {
                assetId: this.state.currentAlert.assetId,
                startTime: this.state.jobAlert.startTime,
                endTime: this.state.jobAlert.endTime,
                hvacId: this.state.currentAlert.pathIds[1],
                alertId: this.state.currentAlert.alertId,
                cacheStart: false,
                hvacSys: this.state.acHvacSystem.length ? this.state.acHvacSystem : []
            }
            await AlertService.getacjobgraphdata(request)
                .then(response => {
                    if (this.props._isMounted) {
                        this.setState(prevState => ({
                            currentGraph: {
                                ...prevState.currentGraph,
                                run: response?.data?.run,
                                highchartsRun: response?.data?.highchartsRun
                            },
                            hasAnalytics: this.state.currentAsset.hasAnalytics,
                            acGraph: true,
                            acSelected: true
                        }), () => {
                            this.getHighchartsData();

                            this.setState({
                                alertDetailLoading: false,
                                alertSelected: true
                            }, () => {
                                this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                            });
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }
        else {
            let assetType = this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_HANDLER) ? Equipment.AIR_HANDLER : Equipment.FURNACE;
            if (assetType === Equipment.AIR_HANDLER) {
                await AlertService.getassetjobalertdata(this.state.currentAsset.offHigh, assetType, this.state.currentAsset.acCurrentPropId, false, this.state.jobAlert)
                    .then(response2 => {
                        this.setState(prevState => ({
                            currentGraph: {
                                ...prevState.currentGraph,
                                run: response2?.data?.run
                            },
                            multiseriesGraph: null,
                            hasAnalytics: this.state.currentAsset.hasAnalytics,
                            acGraph: false,
                            acSelected: false,
                            thermostatSelected: false,
                            thermGraph: false,
                            currentAssetType: assetType
                        }), async () => {
                            await this.getHighchartsData();

                            this.setState({
                                alertDetailLoading: false,
                                alertSelected: true
                            }, () => {
                                this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                            });
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }
            else {
                await AlertService.getjobgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.jobAlert, true)
                    .then(response3 => {
                        if (this.props._isMounted) {
                            this.setState(prevState => ({
                                currentGraph: {
                                    ...prevState.currentGraph,
                                    run: response3?.data
                                },
                                hasAnalytics: this.state.currentAsset.hasAnalytics,
                                acGraph: false
                            }), () => {
                                this.getHighchartsData();

                                this.setState({
                                    alertDetailLoading: false,
                                    alertSelected: true
                                }, () => {
                                    this._intervalId = setInterval(this.getJobGraphData, 2*60*1000);
                                });
                            });
                        }
                    });
            }
        }
    }

    async onAlertClick(index, updateGraph = false) {
        let x = index;
        let prevX = this.state.isSelected;
        let startTime = performance.now();
        Event("Alert Clicked", "User clicked on an alert in an alert table", "Alert Clicked");
        if (x !== prevX || updateGraph) {
            let currentAlert = x !== prevX ? this.props.alertList[x] : this.state.updateAlert;
            let alertMsg = {};
            let alertMsgObj = this.props.alertMessaging.find(a => a.id === parseInt(currentAlert?.typeId));
            if (alertMsgObj !== null && alertMsgObj !== undefined) {
                alertMsg = alertMsgObj;
            }
            this.resetJobGraph();
            clearInterval(this._intervalId);

            this.setState({ multiseriesGraph: {}, runDataList: [], timelineDataList: [], multiDataList: [], currentAlert: jsAlert,
                currentCustomer: alertCustomer, currentAsset: jsAsset, hasAnalytics: false, alertDetailLoading: true, isSelected: index,
                acSelected: false, acGraph: false, acHvacSystem: [], jobStartClicked: false }, () => {
                this.setState({
                    currentAlert: currentAlert,
                    alertMsgObj: alertMsg
                }, async () => {
                    AlertService.getjobcachedstart(this.state.currentAlert.alertId)
                        .then(jobResponse => {
                            let jobStartClicked = false;
                            let jobStart = null;
                            let jobStartStr = null;
                            let jobEnd = null;
                            let jobEndStr = null;
                            if (jobResponse !== null && jobResponse !== undefined && jobResponse.data !== null && jobResponse.data !== "") {
                                jobStartClicked = true;
                                let jobStartMoment = moment(jobResponse.data.replace(/['"]+/g, '')).utc();
                                jobStart = jobStartMoment.toDate();
                                jobStartStr = jobStartMoment.format();
                                let jobEndMoment = moment().utc();
                                jobEnd = jobEndMoment.toDate();
                                jobEndStr = jobEndMoment.format();
                            }
                            this.setState(prevState => ({
                                jobAlert: {
                                    ...prevState.currentAlert,
                                    startTime: jobStartClicked ? jobStart : this.state.currentAlert.startTime,
                                    endTime: jobStartClicked ? jobEnd : this.state.currentAlert.endTime
                                },
                                jobStartClicked: jobStartClicked,
                                dpJobStartDateTime: jobStart,
                                dpJobStartStatus: jobStart,
                                jobStartDateTimeStr: jobStartStr,
                                jobStartDateTimeStrPlus: jobStartStr
                            }), async () => {
                                if (this.state.dpStartStatus == null && this.state.dpEndStatus == null) {
                                    this.setState({
                                        selectedStartTime: this.state.alertType === "Active" ? "" : this.state.currentAlert.startTime,
                                        selectedEndTime: this.state.alertType === "Active" ? this.state.currentAlert.startTime : this.state.currentAlert.endTime,
                                    });
                                }
                                if (this.state.alertType === "Repairs") {
                                    this.getAlertCases(this.state.currentAlert.alertId);
                                }

                                await AlertService.get(this.state.currentAlert.organization)
                                    .then(response => {
                                        if (this.props._isMounted)
                                            this.setState({
                                                currentCustomer: response.data
                                            }, async () => {
                                                await AlertService.getalertasset(this.state.currentAlert)
                                                    .then(response2 => {
                                                        if (this.props._isMounted)
                                                            this.setState({
                                                                currentAsset: response2.data
                                                            }, async () => {
                                                                let acSelected = false;
                                                                let hvacSysList = [];
                                                                let assetType = Equipment.FURNACE;
                                                                if (this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_CONDITIONER)) {
                                                                    acSelected = true;
                                                                    assetType = Equipment.AIR_CONDITIONER;
                                                                    await AssetService.get(this.state.currentAlert.pathIds[1])
                                                                        .then(assetResponse => {
                                                                            if (this.props._isMounted)
                                                                                this.setState({ acHvacSystem: assetResponse.data });
                                                                        });
                                                                }
                                                                else if (this.state.currentAlert?.assetClassNames?.some(a => a === Equipment.AIR_HANDLER)) {
                                                                    assetType = Equipment.AIR_HANDLER;
                                                                }

                                                                if (this.state.alertType !== "Device") {
                                                                    let propertySet = Object.hasOwnProperty.call(this.state.property_dictionary, this.state.currentAlert.typeId);
                                                                    if (propertySet) {
                                                                        if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x !== prevX) {
                                                                            var alertStart = new Date(this.state.currentAlert.startTime);
                                                                            alertStart = alertStart.setDate(alertStart.getDate() - 30);
                                                                            var alertStartStr = moment(alertStart).format();
                                                                            this.setState({
                                                                                dpStartDateTime: this.state.alertType === "Active" ? alertStart : new Date(this.state.currentAlert.startTime),
                                                                                dpEndDateTime: this.state.alertType === "Active" ? new Date(this.state.currentAlert.startTime) : new Date(this.state.currentAlert.endTime)
                                                                            });
                                                                        }
                                                                        else if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x == prevX) {
                                                                            this.setState({
                                                                                selectedStartTime: this.state.currentAlert.startTime,
                                                                                selectedEndTime: this.state.currentAlert.endTime,
                                                                                dpStartDateTime: new Date(this.state.currentAlert.startTime),
                                                                                dpEndDateTime: new Date(this.state.currentAlert.endTime)
                                                                            });
                                                                        }

                                                                        await AlertService.getmultiseriesgraphdata({
                                                                            assetId: parseInt(this.state.currentAlert.assetId),
                                                                            propertyList: this.state.property_dictionary[this.state.currentAlert.typeId],
                                                                            startTime: this.state.selectedStartTime,
                                                                            endTime: this.state.selectedEndTime,
                                                                            maxPoints: 800,
                                                                            summaryType: 1
                                                                        }, acSelected ? this.state.currentAlert.pathIds[1] : null)
                                                                            .then(response3 => {
                                                                                if (this.props._isMounted)
                                                                                    this.setState({
                                                                                        multiseriesGraph: response3.data.trend,
                                                                                        currentGraph: response3.data.run,
                                                                                        acSelected: acSelected,
                                                                                        acGraph: acSelected,
                                                                                        alertDetailLoading: false,
                                                                                        alertSelected: true,
                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                        tabIndex: 1
                                                                                    }, () => {
                                                                                        if (this.state.currentGraph.timeSeriesDataSources !== null) {
                                                                                            this.getHighchartsData();

                                                                                            this.setState({
                                                                                                currentRunName: this.state.currentGraph.runNamesList[0],
                                                                                                currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                                                runList: this.state.currentGraph.timeSeriesDataSources,
                                                                                                numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                                                canLeftClick: false,
                                                                                                canRightClick: true
                                                                                            }, () => {
                                                                                                const runName = this.state.currentRunName;
                                                                                                this.setState({
                                                                                                    untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                                                    alertDetailLoading: false,
                                                                                                    alertSelected: true
                                                                                                }, () => {
                                                                                                    var elapsedTime = performance.now() - startTime;
                                                                                                    Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                });
                                                                                            });
                                                                                        }
                                                                                        else {
                                                                                            this.setState({
                                                                                                alertDetailLoading: false,
                                                                                                alertSelected: true,
                                                                                                numRuns: 0,
                                                                                                currentRunName: "",
                                                                                                currentSource: jsSource,
                                                                                                canLeftClick: false,
                                                                                                canRightClick: false
                                                                                            }, () => {
                                                                                                var elapsedTime = performance.now() - startTime;
                                                                                                Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                            });
                                                                                        }
                                                                                    });
                                                                            });

                                                                    }
                                                                    else {
                                                                        /** START EDIT SECTION **/

                                                                        if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x !== prevX) {
                                                                            let alertStart = new Date(this.state.currentAlert.startTime);
                                                                            alertStart.setMinutes(alertStart.getMinutes() - 5);
                                                                            let dailyStart = alertStart.setHours(alertStart.getHours() - 6);
                                                                            let dailyEnd = alertStart.setHours(alertStart.getHours() + 12);
                                                                            this.setState({
                                                                                dpStartDateTime: dailyStart,
                                                                                dpEndDateTime: dailyEnd
                                                                            }, async () => {
                                                                                if (!acSelected) {
                                                                                    if (assetType !== Equipment.AIR_HANDLER) {
                                                                                        await AlertService.getallgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.currentAlert)
                                                                                            .then(response3 => {
                                                                                                if (this.props._isMounted) {
                                                                                                    this.setState({
                                                                                                        currentGraph: response3.data,
                                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                        acGraph: false
                                                                                                    }, () => {
                                                                                                        if (!jobStartClicked) {
                                                                                                            this.getHighchartsData();
                                                                                                        } else {
                                                                                                            this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            alertDetailLoading: false,
                                                                                                            alertSelected: true
                                                                                                        }, () => {
                                                                                                            let elapsedTime = performance.now() - startTime;
                                                                                                            Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                        });
                                                                                                    });
                                                                                                }
                                                                                            });
                                                                                    }
                                                                                    else {
                                                                                        await AlertService.getsingleassetalertdata(this.state.currentAsset.offHigh, Equipment.AIR_HANDLER, this.state.currentAsset.acCurrentPropId, this.state.currentAlert)
                                                                                            .then(response4 => {
                                                                                                this.setState({
                                                                                                    currentGraph: response4.data,
                                                                                                    multiseriesGraph: null,
                                                                                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                    acGraph: false,
                                                                                                    acSelected: false,
                                                                                                    thermostatSelected: false,
                                                                                                    thermGraph: false,
                                                                                                    currentAssetType: assetType
                                                                                                }, async () => {
                                                                                                   if (!jobStartClicked) {
                                                                                                       await this.getHighchartsData();
                                                                                                   }
                                                                                                   else {
                                                                                                       await this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                   }

                                                                                                    this.setState({
                                                                                                        alertDetailLoading: false,
                                                                                                        alertSelected: true
                                                                                                    });
                                                                                                });
                                                                                            })
                                                                                            .catch(e => {
                                                                                                console.log(e);
                                                                                            })
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    /** todo AC GRAPH **/
                                                                                    await AlertService.getacalertgraphdata(this.state.currentAlert.assetId, this.state.currentAlert.startTime, this.state.currentAlert.endTime, this.state.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                                                                        .then(response5 => {
                                                                                            if (this.props._isMounted) {
                                                                                                this.setState({
                                                                                                    currentGraph: response5.data,
                                                                                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                    acGraph: true,
                                                                                                    acSelected: true,
                                                                                                    selectedRun: 0
                                                                                                }, () => {
                                                                                                    if (this.state.currentGraph.timeSeriesDataSources !== null) {
                                                                                                        if (!jobStartClicked) {
                                                                                                            this.getHighchartsData();
                                                                                                        }
                                                                                                        else {
                                                                                                            this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                        }

                                                                                                        this.setState({
                                                                                                            currentRunName: this.state.currentGraph.runNamesList[0],
                                                                                                            currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                                                            runList: this.state.currentGraph.timeSeriesDataSources,
                                                                                                            numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                                                            canLeftClick: false,
                                                                                                            canRightClick: true
                                                                                                        }, () => {
                                                                                                            const runName = this.state.currentRunName;
                                                                                                            this.setState({
                                                                                                                untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                                                                alertDetailLoading: false,
                                                                                                                alertSelected: true
                                                                                                            }, () => {
                                                                                                                var elapsedTime = performance.now() - startTime;
                                                                                                                Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                            });
                                                                                                        });
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({
                                                                                                            alertDetailLoading: false,
                                                                                                            alertSelected: true,
                                                                                                            numRuns: 0,
                                                                                                            currentRunName: "",
                                                                                                            currentSource: jsSource,
                                                                                                            canLeftClick: false,
                                                                                                            canRightClick: false
                                                                                                        }, () => {
                                                                                                            var elapsedTime = performance.now() - startTime;
                                                                                                            Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                        });
                                                                                                    }
                                                                                                });
                                                                                            }
                                                                                        });
                                                                                }
                                                                            });
                                                                        }

                                                                        else if (this.state.dpStartStatus == null && this.state.dpEndStatus == null && x == prevX) {
                                                                            this.setState({
                                                                                dpStartDateTime: new Date(this.state.currentAlert.startTime),
                                                                                dpEndDateTime: new Date(this.state.currentAlert.endTime)
                                                                            }, async () => {
                                                                                // update daily/timeline graph functionality here
                                                                                let startStr = moment(this.state.dpStartDateTime).format();
                                                                                let endStr = moment(this.state.dpEndDateTime).format();
                                                                                if (!acSelected) {
                                                                                    if (assetType !== Equipment.AIR_HANDLER) {
                                                                                        await AlertService.getallgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.currentAlert)
                                                                                            .then(response4 => {
                                                                                                if (this.props._isMounted)
                                                                                                    this.setState(prevState => ({
                                                                                                        currentGraph: response4.data,
                                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics
                                                                                                    }), () => {
                                                                                                        if (!jobStartClicked) {
                                                                                                            this.getHighchartsData();
                                                                                                        }
                                                                                                        else {
                                                                                                            this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                        }
                                                                                                        this.setState({
                                                                                                            alertDetailLoading: false,
                                                                                                            alertSelected: true
                                                                                                        })
                                                                                                    });
                                                                                            })
                                                                                            .catch(e => {
                                                                                                console.log(e);
                                                                                            });
                                                                                    }
                                                                                    else {
                                                                                        await AlertService.getsingleassetalertdata(this.state.currentAsset.offHigh, Equipment.AIR_HANDLER, this.state.currentAsset.acCurrentPropId, this.state.currentAlert)
                                                                                            .then(response4 => {
                                                                                                this.setState({
                                                                                                    currentGraph: response4.data,
                                                                                                    multiseriesGraph: null,
                                                                                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                    acGraph: false,
                                                                                                    acSelected: false,
                                                                                                    thermostatSelected: false,
                                                                                                    thermGraph: false,
                                                                                                    currentAssetType: assetType
                                                                                                }, async () => {
                                                                                                    if (!jobStartClicked) {
                                                                                                        this.getHighchartsData();
                                                                                                    }
                                                                                                    else {
                                                                                                        this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                    }

                                                                                                    this.setState({
                                                                                                        alertDetailLoading: false,
                                                                                                        alertSelected: true
                                                                                                    });
                                                                                                });
                                                                                            })
                                                                                            .catch(e => {
                                                                                                console.log(e);
                                                                                            })
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    /** todo AC GRAPH **/
                                                                                    await AlertService.getacalertgraphdata(this.state.currentAlert.assetId, this.state.currentAlert.startTime, this.state.currentAlert.endTime, this.state.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                                                                        .then(response5 => {
                                                                                            if (this.props._isMounted) {
                                                                                                this.setState(prevState => ({
                                                                                                    currentGraph: {
                                                                                                        ...prevState.currentGraph,
                                                                                                        daily: response5.data.daily
                                                                                                    },
                                                                                                    hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                    acGraph: true,
                                                                                                    acSelected: true
                                                                                                }), () => {
                                                                                                    if (!jobStartClicked) {
                                                                                                        this.getHighchartsData();
                                                                                                    }
                                                                                                    else {
                                                                                                        this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                    }

                                                                                                    this.setState({
                                                                                                        alertDetailLoading: false,
                                                                                                        alertSelected: true
                                                                                                    });
                                                                                                });
                                                                                            }
                                                                                        })
                                                                                        .catch(e => {
                                                                                            console.log(e);
                                                                                        });
                                                                                }
                                                                            });
                                                                        }
                                                                        else {
                                                                            if (!acSelected) {
                                                                                if (assetType !== Equipment.AIR_HANDLER) {
                                                                                    await AlertService.getallgraphdata(this.state.currentAsset.offHigh, this.state.currentAsset.furnaceId, this.state.currentAsset.acCurrentPropId, this.state.currentAsset.furnaceCurrentPropId, this.state.currentAlert)
                                                                                        .then(response3 => {
                                                                                            if (this.props._isMounted)
                                                                                                this.setState({
                                                                                                    currentGraph: response3.data,
                                                                                                    multiseriesGraph: null
                                                                                                }, () => {
                                                                                                    if (!jobStartClicked) {
                                                                                                        this.getHighchartsData();
                                                                                                    }
                                                                                                    else {
                                                                                                        this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                    }

                                                                                                    this.setState({
                                                                                                        alertDetailLoading: false,
                                                                                                        alertSelected: true,
                                                                                                        hasAnalytics: this.state.currentAsset.hasAnalytics
                                                                                                    }, () => {
                                                                                                        var elapsedTime = performance.now() - startTime;
                                                                                                        Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                    });
                                                                                                });
                                                                                        });
                                                                                }
                                                                                else {
                                                                                    await AlertService.getsingleassetalertdata(this.state.currentAsset.offHigh, Equipment.AIR_HANDLER, this.state.currentAsset.acCurrentPropId, this.state.currentAlert)
                                                                                        .then(response4 => {
                                                                                            this.setState({
                                                                                                currentGraph: response4.data,
                                                                                                multiseriesGraph: null,
                                                                                                hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                acGraph: false,
                                                                                                acSelected: false,
                                                                                                thermostatSelected: false,
                                                                                                thermGraph: false,
                                                                                                currentAssetType: assetType
                                                                                            }, async () => {
                                                                                                if (!jobStartClicked) {
                                                                                                    this.getHighchartsData();
                                                                                                }
                                                                                                else {
                                                                                                    this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                }

                                                                                                this.setState({
                                                                                                    alertDetailLoading: false,
                                                                                                    alertSelected: true
                                                                                                });
                                                                                            });
                                                                                        })
                                                                                        .catch(e => {
                                                                                            console.log(e);
                                                                                        })
                                                                                }
                                                                            }
                                                                            else {
                                                                                /** todo AC GRAPH **/
                                                                                await AlertService.getacalertgraphdata(this.state.currentAlert.assetId, this.state.currentAlert.startTime, this.state.currentAlert.endTime, this.state.currentAlert.pathIds[1], this.state.acHvacSystem.length ? this.state.acHvacSystem : [])
                                                                                    .then(response5 => {
                                                                                        if (this.props._isMounted) {
                                                                                            this.setState({
                                                                                                currentGraph: response5.data,
                                                                                                multiseriesGraph: null,
                                                                                                hasAnalytics: this.state.currentAsset.hasAnalytics,
                                                                                                acGraph: true,
                                                                                                acSelected: true,
                                                                                                selectedRun: 0
                                                                                            }, () => {
                                                                                                if (this.state.currentGraph.timeSeriesDataSources !== null && this.state.currentGraph.timeSeriesDataSources.length) {
                                                                                                    if (!jobStartClicked) {
                                                                                                        this.getHighchartsData();
                                                                                                    }
                                                                                                    else {
                                                                                                        this.getJobGraphFromCache(jobStartStr, jobEndStr);
                                                                                                    }

                                                                                                    this.setState({
                                                                                                        currentRunName: this.state.currentGraph.runNamesList[0],
                                                                                                        currentSource: this.state.currentGraph.timeSeriesDataSources[0],
                                                                                                        runList: this.state.currentGraph.timeSeriesDataSources,
                                                                                                        numRuns: this.state.currentGraph.timeSeriesDataSources.length,
                                                                                                        canLeftClick: false,
                                                                                                        canRightClick: true
                                                                                                    }, () => {
                                                                                                        const runName = this.state.currentRunName;
                                                                                                        this.setState({
                                                                                                            untrainedRun: runName !== undefined ? (runName.charAt(0) == 'g' ? false : (runName.charAt(0) == 'r' ? false : true)) : false,
                                                                                                            alertDetailLoading: false,
                                                                                                            alertSelected: true
                                                                                                        }, () => {
                                                                                                            var elapsedTime = performance.now() - startTime;
                                                                                                            Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                        });
                                                                                                    });
                                                                                                }
                                                                                                else {
                                                                                                    this.setState({
                                                                                                        currentRunName: "",
                                                                                                        currentSource: jsSource,
                                                                                                        runList: [],
                                                                                                        numRuns: 0,
                                                                                                        canLeftClick: false,
                                                                                                        canRightClick: false,
                                                                                                        alertDetailLoading: false,
                                                                                                        alertSelected: true
                                                                                                    }, () => {
                                                                                                        var elapsedTime = performance.now() - startTime;
                                                                                                        Timing("Alert Detail Loading", "alertDetailLoading", elapsedTime, "Alert Detail Loading");
                                                                                                    });
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    });
                                                                            }
                                                                        }

                                                                        /** END EDIT SECTION **/
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ alertSelected: true, acSelected: false, acGraph: false, alertDetailLoading: false, hasAnalytics: this.state.currentAsset.hasAnalytics });
                                                                }
                                                                //this.setState({ alertSelected: true, alertDetailLoading: false });
                                                            });
                                                    });
                                            });
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        })

                });
            });
        }
    }

    async getHighchartsData() {
        var graph = this.state.currentGraph;
        var multiGraph = this.state.multiseriesGraph;

        var runDataList = [];
        var timelineDataList = [];
        var multiDataList = [];
        var rlaVal = 0;
        let currentMax = this.state.currentSource !== null && this.state.currentSource !== undefined ? this.state.currentSource?.maxY : this.state.currentGraph.daily?.maxY;

        if (graph !== null && graph !== undefined) {
            let graphConstants = graph.constantValues;
            if (this.state.currentAlert.assetClassNames.some(a => a === Equipment.AIR_HANDLER)) {
                if (graphConstants !== null && graphConstants !== undefined && graph.run.series[0].data !== null && graph.run.series[0].data !== undefined && graph.run.series[0].data.length) {
                    let dataCount = graph.run.series[0].data.length;
                    let dailyCount = graph.daily.series[0].data.length;
                    let pointStart = moment(graph.run.series[0].data[0].timestamp);
                    let dailyStart = moment(graph.daily.series[0].data[0].timestamp);
                    if (graphConstants["blowerLowStageAvg"] !== null && graphConstants["blowerLowStageAvg"] !== undefined && graphConstants["blowerLowStageAvg"] !== 0) {

                        let blowerLowAvg = graphConstants["blowerLowStageAvg"];
                        if (graphConstants["blowerLowStdDev"] !== null && graphConstants["blowerLowStdDev"] !== undefined && graphConstants["blowerLowStdDev"] !== 0) {
                            let blowerLowStdDev = graphConstants["blowerLowStdDev"];
                            let blowerLowMin = blowerLowAvg - blowerLowStdDev;
                            let blowerLowMax = blowerLowAvg + blowerLowStdDev;
                            let minArr = Array(dataCount - 1).fill(blowerLowMin);
                            let minLabel = {
                                y: blowerLowMin,
                                dataLabels: { enabled: true, format: 'Expected Low Stage Min' }
                            };
                            minArr = minArr.concat(minLabel);
                            let maxArr = Array(dataCount - 1).fill(blowerLowMax);
                            let maxLabel = {
                                y: blowerLowMax,
                                dataLabels: { enabled: true, format: 'Expected Low Stage Max' }
                            };
                            maxArr = maxArr.concat(maxLabel);
                            let lowStageMinData = {
                                name: 'Expected Low Stage Min',
                                data: minArr,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            runDataList = runDataList.concat(lowStageMinData);

                            let lowStageMaxData = {
                                name: 'Expected Low Stage Max',
                                data: maxArr,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            runDataList = runDataList.concat(lowStageMaxData);

                            let minArr2 = Array(dailyCount - 1).fill(blowerLowMin);
                            minArr2 = minArr2.concat(minLabel);
                            let maxArr2 = Array(dailyCount - 1).fill(blowerLowMax);
                            maxArr2 = maxArr2.concat(maxLabel);

                            let lowStageMinData2 = {
                                name: 'Expected Low Stage Min',
                                data: minArr2,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            timelineDataList = timelineDataList.concat(lowStageMinData2);

                            let lowStageMaxData2 = {
                                name: 'Expected Low Stage Max',
                                data: maxArr2,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            timelineDataList = timelineDataList.concat(lowStageMaxData2);
                        }
                        else {
                            let lowAvgArr = Array(dataCount - 1).fill(blowerLowAvg);
                            let lowAvgLabel = {
                                y: blowerLowAvg,
                                dataLabels: { enabled: true, format: 'Expected Low Stage Avg'}
                            };
                            lowAvgArr = lowAvgArr.concat(lowAvgLabel);
                            let lowStageData = {
                                name: 'Expected Low Stage Avg',
                                data: lowAvgArr,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            runDataList = runDataList.concat(lowStageData);

                            let lowAvgArr2 = Array(dailyCount - 1).fill(blowerLowAvg);
                            lowAvgArr2 = lowAvgArr2.concat(lowAvgLabel);
                            let lowStageData2 = {
                                name: 'Expected Low Stage Avg',
                                data: lowAvgArr2,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            timelineDataList = timelineDataList.concat(lowStageData2);
                        }
                    }

                    if (graphConstants["blowerHighStageAvg"] !== null && graphConstants["blowerHighStageAvg"] !== undefined && graphConstants["blowerHighStageAvg"] !== 0) {
                        let blowerHighAvg = graphConstants["blowerHighStageAvg"];
                        if (graphConstants["blowerHighStdDev"] !== null && graphConstants["blowerHighStdDev"] !== undefined && graphConstants["blowerHighStdDev"] !== 0) {
                            let blowerHighStdDev = graphConstants["blowerHighStdDev"];
                            let blowerHighMin = blowerHighAvg - blowerHighStdDev;
                            let blowerHighMax = blowerHighAvg + blowerHighStdDev;
                            let minArr = Array(dataCount - 1).fill(blowerHighMin);
                            let minLabel = {
                                y: blowerHighMin,
                                dataLabels: { enabled: true, format: 'Expected High Stage Min' }
                            };
                            minArr = minArr.concat(minLabel);
                            let maxArr = Array(dataCount - 1).fill(blowerHighMax);
                            let maxLabel = {
                                y: blowerHighMax,
                                dataLabels: { enabled: true, format: 'Expected High Stage Max' }
                            };
                            maxArr = maxArr.concat(maxLabel);
                            let highStageMinData = {
                                name: 'Expected High Stage Min',
                                data: minArr,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            runDataList = runDataList.concat(highStageMinData);

                            let highStageMaxData = {
                                name: 'Expected High Stage Max',
                                data: maxArr,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            runDataList = runDataList.concat(highStageMaxData);

                            let minArr2 = Array(dailyCount - 1).fill(blowerHighMin);
                            minArr2 = minArr2.concat(minLabel);
                            let maxArr2 = Array(dailyCount - 1).fill(blowerHighMax);
                            maxArr2 = maxArr2.concat(maxLabel);

                            let highStageMinData2 = {
                                name: 'Expected High Stage Min',
                                data: minArr2,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            timelineDataList = timelineDataList.concat(highStageMinData2);

                            let highStageMaxData2 = {
                                name: 'Expected High Stage Max',
                                data: maxArr2,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            timelineDataList = timelineDataList.concat(highStageMaxData2);
                        } else {
                            let highAvgArr = Array(dataCount- 1).fill(blowerHighAvg);
                            let highAvgLabel = {
                                y: blowerHighAvg,
                                dataLabels: { enabled: true, format: 'Expected High Stage Avg'}
                            };
                            highAvgArr = highAvgArr.concat(highAvgLabel);
                            let highStageData = {
                                name: 'Expected High Stage Avg',
                                data: highAvgArr,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            runDataList = runDataList.concat(highStageData);

                            let highAvgArr2 = Array(dailyCount - 1).fill(blowerHighAvg);
                            highAvgArr2 = highAvgArr2.concat(highAvgLabel);
                            let highStageData2 = {
                                name: 'Expected High Stage Avg',
                                data: highAvgArr2,
                                color: 'red',
                                type: 'line',
                                zIndex: 2,
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0,
                                dashStyle: 'longDash',
                                visible: true
                            };
                            timelineDataList = timelineDataList.concat(highStageData2);
                        }
                    }

                    if (graphConstants["blowerLowAverage"] !== null && graphConstants["blowerLowAverage"] !== undefined && graphConstants["blowerLowAverage"] !== 0) {
                        let blowerLowAvg = graphConstants["blowerLowAverage"];
                        let lowAvgArr = Array(dataCount - 1).fill(blowerLowAvg);
                        let lowAvgLabel = {
                            y: blowerLowAvg,
                            dataLabels: { enabled: true, format: 'Blower Low Avg' }
                        };
                        lowAvgArr = lowAvgArr.concat(lowAvgLabel);
                        let lowStageData = {
                            name: 'Blower Low Average',
                            data: lowAvgArr,
                            color: 'teal',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStart,
                            pointInterval: 1000,
                            yAxis: 0,
                            visible: true
                        };
                        runDataList = runDataList.concat(lowStageData);

                        let lowAvgArr2 = Array(dailyCount - 1).fill(blowerLowAvg);
                        lowAvgArr2 = lowAvgArr2.concat(lowAvgLabel);
                        let lowStageData2 = {
                            name: 'Blower Low Average',
                            data: lowAvgArr2,
                            color: 'teal',
                            type: 'line',
                            zIndex: 2,
                            pointStart: dailyStart,
                            pointInterval: 1000,
                            yAxis: 0,
                            visible: true
                        };
                        timelineDataList = timelineDataList.concat(lowStageData2);
                    }

                    if (graphConstants["blowerHighAverage"] !== null && graphConstants["blowerHighAverage"] !== undefined && graphConstants["blowerHighAverage"] !== 0) {
                        let blowerHighAvg = graphConstants["blowerHighAverage"];
                        let highAvgArr = Array(dataCount - 1).fill(blowerHighAvg);
                        let highAvgLabel = {
                            y: blowerHighAvg,
                            dataLabels: { enabled: true, format: 'Blower High Avg' }
                        };
                        highAvgArr = highAvgArr.concat(highAvgLabel);
                        let highStageData = {
                            name: 'Blower High Average',
                            data: highAvgArr,
                            color: 'teal',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStart,
                            pointInterval: 1000,
                            yAxis: 0,
                            visible: true
                        };
                        runDataList = runDataList.concat(highStageData);

                        let highAvgArr2 = Array(dailyCount - 1).fill(blowerHighAvg);
                        highAvgArr2 = highAvgArr2.concat(highAvgLabel);
                        let highStageData2 = {
                            name: 'Blower High Average',
                            data: highAvgArr2,
                            color: 'teal',
                            type: 'line',
                            zIndex: 2,
                            pointStart: dailyStart,
                            pointInterval: 1000,
                            yAxis: 0,
                            visible: true
                        };
                        timelineDataList = timelineDataList.concat(highStageData2);
                    }
                }
            }
            
            if (this.state.acSelected) {
                if (graph.highchartsRun !== null && graph.highchartsRun !== undefined && graph.highchartsRun.data !== null && graph.highchartsRun.data !== undefined && graph.highchartsRun.data.length) {
                    let currentHCSource = graph.highchartsRun;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = this.state.jobStartClicked ? moment(currentHCSource.data[0][0]).format("l h:mm:ss A") : moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
                    let lastMoment = this.state.jobStartClicked ? moment(currentHCSource.data[currentHCSource.data.length -1][0]).format("l h:mm:ss A") : moment(currentHCSource.data[currentHCSource.data.length -1][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
                    var dtLast = new Date(lastMoment);
                    var pointLastMoment = Date.UTC(dtLast.getFullYear(), dtLast.getMonth(), dtLast.getDate(), dtLast.getHours(), dtLast.getMinutes(), dtLast.getSeconds(), dtLast.getMilliseconds());

                    let currentAnnotations = graph.timeSeriesDataSources !== null && graph.timeSeriesDataSources !== undefined && graph.timeSeriesDataSources.length ? graph.timeSeriesDataSources[0].graphAnnotations : [];
                    if (currentAnnotations !== undefined && currentAnnotations !== null && currentAnnotations.length) {
                        let blowerAnnotation = currentAnnotations.find(a => a.state === "Blower");
                        let compressorAnnotation = currentAnnotations.find(a => a.state === "Compressor");

                        if (blowerAnnotation !== undefined || compressorAnnotation !== undefined) {
                            // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local
                            if (blowerAnnotation !== undefined) {
                                let bandStart = moment(blowerAnnotation["startTime"]);
                                let bandEnd = moment(blowerAnnotation["endTime"]);
                                if (this.state.acSelected) {
                                    let start = this.state.jobStartClicked ? moment(blowerAnnotation["startTime"]).format("l h:mm:ss A") : moment(blowerAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let startDT = new Date(start);
                                    bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                    let end = this.state.jobStartClicked ? moment(blowerAnnotation["endTime"]).format("l h:mm:ss A") : moment(blowerAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let endDT = new Date(end);
                                    bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                }

                                let blowerData ={
                                    name: "Blower",
                                    type: 'polygon',
                                    color: blowerAnnotation["color"] + "45",
                                    zIndex: 1,
                                    data: [
                                        [bandStart, 0],
                                        [bandEnd, 0],
                                        [bandEnd, 1],
                                        [bandStart, 1]
                                    ]
                                };
                                runDataList = runDataList.concat(blowerData);
                            }

                            if (compressorAnnotation !== undefined) {
                                let bandStart = moment(compressorAnnotation["startTime"]);
                                let bandEnd = moment(compressorAnnotation["endTime"]);
                                if (this.state.acSelected) {
                                    let start = this.state.jobStartClicked ? moment(compressorAnnotation["startTime"]).format("l h:mm:ss A") : moment(compressorAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let startDT = new Date(start);
                                    bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                    let end = this.state.jobStartClicked ? moment(compressorAnnotation["endTime"]).format("l h:mm:ss A") : moment(compressorAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                    let endDT = new Date(end);
                                    bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                }

                                let compressorData ={
                                    name: "Compressor",
                                    type: 'polygon',
                                    color: compressorAnnotation["color"] + "45",
                                    zIndex: 1,
                                    data: [
                                        [bandStart, 1],
                                        [bandEnd, 1],
                                        [bandEnd, 2],
                                        [bandStart, 2]
                                    ]
                                };
                                runDataList = runDataList.concat(compressorData);
                            }
                        }
                    }
                    
                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    runDataList = runDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        runDataList = runDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if (currentHCSource.indoorTempData !== null) {
                            let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                            let indoorData = {
                                name: 'Indoor Hub Temp',
                                data: indoorArr,
                                color: '#d399e0',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            runDataList = runDataList.concat(indoorData);
                        }

                        if (currentHCSource.outdoorTempData !== null) {
                            let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                            let outdoorData = {
                                name: 'Outdoor Hub Temp',
                                data: outdoorArr,
                                color: '#7fc6f5',
                                type: 'line',
                                zIndex: 2,
                                pointStart: pointStartMoment,
                                pointInterval: 1000,
                                yAxis: 1
                            };
                            runDataList = runDataList.concat(outdoorData);
                        }
                    }

                    if (this.state.currentGraph?.rla !== null && this.state.currentGraph?.rla !== undefined) {
                        let rlaVal = this.state.currentGraph.rla;
                        let rlaArr = Array(currentHCSource.data.length - 1).fill(rlaVal);
                        let rlaLabel = {
                            y: rlaVal,
                            dataLabels: { enabled: true, format: 'RLA' }
                        };
                        rlaArr = rlaArr.concat(rlaLabel);
                        let rlaData = {
                            name: 'RLA',
                            data: rlaArr,
                            color: '#f0b169',
                            type: 'line',
                            zIndex: 2,
                            yAxis: 0,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            dashStyle: 'longDash'
                        };
                        runDataList = runDataList.concat(rlaData);
                    }

                    if (this.state.currentGraph?.lra !== null && this.state.currentGraph?.lra !== undefined) {
                        let lraVal = this.state.currentGraph.lra;
                        let lraArr = Array(currentHCSource.data.length - 1).fill(lraVal);
                        let lraLabel = {
                            y: lraVal,
                            dataLabels: { enabled: true, format: 'LRA' }
                        };
                        lraArr = lraArr.concat(lraLabel);
                        let lraData = {
                            name: 'LRA',
                            data: lraArr,
                            color: 'red',
                            type: 'line',
                            zIndex: 2,
                            yAxis: 0,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            dashStyle: 'longDash'
                        };
                        runDataList = runDataList.concat(lraData);
                    }
                }

                if (graph.highchartsDaily !== null && graph.highchartsDaily !== undefined && graph.highchartsDaily.data !== null && graph.highchartsDaily.data !== undefined && graph.highchartsDaily.data.length) {
                    let currentHCSource = graph.highchartsDaily;
                    let dataArr = currentHCSource.data.map((item) => item[1]);
                    let pointMoment = this.state.jobStartClicked ? moment(currentHCSource.data[0][0]).format("l h:mm:ss A") : moment(currentHCSource.data[0][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A")
                    var dt = new Date(pointMoment);
                    var pointStartMoment = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds(), dt.getMilliseconds());
                    let lastMoment = this.state.jobStartClicked ? moment(currentHCSource.data[currentHCSource.data.length -1][0]).format("l h:mm:ss A") : moment(currentHCSource.data[currentHCSource.data.length -1][0]).add(moment().utcOffset() * -1, 'minutes').format("l h:mm:ss A");
                    var dtLast = new Date(lastMoment);
                    var pointLastMoment = Date.UTC(dtLast.getFullYear(), dtLast.getMonth(), dtLast.getDate(), dtLast.getHours(), dtLast.getMinutes(), dtLast.getSeconds(), dtLast.getMilliseconds());

                    let currentAnnotations = graph.daily?.graphAnnotations;
                    if (currentAnnotations !== undefined && currentAnnotations !== null && currentAnnotations.length) {
                        let blowerAnnotations = currentAnnotations.filter(a => a.state === "Blower");
                        let compressorAnnotations = currentAnnotations.filter(a => a.state === "Compressor");

                        // note - for AC annotations, the UTC offset calculation is necessary for release but needs to be removed to show correctly when developing on local (this note from assets page...)
                        if ((blowerAnnotations !== undefined && blowerAnnotations.length) || (compressorAnnotations !== undefined && compressorAnnotations.length)) {
                            if (blowerAnnotations !== undefined && blowerAnnotations.length) {
                                let blowerDataList = [];
                                for (let i = 0; i < blowerAnnotations.length; i++) {
                                    let blowerAnnotation = blowerAnnotations[i];
                                    if (blowerAnnotation !== undefined) {
                                        let bandStart = moment(blowerAnnotation["startTime"]);
                                        let bandEnd = moment(blowerAnnotation["endTime"]);
                                        if (this.state.acSelected) {
                                            let start = this.state.jobStartClicked ? moment(blowerAnnotation["startTime"]).format("l h:mm:ss A") : moment(blowerAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let startDT = new Date(start);
                                            bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                            let end = this.state.jobStartClicked ? moment(blowerAnnotation["endTime"]).format("l h:mm:ss A") : moment(blowerAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let endDT = new Date(end);
                                            bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                        }

                                        let blowData = [
                                            [bandStart, 0],
                                            [bandEnd, 0],
                                            [bandEnd, 1],
                                            [bandStart, 1],
                                            null //allows for second shape
                                        ];
                                        blowerDataList = blowerDataList.concat(blowData);
                                    }
                                }
                                let blowerData ={
                                    name: "Blower",
                                    type: 'polygon',
                                    color: blowerAnnotations[0]["color"] + "45",
                                    zIndex: 1,
                                    data: blowerDataList
                                };
                                timelineDataList = timelineDataList.concat(blowerData);
                            }

                            if (compressorAnnotations !== undefined && compressorAnnotations.length) {
                                let compressorDataList = [];
                                for (let i = 0; i < compressorAnnotations.length; i++) {
                                    let compressorAnnotation = compressorAnnotations[i];
                                    if (compressorAnnotation !== undefined) {
                                        let bandStart = moment(compressorAnnotation["startTime"]);
                                        let bandEnd = moment(compressorAnnotation["endTime"]);
                                        if (this.state.acSelected) {
                                            let start = this.state.jobStartClicked ? moment(compressorAnnotation["startTime"]).format("l h:mm:ss A") : moment(compressorAnnotation["startTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let startDT = new Date(start);
                                            bandStart = Date.UTC(startDT.getFullYear(), startDT.getMonth(), startDT.getDate(), startDT.getHours(), startDT.getMinutes(), startDT.getSeconds(), startDT.getMilliseconds());

                                            let end = this.state.jobStartClicked ? moment(compressorAnnotation["endTime"]).format("l h:mm:ss A") : moment(compressorAnnotation["endTime"]).add(moment().utcOffset()*-1, 'minutes').format("l h:mm:ss A");
                                            let endDT = new Date(end);
                                            bandEnd = Date.UTC(endDT.getFullYear(), endDT.getMonth(), endDT.getDate(), endDT.getHours(), endDT.getMinutes(), endDT.getSeconds(), endDT.getMilliseconds());
                                        }
                                        let compData = [
                                            [bandStart, 1],
                                            [bandEnd, 1],
                                            [bandEnd, 2],
                                            [bandStart, 2],
                                            null
                                        ];
                                        compressorDataList = compressorDataList.concat(compData);
                                    }
                                }
                                let compressorData ={
                                    name: "Compressor",
                                    type: 'polygon',
                                    color: compressorAnnotations[0]["color"] + "45",
                                    zIndex: 1,
                                    data: compressorDataList
                                };
                                timelineDataList = timelineDataList.concat(compressorData);
                            }
                        }
                    }
                    
                    let chartData = {
                        name: this.state.acSelected ? 'AC Current' : 'Current',
                        data: dataArr,
                        color: '#176a95',
                        type: 'line',
                        zIndex: 2,
                        pointStart: pointStartMoment,
                        pointInterval: 1000,
                        yAxis: 0
                    };
                    timelineDataList = timelineDataList.concat(chartData);

                    if (currentHCSource.furnaceData !== null && this.state.visibility.furnace) {
                        let furnArr = currentHCSource.furnaceData.map((item) => item[1]);
                        let furnData = {
                            name: 'Furnace Current',
                            data: furnArr,
                            color: 'purple',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0
                        };
                        timelineDataList = timelineDataList.concat(furnData);
                    }

                    if (this.state.visibility.temp) {
                        if ((currentHCSource.indoorTempData == null || currentHCSource.indoorTempData === undefined) && (currentHCSource.outdoorTempData === null || currentHCSource.outdoorTempData === undefined)) {
                            this.setState(prevState => ({
                                visibility: {
                                    ...prevState.visibility,
                                    temp: false
                                }
                            }));
                        }
                        else {
                            if (currentHCSource.indoorTempData !== null) {
                                let indoorArr = currentHCSource.indoorTempData.map((item) => item[1]);
                                let indoorData = {
                                    name: 'Indoor Hub Temp',
                                    data: indoorArr,
                                    color: '#d399e0',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: pointStartMoment,
                                    pointInterval: 1000,
                                    yAxis: 1
                                };
                                timelineDataList = timelineDataList.concat(indoorData);
                            }

                            if (currentHCSource.outdoorTempData !== null) {
                                let outdoorArr = currentHCSource.outdoorTempData.map((item) => item[1]);
                                let outdoorData = {
                                    name: 'Outdoor Hub Temp',
                                    data: outdoorArr,
                                    color: '#7fc6f5',
                                    type: 'line',
                                    zIndex: 2,
                                    pointStart: pointStartMoment,
                                    pointInterval: 1000,
                                    yAxis: 1
                                };
                                timelineDataList = timelineDataList.concat(outdoorData);
                            }
                        }
                    }

                    if (this.state.currentGraph?.rla !== null && this.state.currentGraph?.rla !== undefined) {
                        let rlaVal = this.state.currentGraph.rla;
                        let rlaArr = Array(currentHCSource.data.length - 1).fill(rlaVal);
                        let labelObj = {
                            y: rlaVal,
                            dataLabels: { enabled: true, format: 'RLA', allowOverlap: true }
                        };
                        rlaArr = rlaArr.concat(labelObj);
                        let rlaData = {
                            name: 'RLA',
                            data: rlaArr,
                            color: '#f0b169',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0,
                            dashStyle: 'longDash'
                        };
                        timelineDataList = timelineDataList.concat(rlaData);
                    }

                    if (this.state.currentGraph?.lra !== null && this.state.currentGraph?.lra !== undefined) {
                        let lraVal = this.state.currentGraph.lra;
                        let lraArr = Array(currentHCSource.data.length - 1).fill(lraVal);
                        let labelObj = {
                            y: lraVal,
                            dataLabels: { enabled: true, format: 'LRA', allowOverlap: true }
                        };
                        lraArr = lraArr.concat(labelObj);
                        let lraData = {
                            name: 'LRA',
                            data: lraArr,
                            color: 'red',
                            type: 'line',
                            zIndex: 2,
                            pointStart: pointStartMoment,
                            pointInterval: 1000,
                            yAxis: 0,
                            dashStyle: 'longDash'
                        };
                        timelineDataList = timelineDataList.concat(lraData);
                    }
                }
            }
            else {
                if (graph.run !== null && graph.run !== undefined && graph.run.series !== null && graph.run.series !== undefined && graph.run.series.length) {
                    for (let i = 0; i < graph.run.series.length; i++) {
                        let series = graph.run.series[i];
                        if (series.data.length) {
                            let dataArr = series.data.map((item) => (item.value));
                            let pointStart = moment(series.data[0].timestamp);
                            let data = {
                                name: series.name,
                                data: dataArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: pointStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            runDataList = runDataList.concat(data);
                        }
                    }
                }

                if (graph.daily !== null && graph.daily !== undefined && graph.daily.series !== null && graph.daily.series !== undefined && graph.daily.series.length) {
                    for (let j = 0; j < graph.daily.series.length; j++) {
                        let series = graph.daily.series[j];
                        if (series.data.length) {

                            let dailyArr = series.data.map((item) => (item.value));
                            let dailyStart = moment(series.data[0].timestamp);
                            let dailyData = {
                                name: series.name,
                                data: dailyArr,
                                color: series.color,
                                zIndex: 2,
                                type: 'line',
                                pointStart: dailyStart,
                                pointInterval: 1000,
                                yAxis: 0
                            };

                            timelineDataList = timelineDataList.concat(dailyData);
                        }
                    }
                }
            }
        }

        if (multiGraph !== null && multiGraph !== undefined && multiGraph.series !== undefined && multiGraph.series.length) {
            for (let i = 0; i < multiGraph.series.length; i++) {
                let series = multiGraph.series[i];
                if (series.data.length) {

                    let multiArr = series.data.map((item) => (item.value));
                    let multiStart = moment(series.data[0].timestamp);
                    let multiEnd = moment(series.data[series.data.length - 1].timestamp);
                    let timeDiff = multiEnd.diff(multiStart);

                    let multiData = {
                        name: series.name,
                        data: multiArr,
                        color: series.color,
                        zIndex: 2,
                        type: 'line',
                        pointStart: multiStart,
                        pointInterval: timeDiff / 800,
                        yAxis: 0
                    };

                    multiDataList = multiDataList.concat(multiData);
                }
            }
        }
        
        this.setState({
            runDataList: runDataList,
            timelineDataList: timelineDataList,
            multiDataList: multiDataList,
            currentMax: currentMax
        }, () => {
            let rlaMin = graph.rla - 5;
            
            if (this.state.acSelected && currentMax >= rlaMin) {
                this.setState(prevState => ({
                    visibility: {
                        ...prevState.visibility,
                        rla: true
                    }
                }));
            }
        });
    }

    getHighchartsGraphData(currentHCSource) {
        let chartDataList = [];
        let fullChartDataList = currentHCSource;
        if (fullChartDataList !== null && fullChartDataList !== undefined) {
            let acCurrent = fullChartDataList?.find(x => x.name === "AC Current" || x.name === "Air Handler Current");
            if (acCurrent !== null && acCurrent !== undefined) {
                chartDataList = chartDataList.concat(acCurrent);
            }


            if (this.state.visibility.temp) {
                let hasTempData = false;
                let indoorHubTemp = fullChartDataList?.find(x => x.name === "Indoor Hub Temp");
                if (indoorHubTemp !== null && indoorHubTemp !== undefined) {
                    chartDataList = chartDataList.concat(indoorHubTemp);
                    hasTempData = true;
                }
                let outdoorHubTemp = fullChartDataList?.find(x => x.name === "Outdoor Hub Temp");
                if (outdoorHubTemp !== null && outdoorHubTemp !== undefined) {
                    chartDataList = chartDataList.concat(outdoorHubTemp);
                    hasTempData = true
                }
            }
            if (this.state.visibility.furnace) {
                let furenceCurrent = fullChartDataList?.find(x => x.name === "Furnace Current");
                if (furenceCurrent !== null && furenceCurrent !== undefined) {
                    chartDataList = chartDataList.concat(furenceCurrent);
                }

            }
            if (this.state.visibility.rla) {
                let rlaLine = fullChartDataList?.find(x => x.name === 'RLA');
                if (rlaLine !== null && rlaLine !== undefined) {
                    chartDataList = chartDataList.concat(rlaLine);
                }
            }
            if (this.state.visibility.lra) {
                let lraLine = fullChartDataList?.find(x => x.name === 'LRA');
                if (lraLine !== null && lraLine !== undefined) {
                    chartDataList = chartDataList.concat(lraLine);
                }
            }
            
            if (this.state.visibility.analytics || this.state.showRunAnnotations) {
                let blowerAnn = fullChartDataList?.find(x => x.name === 'Blower');
                if (blowerAnn !== null && blowerAnn !== undefined) {
                    chartDataList = chartDataList.concat(blowerAnn)
                }
                let compressorAnn = fullChartDataList?.find(x => x.name === 'Compressor');
                if (compressorAnn !== null && compressorAnn !== undefined) {
                    chartDataList = chartDataList.concat(compressorAnn)
                }

                let expectedAnns = fullChartDataList?.filter(x => x.name.includes("Expected"));
                if (expectedAnns !== null && expectedAnns !== undefined && expectedAnns.length) {
                    chartDataList.push(...expectedAnns);
                }

                let avgAnns = fullChartDataList?.filter(x => x.name.includes("Average"));
                if (avgAnns !== null && avgAnns !== undefined && avgAnns.length) {
                    chartDataList.push(...avgAnns);
                }
            }
        }
        
        return chartDataList;
    }
    
    getVerify(verified) {
        var v = "";
        if (verified === true) {
            v = "Yes";
        }
        else if (verified === null) {
            v = "No";
        }
        else if (verified === false) {
            v = "False Positive";
        }
        return (v);
    }

    getStartDate(startTime) {
        var d = new Date(startTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (startTime == "" || startTime == null)
            date = "";

        return (date);
    }

    getEndDate(endTime) {
        var d = new Date(endTime);
        var hr = d.getHours() < 10 ? "0" : "";
        var min = d.getMinutes() < 10 ? "0" : "";
        var sec = d.getSeconds() < 10 ? "0" : "";
        var month = d.getMonth() + 1;

        var date = month + "/" + d.getDate() + "/" + d.getFullYear() + " " + d.toLocaleTimeString('en-US');

        if (endTime == "" || endTime == null) {
            date = "Active";
            return (<div style={{ color: 'red' }}>{date}</div>);
        }
        else {
            return (date);
        }
    }

    getAddress() {
        var addy = "";

        if (this.state.currentCustomer.address !== "" && this.state.currentCustomer.address !== null) {
            addy = this.state.currentCustomer.address;

            if (this.state.currentCustomer.city !== "" && this.state.currentCustomer.city !== null) {
                addy += ", " + this.state.currentCustomer.city;
            }

            if (this.state.currentCustomer.state !== "" && this.state.currentCustomer.state !== null) {
                addy += ", " + this.state.currentCustomer.state;

                if (this.state.currentCustomer.zip !== "" && this.state.currentCustomer.zip !== null)
                    addy += " " + this.state.currentCustomer.zip;
            }
        }
        return (addy);
    }

    getPropertyValueRaw(propertyName) {
        if (this.state.currentAsset.properties) {
            let result = this.state.currentAsset.properties.find((i) => i.name == propertyName);
            return result ? result["valueRaw"] : null;
        }
    }

    showZoomButton(show) {
        this.setState({ showZoomButton: show });
    }

    getHighchartsAnnotations(graphAnnotations) {
        var plotBands = [];

        if (graphAnnotations !== null && graphAnnotations !== undefined && graphAnnotations.length) {
            for (let i = 0; i < graphAnnotations.length; i++) {
                let annotation = graphAnnotations[i];
                let bandStart = moment(annotation["startTime"]);
                let bandEnd = moment(annotation["endTime"]);

                let band = {
                    color: annotation["color"] + "45", /* opacity */
                    from: bandStart,
                    to: bandEnd,
                    label: {
                        text: annotation["state"],
                        rotation: -90,
                        textAlign: 'left',
                        verticalAlign: 'top',
                        y: 90
                    },
                    zIndex: 1
                };

                plotBands = plotBands.concat(band);
            }
        }

        return plotBands;
    }

    getAlertSeverity(alertId) {
        let alertMsgObj = this.props.alertMessaging.find(a => a.id === parseInt(alertId));
        if (alertMsgObj !== null && alertMsgObj !== undefined) {
            if (alertMsgObj.severity === "Critical") {
                return (<Tooltip title="Critical" enterDelay={600} leaveDelay={200}>
                        <NotificationIcon className="db-red db-red-bg" style={{ borderRadius: '12px', padding: '3px' }} />
                    </Tooltip>
                );
            }
            else {
                return (<Tooltip title="Warning" enterDelay={600} leaveDelay={200}>
                        <NotificationIcon className="db-orange db-orange-bg" style={{ borderRadius: '12px', padding: '3px' }} />
                    </Tooltip>
                );
            }
        }
        return (<Tooltip title="Warning" enterDelay={600} leaveDelay={200}>
            <NotificationIcon className="db-orange db-orange-bg" style={{ borderRadius: '12px', padding: '3px' }} />
        </Tooltip>);
    }

    getExpandContent(index, alert) {
        const { classes } = this.props;
        let isJobExists = false;
        if (alert.alert !== null && alert.alert !== undefined && alert.alert.tagIds !== null && alert.alert.tagIds !== undefined && alert.alert.tagIds.includes(TagIdsEnum.JobCreated)) {
            isJobExists = true;
        }
        
        let expandedLoader = this.state.alertDetailLoading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}><div className="loading-wrapper">
                <div className="modal-body"><Spinner animation="border" variant="light" /></div>
            </div></div>
            : null;


        if (this.state.isSelected === index) {
            if (this.state.alertCases.length > 0) {
                let caseid = this.state.alertCases.find(x => x.caseNumber === "A" + alert.alertId)?.caseNumber;
                if (caseid !== null && caseid !== undefined && caseid !== "undefined") {
                    let link = "/scheduleRepairs?jobNumber=" + caseid;
                }
                else {
                    let link = "/scheduleRepairs";
                }

            }

            return (
                <tr className="expanded" key={`expandedContent-${index}`} style={{ backgroundColor: 'white !important' }}>
                    <td colSpan={10} style={{ color: 'unset' }}>
                        <div className="row">
                            <div className="col offset-1">
                                <h6 className="db-blue">Customer</h6>
                                <p><span style={{ color: 'grey' }}>Name</span><br />
                                    {this.state.currentCustomer.name}
                                </p>
                                <p><span style={{ color: 'grey' }}>Phone Number</span><br />
                                    {this.state.currentCustomer.phone}
                                </p>
                                <p><span style={{ color: 'grey' }}>Email</span><br />
                                    {this.state.currentCustomer.email}
                                </p>
                                <p><span style={{ color: 'grey' }}>Address</span><br />
                                    {this.getAddress()}
                                </p>
                            </div>
                            <div className="col">
                                <h6 className="db-blue">Asset</h6>
                                <p>
                                    <span style={{ color: 'grey' }}>Make</span><br />
                                    {this.state.currentAsset.make}
                                </p>
                                <p><span style={{ color: 'grey' }}>Model</span><br />
                                    {this.state.currentAsset.model}
                                </p>
                                <p><span style={{ color: 'grey' }}>Manufacturing Date</span><br />
                                    {this.state.currentAsset.mfgDate}
                                </p>
                            </div>
                            <div className="col-4">
                                <h6 className="db-blue">Alert Details</h6>
                                <p><span style={{ color: 'grey' }}>Possible Causes</span><br />
                                    {this.state.alertMsgObj?.possibleCauses}
                                </p>
                                <p><span style={{ color: 'grey' }}>Suggested Technician Action</span><br />
                                    {this.state.alertMsgObj?.techAction}
                                </p>
                                <p><span style={{ color: 'grey' }}>Suggested Homeowner Action</span><br />
                                    {this.state.alertMsgObj?.homeownerAction}
                                </p>
                                <p><span style={{ color: 'grey' }}>Risk</span><br />
                                    {this.state.alertMsgObj?.risk}
                                </p>

                            </div>
                            {expandedLoader}
                        </div>
                        <div className="bottom-right">
                            <Tooltip style={{ position: "absolute", right: "0px", bottom: "0px" }} title="Question about this alert? Submit a question to ProIQ Advanced LifeWhere Support by clicking the comments button."
                                     enterDelay={600} leaveDelay={200}>
                                <HelpIcon />
                            </Tooltip>
                        </div>
                    </td>
                </tr>
            );
        }
        else
            return (<tr>{null}</tr>);
    }

    renderTable(alertType) {
        const { classes } = this.props;
        let table = null;

        let alertLoader = this.props.alertTableLoading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let activeContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    [<tr className="alerts-table-row" key={`${alert.alertId}-tableRow`} data={index} value={index} onClick={() => this.onAlertClick(index)}>
                        <td style={{ borderLeft: '1px solid #ebeef0', textAlign: 'center' }}>
                            {this.getAlertSeverity(parseInt(alert.typeId))}
                        </td>
                        <td style={{ textAlign: 'center' }}>{alert.message}</td>
                        <td style={{ textAlign: 'center' }}>{this.getStartDate(alert.startTime)}</td>
                        <td style={{ textAlign: 'center' }}>{this.getEndDate(alert.endTime)}</td>
                        <td style={{ textAlign: 'center' }}>{this.getVerify(alert.isVerified)}</td>
                        <td className="alerts-td-right" style={{ textAlign: 'center' }}>
                            {this.state.isSelected === index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                        </td>
                    </tr>,
                    this.state.isSelected === index && (this.getExpandContent(index, alert))
                ])}
            </tbody>
            : <tbody>
                <tr className={"alerts-table-row"}><td colSpan={5} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>{this.props.alertTableLoading ? "Loading Active Alerts..." : "No Active Alerts"}</td></tr>
            </tbody>;

        let inactiveContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    [<tr className="alerts-table-row" key={`${alert.alertId}-tableRow`} data={index} value={index} onClick={() => this.onAlertClick(index)}>
                        <td style={{ borderLeft: '1px solid #ebeef0', textAlign: 'center' }}>
                            {this.getAlertSeverity(parseInt(alert.typeId))}
                        </td>
                        <td style={{ textAlign: 'center' }}>{alert.message}</td>
                        <td style={{ textAlign: 'center' }}>{this.getStartDate(alert.startTime)}</td>
                        <td style={{ textAlign: 'center' }}>{this.getEndDate(alert.endTime)}</td>
                        <td style={{ textAlign: 'center' }}>{this.getVerify(alert.isVerified)}</td>
                        <td className="alerts-td-right">
                            {this.state.isSelected === index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                        </td>
                    </tr>,
                    this.state.isSelected === index && (this.getExpandContent(index, alert))
                ])}
                {/*{this.props.alertList.map((alert, index) =>
                    <tr key={alert.alertId} data={index} className={"alerts-table-row"}>
                        <td onClick={() => { this.onAlertClick(index) }} style={{ borderLeft: '1px solid #ebeef0' }}>{alert.priorityName}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{alert.message}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                        <td onClick={() => { this.onAlertClick(index) }} style={{ borderRight: '1px solid #ebeef0' }}>{this.getVerify(alert.isVerified)}</td>
                    </tr>
                )}*/}
            </tbody>
            : <tbody>
                <tr className={"alerts-table-row"}><td colSpan={5} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>{this.props.alertTableLoading ? "Loading Intermittent Alerts..." : "No Intermittent Alerts"}</td></tr>
            </tbody>;

        let closedContent = this.props.alertList.length ?
            <tbody>
            {this.props.alertList.map((alert, index) =>
                [<tr className="alerts-table-row" key={`${alert.alertId}-tableRow`} data={index} value={index} onClick={() => this.onAlertClick(index)}>
                    <td style={{ borderLeft: '1px solid #ebeef0', textAlign: 'center' }}>
                        {this.getAlertSeverity(parseInt(alert.typeId))}
                    </td>
                    <td style={{ textAlign: 'center' }}>{alert.message}</td>
                    <td style={{ textAlign: 'center' }}>{this.getStartDate(alert.startTime)}</td>
                    <td style={{ textAlign: 'center' }}>{this.getEndDate(alert.endTime)}</td>
                    <td style={{ textAlign: 'center' }}>{this.getVerify(alert.isVerified)}</td>
                    <td style={{ textAlign: 'center' }}>{this.getStartDate(alert.closedOn)}</td>
                    <td className="alerts-td-right">
                        {this.state.isSelected === index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                    </td>
                </tr>,
                    this.state.isSelected === index && (this.getExpandContent(index, alert))
                ])}
            </tbody>
            : <tbody>
                <tr className={"alerts-table-row"}><td colSpan={6} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>{this.props.alertTableLoading ? "Loading Closed Alerts..." : "No Closed Alerts"}</td></tr>
            </tbody>;

        let repairsContent = this.props.alertList.length ?
            <tbody>
                {this.props.alertList.map((alert, index) =>
                    [<tr className="alerts-table-row" key={`${alert.alertId}-tableRow`} data={index} value={index} onClick={() => this.onAlertClick(index)}>
                        <td style={{ borderLeft: '1px solid #ebeef0', textAlign: 'center' }}>
                            {this.getAlertSeverity(parseInt(alert.typeId))}
                        </td>
                        <td style={{ textAlign: 'center' }}>{alert.message}</td>
                        <td style={{ textAlign: 'center' }}>{this.getStartDate(alert.startTime)}</td>
                        <td style={{ textAlign: 'center' }}>{this.getEndDate(alert.endTime)}</td>
                        <td className="alerts-td-right">
                            {this.state.isSelected === index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                        </td>
                    </tr>,
                    this.state.isSelected === index && (this.getExpandContent(index, alert))
                ])}
            </tbody>
            : <tbody>
                <tr className={"alerts-table-row"}><td colspan={5} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>{this.props.alertTableLoading ? "Loading Repairs Alerts..." : "No Repairs Alerts"}</td></tr>
            </tbody>;

        let deviceContent = this.props.alertList.length ?
            <tbody>
            {this.props.alertList.map((alert, index) =>
                [<tr className="alerts-table-row" key={`${alert.alertId}-tableRow`} data={index} value={index} onClick={() => this.onAlertClick(index)}>
                    <td style={{ borderLeft: '1px solid #ebeef0' }}>
                        {this.getAlertSeverity(parseInt(alert.typeId))}
                    </td>
                    <td>{alert.message}</td>
                    <td>{this.getStartDate(alert.startTime)}</td>
                    <td>{this.getEndDate(alert.endTime)}</td>
                    <td className="alerts-td-right">
                        {this.state.isSelected === index ? <ArrowUpIcon className="db-blue-reverse" style={{ borderRadius: '12px' }} /> : <ArrowDownIcon className="db-blue db-blue-bg" style={{ borderRadius: '12px' }} />}
                    </td>
                </tr>,
                    this.state.isSelected === index && (this.getExpandContent(index, alert))
                ])}
            </tbody>
            : <tbody>
                <tr className={"alerts-table-row"}><td colspan={5} style={{ borderLeft: '1px solid #ebeef0', borderRight: '1px solid #ebeef0' }}>{this.props.alertTableLoading ? "Loading Device Alerts..." : "No Device Alerts"}</td></tr>
            </tbody>;

        switch (alertType) {
            case "Active":
                table =
                    <div style={{ maxHeight: "400px", marginBottom: '15px', overflow: "auto" }}>
                        <table className='table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                            <thead>
                            <tr className="alerts-table-row">
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>SEVERITY</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>ALERT MESSAGE</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>START</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>END</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>VERIFIED</th>
                                <th className="table-shrink" style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                            </tr>
                            </thead>
                            {activeContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Inactive":
                table =
                    <div style={{ maxHeight: "400px", marginBottom: '15px', overflow: "auto" }}>
                        <table className='table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                            <thead>
                            <tr className="alerts-table-row">
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>SEVERITY</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>ALERT MESSAGE</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>START</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>END</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>VERIFIED</th>
                                <th className="table-shrink" style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                            </tr>
                            </thead>
                            {inactiveContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Closed":
                table =
                    <div style={{ maxHeight: "400px", marginBottom: '15px', overflow: "auto" }}>
                        <table className='table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                            <thead>
                            <tr className="alerts-table-row">
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>SEVERITY</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>ALERT MESSAGE</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>START</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>END</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>VERIFIED</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>CLOSED</th>
                                <th className="table-shrink" style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                            </tr>
                            </thead>
                            {closedContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Repairs":
                table =
                    <div style={{ maxHeight: "400px", marginBottom: "15px", overflow: "auto" }}>
                        <table className='table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                            <thead>
                            <tr className="alerts-table-row">
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>SEVERITY</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>ALERT MESSAGE</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>START</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>END</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>VERIFIED</th>
                                <th className="table-shrink" style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                            </tr>
                            </thead>
                            {repairsContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;

            case "Device":
                table =
                    <div style={{ maxHeight: "400px", marginBottom: "15px", overflow: "auto" }}>
                        <table className='table-hover' aria-labelledby="tabelLabel" style={{ width: '100%' }}>
                            <thead>
                            <tr className="alerts-table-row">
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>SEVERITY</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>ALERT MESSAGE</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>START</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>END</th>
                                <th style={{textAlign: 'center', padding: '10px 0px'}}>VERIFIED</th>
                                <th className="table-shrink" style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                            </tr>
                            </thead>
                            {deviceContent}
                        </table>
                        {alertLoader}
                    </div>;
                break;
        }

        return table;
    }

    renderAlerts() {
        const { classes } = this.props;

        let alertDetailLoader = this.state.alertDetailLoading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let alertTable = this.renderTable(this.state.alertType);

        let jobDetailBtn = this.state.alertType == "Repairs" ?
            <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("View Job Details Clicked", "User clicked the view job details button", "View Job Details Clicked"); this.openJobDetailModal(this.state.currentAlert); }}>View Job Details</button>
            : null;

        let devicesBtn = this.state.alertType == "Device" ?
            <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("View Devices Clicked", "User clicked the view devices button", "View Devices Clicked"); this.openDevicesModal(this.state.currentAlert); }}>View Devices</button>
            : null;

        let alertButtons = this.props.showButtons ?
            <div className="btn-right">
                {jobDetailBtn}
                {devicesBtn}
                <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("Edit Alert Clicked", "User clicked edit alert button", "Edit Alert Clicked"); this.openEditAlertModal(this.state.currentAlert); }}>Edit Alert</button>
                <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("Create Job Clicked", "User clicked create job button", "Create Job Clicked"); this.openCreateJobModal(this.state.currentAlert, this.state.currentOpportunity); }}>Create Job</button>
                <button hidden={this.state.isSelected === -1} type="button" className="secondary-btn btn-small" onClick={() => { Event("Close Alert Clicked", "User clicked close alert button", "Close Alert Clicked"); this.openCloseAlertModal(this.state.currentAlert); }}>Close Alert</button>
            </div>
            : <div>{null}</div>;

        return (
            <div>
                <div className="row no-gutters" style={{ display: 'flex' }}>
                    <div className="col-sm-12 col-md-12">
                        
                        <div className="white-container" style={{ minHeight: '100%' }}>
                            <h5 className="sectionTitle">Alerts</h5>
                            <div className="radio-container" onChange={e => this.changeRadio(e)} >
                                <span>
                                    <input type="radio" value="Active" defaultChecked name="alertType" />
                                    &nbsp; Active &nbsp;&nbsp;
                                <input type="radio" value="Inactive" name="alertType" />
                                    &nbsp; Intermittent &nbsp;&nbsp;
                                <input type="radio" value="Closed" name="alertType" />
                                    &nbsp; Closed &nbsp;&nbsp;
                                <input type="radio" value="Repairs" name="alertType" />
                                    &nbsp; Repairs &nbsp;&nbsp;
                                <input type="radio" value="Device" name="alertType" />
                                    &nbsp; Device &nbsp;&nbsp;
                            </span>
                            </div>
                            <div className="table-container">
                                {alertTable}
                            </div>
                        </div>
                    </div>
                </div>
                {alertDetailLoader}
            </div>
        );
    }

    renderACGraph(isRunPanel) {
        let acRunPanel = this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.currentGraph !== undefined && this.state.currentGraph.timeSeriesDataSources !== undefined && this.state.currentGraph.timeSeriesDataSources.length && this.state.currentGraph.highchartsRun?.data?.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource.timeString}-${this.state.runDataList.length}-${this.state.visibility}`} isAirConditioner={true} yDataMax={0}
                            showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource.timeString /*currentGraph.highchartsRun.name*/}
                            pointStart={this.state.currentGraph.highchartsRun !== null && this.state.currentGraph.highchartsRun !== undefined && this.state.currentGraph.highchartsRun.data !== null && this.state.currentGraph.highchartsRun.data.length ? this.state.currentGraph.highchartsRun?.data[0] : this.state.currentGraph.highchartsDataSources[0]?.data[0]} hasTempAxis={true} />

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <ACRightPanel onClick={this.openFullRunModal} isFullScreen={false} isDaily={false} showAnnotations={this.state.showRunAnnotations} onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }} visibility={this.state.visibility}
                        handleCheckbox={this.handleCheckbox} zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} canLeftClick={this.state.canLeftClick == true ? true : false} canRightClick={this.state.canRightClick == true ? true : false}
                        runNamesList={this.state.currentGraph.runNamesList} selectedRun={this.state.selectedRun} onChangeRun={this.onChangeRun} onLeftRunClick={this.onLeftRunClick} onRightRunClick={this.onRightRunClick} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acJobPanel = this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.currentGraph !== undefined && this.state.currentGraph.timeSeriesDataSources !== undefined && this.state.currentGraph.timeSeriesDataSources.length && this.state.currentGraph.highchartsRun?.data?.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource.timeString}-${this.state.runDataList.length}-${this.state.visibility}`} isAirConditioner={true} yDataMax={0}
                                        showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                                        showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource.timeString /*currentGraph.highchartsRun.name*/}
                                        pointStart={this.state.currentGraph.highchartsRun !== null && this.state.currentGraph.highchartsRun !== undefined && this.state.currentGraph.highchartsRun.data !== null && this.state.currentGraph.highchartsRun.data.length ? this.state.currentGraph.highchartsRun?.data[0] : this.state.currentGraph.highchartsDataSources[0]?.data[0]} hasTempAxis={true} />

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <JobRightPanel selected={this.state.dpJobStartDateTime} onChange={date => {
                        Event("Job Start Date Click", "User clicked job start date picker to change it", "Job Start Date Changed");
                        this.onChangeJobStart(date);
                    }} selected1={this.state.dpJobEndDateTime} onChange1={date => {
                        Event("Job End Date Click", "User clicked job end date picker to change it", "Job End Date Changed");
                        this.onChangeJobEnd(date);
                    }} onClick={this.onChangeJobStart} onClick1={this.onResetJob} onClick2={this.onChangeJobEnd}
                                   showZoomButton={this.state.showZoomButton} showAnalyticsButton={true} jobStartClicked={this.state.jobStartClicked} jobEndClicked={this.state.jobEndClicked}
                                   onClick3={this.runAnalytic.bind(this)} showAnnotations={this.state.showRunAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics === true} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acDailyPanel = this.state.currentGraph !== undefined && this.state.currentGraph.daily !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily.dataArray.length && this.state.currentGraph.highchartsDaily?.data?.length ?
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col-sm-12 col-md-8">
                    <div className="highlight-bar-charts" >
                        <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.timelineDataList)} key={`${this.state.currentGraph.highchartsDaily.name}-${this.state.visibility}-timeline`} isAirConditioner={true} yDataMax={0/*this.state.currentGraph.daily?.maxY*/}
                             showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)} showAnnotations={this.state.showRunAnnotations}
                             showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentGraph.highchartsRun.name}
                             pointStart={this.state.currentGraph.highchartsDaily.data[0]} hasTempAxis={true} />

                        <br /><br />
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <ACRightPanel onClick={this.openFullDailyModal} isFullScreen={false} isDaily={true} showAnnotations={this.state.showRunAnnotations} onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }} 
                        handleCheckbox={this.handleCheckbox} visibility={this.state.visibility} zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} />
                </div>
            </div>
            : <div className="centered"><br />No Data found!</div>;

        let acPanel = isRunPanel ? acJobPanel : acDailyPanel;//isRunPanel ? acRunPanel : (isJobPanel ? acJobPanel : acDailyPanel);

        return (acPanel);
    }
    
    renderGraph() {
        let multiGraph = this.state.multiseriesGraph !== null && this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph.series !== null && this.state.multiseriesGraph.series !== undefined && this.state.multiseriesGraph.series[0] !== undefined && this.state.multiseriesGraph.series[0] !== null ?
            <div>
                <HighchartGraph graphType={"MULTISERIES"} chartData={this.state.multiDataList} key={`Multiseries-${this.state.multiseriesGraph.series.length}`} graph={this.state.multiseriesGraph} isAirConditioner={true} dataMax={this.state.multiseriesGraph.yDataMax}
                    showRLA={false} rlaValue={this.state.currentGraph.rla} showLRA={false} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.multiseriesGraph.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={null} pointStart={this.state.multiseriesGraph.series[0].data[0]?.timestamp} />
            </div>
            : <div className="centered"><br/>No Data found!</div>;
        
        let jobPanel = this.state.acGraph ?
            <div>{this.renderACGraph(true)}</div>
            : this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run !== undefined && this.state.currentGraph.run.series !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-12 col-md-10">
                        <div className="highlight-bar-charts" >
                            <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentSource?.timeString}-${this.state.runDataList.length}-${this.state.visibility}`} isAirConditioner={false} dataMax={this.state.currentGraph.run.yDataMax}
                                            showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                            <br /><br />
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-2" style={{ marginTop: "25px" }}>
                        <JobRightPanel selected={this.state.dpJobStartDateTime} onChange={date => {
                            Event("Job Start Date Click", "User clicked job start date picker to change it", "Job Start Date Changed");
                            this.onChangeJobStart(date);
                        }} selected1={this.state.dpJobEndDateTime} onChange1={date => {
                            Event("Job End Date Click", "User clicked job end date picker to change it", "Job End Date Changed");
                            this.onChangeJobEnd(date);
                        }} onClick={this.onChangeJobStart} onClick1={this.onResetJob} onClick2={this.onChangeJobEnd}
                            showZoomButton={this.state.showZoomButton} showAnalyticsButton={true} jobStartClicked={this.state.jobStartClicked} jobEndClicked={this.state.jobEndClicked}
                            onClick3={this.runAnalytic.bind(this)} showAnnotations={this.state.showRunAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics === true} />
                    </div>
                </div>
                : <div className="centered"><br />No Data found!</div>

        /*let runPanel = this.state.acGraph ?
            <div>{this.renderACGraph(true)}</div>
            : (this.state.currentSource !== null && this.state.currentSource !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run !== undefined && this.state.currentGraph.run.series !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-12 col-md-10">
                        <div className="highlight-bar-charts" >
                            <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentSource?.timeString}-${this.state.runDataList.length}-${this.state.visibility}`} isAirConditioner={false} dataMax={this.state.currentGraph.run.yDataMax}
                                showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                                showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                            <br /><br />
                        </div>
                    </div>
                    <div className="col-sm-4 col-md-2" style={{ marginTop: "25px" }}>
                        <RunRightPanel onClick={this.openFullRunModal} isFullScreen={false} showAnnotations={this.state.showRunAnnotations}
                            onClick1={this.runAnalytic.bind(this)} onClick2={() => { this.child.zoomOut(); }}
                            zoomOutEnabled={this.state.showZoomButton} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} />
                    </div>
                </div>
                : <div className="centered"><br />No Data found!</div>);*/

        let dailyPanel = this.state.acGraph ?
            <div>{this.renderACGraph(false)}</div>
            : (this.state.currentGraph.daily !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series[0] !== null && this.state.currentGraph.daily.series !== undefined ?
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-sm-12 col-md-8">
                        <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.timelineDataList)} key={`${this.state.currentGraph.daily?.timeString}-${this.state.visibility}`} isAirConditioner={false} dataMax={this.state.currentGraph.daily.yDataMax}
                            showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)}
                            showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentGraph.daily?.timeString} 
                            pointStart={this.state.currentGraph.daily.series[0].data[0]?.timestamp} />

                        <br /><br />
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <TimeRightPanel selected={this.state.dpStartDateTime} onChange={date => {
                            Event("Run Start Date Click", "User clicked start date picker to change it", "Run Start Date Changed");
                            this.onChangeStart(date);
                        }} selected1={this.state.dpEndDateTime} onChange1={date => {
                            Event("Run End Date Click", "User clicked end date picker to change it", "Run End Date Changed");
                            this.onChangeEnd(date);
                        }} onClick={this.onViewGraph} onClick1={this.openFullDailyModal}
                            showZoomButton={this.state.showZoomButton} showAnalyticsButton={true} onClick2={() => { this.child.zoomOut(); }}
                            onClick3={this.runAnalytic.bind(this)} showAnnotations={this.state.showRunAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics == true ? true : false} />
                        <br /><br />
                    </div>
                </div>
                : <div className="centered"><br />No Data found!</div>);

        let noDataStr = this.state.alertSelected ? "No Data Found!" : "Select an alert to display graph here";

        if (this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph !== null && this.state.multiseriesGraph.series !== null && this.state.multiseriesGraph.series !== undefined ) {
            return (
                <div style={{marginRight: 25 + "px"}}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Trend</Tab>
                        </TabList>
                        
                        <TabPanel>
                            {jobPanel}
                        </TabPanel>
                        <TabPanel>
                            <div className="row">
                                <div className="col-sm-12 col-md-8">
                                    <div style={{marginTop: 10 + "px", marginRight: 15 + "px"}}>
                                        {multiGraph}
                                        <br/><br/>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <TimeRightPanel selected={this.state.dpStartDateTime} onChange={date => {
                                        Event("Run Start Date Click", "User clicked start date picker to change it", "Run Start Date Changed");
                                        this.onChangeStart(date);
                                    }} selected1={this.state.dpEndDateTime} onChange1={date => {
                                        Event("Run End Date Click", "User clicked end date picker to change it", "Run End Date Changed");
                                        this.onChangeEnd(date);
                                    }} onClick={this.onViewGraph} onClick1={this.openFullMultiModal}
                                        showZoomButton={this.state.showZoomButton} haAnalytics={this.state.currentAsset.hasAnalytics} onClick2={() => {
                                        this.child.zoomOut();
                                    }}/>
                                    <br/><br/>
                                </div>
                            </div>

                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
        else if (Object.keys(this.state.currentGraph).length && !this.state.alertDetailLoading && this.state.currentGraph !== undefined && this.state.currentGraph !== null && ((this.state.acSelected ? this.state.currentSource !== null : this.state.currentGraph.run !== null) || this.state.currentGraph.daily !== null)) {
            return (
                <div style={{ marginRight: 25 + "px" }}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index }) }>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Timeline</Tab>
                        </TabList>
                            
                        <TabPanel>
                            {jobPanel}
                        </TabPanel>

                        <TabPanel>
                            {dailyPanel}
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
        
        else { 
            return (
                <div style={{ marginTop: 15 + "px", marginRight: 25 + "px" }}>
                    <Tabs selectedIndex={this.state.tabIndex} onSelect={index => this.setState({ tabIndex: index })}>
                        <TabList style={{ paddingLeft: 15 + "px" }}>
                            <Tab>Run</Tab>
                            <Tab>Timeline</Tab>
                            <Tab>Trend</Tab>
                        </TabList>

                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <div className="centered">
                                {noDataStr}
                            </div>
                            <br /><br />
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
    }

    renderContent() {
        return (
            <div>
                {this.renderAlerts()}
                <p className="status-msg">{this.state.message}</p>
                {this.state.alertType != "Device" ? this.renderGraph() : null}
            </div>
        );
    }

    renderTabTable() {
        return (
            <div style={{ height: '200px', margin: '0 20px 20px', border: '1px solid whitesmoke', borderRadius: '.15rem' }}>

                <div style={{ overflow: "auto" }}>
                    <table className='table table-hover' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Severity</th>
                                <th>Alert</th>
                                <th>Problem</th>
                                <th>Start</th>
                                <th>End</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.alertList.map((alert, index) =>
                                <tr key={alert.alertId} data={index} className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <td onClick={() => { this.onAlertClick(index) }}>{alert.priorityName}</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>{alert.type}</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>Show problem msg from database</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>{this.getStartDate(alert.startTime)}</td>
                                    <td onClick={() => { this.onAlertClick(index) }}>{this.getEndDate(alert.endTime)}</td>
                                    <td><ArrowDownIcon className="db-blue db-blue-bg" style={{ height: '20px', width: '20px', borderRadius: '10px' }} /></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            </div>
        );
    }

    renderNewContent() {
        return (
            <div>
                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '20px 30px', border: '1px solid whitesmoke', borderRadius: '.1rem', boxShadow: 'inset 0 -1px 0 rgba(100,121,143,0.122)' }}>
                    <div onClick={() => this.onTableTabClick(0)}>

                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>
                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <NotificationIcon className="alerts-tab-icon" />
                                            Equipment Health
                                            <div className="alerts-badge db-red-bg2">{this.props.partnerInfo.criticalCount} Critical</div>
                                            <div className="alerts-badge db-orange-bg2">{this.props.partnerInfo.warningCount} Warning</div>
                                        <div className="alerts-badge" style={{ backgroundColor: '#ff69b6a6' }}>{this.props.partnerInfo.scheduledCount} Scheduled</div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <div className={this.state.tableIndex == 0 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'}/>
                    </div>

                    <div onClick={() => this.onTableTabClick(1)}>
                        <Card elevation={0}>
                            <CardContent style={{ padding: 'unset' }}>

                                <div className="alerts-tab-item">
                                    <div className="alerts-tab-label">
                                        <SignalIcon className="alerts-tab-icon" />
                                            Device Health
                                            <div className="alerts-badge db-teal-bg2">{this.props.partnerInfo.downCount} Critical</div>
                                            <div className="alerts-badge db-purple-bg2">{this.props.partnerInfo.lowSignalCount} Warning</div>
                                    </div>
                                </div>

                            </CardContent>
                        </Card>
                        <div className={this.state.tableIndex == 1 ? "alerts-indicator db-blue-bg2" : 'alerts-indicator'} />
                    </div>
                </div>

                <div className="alerts-tab-wrapper" style={{ margin: '0 30px', border: '1px solid whitesmoke', padding: '10px 0'}}>
                    <div className="alerts-tab-label" style={{ paddingLeft: '45px' }}>Table Options</div>
                    <div onChange={e => this.changeTableRadio(e)} style={{ paddingLeft: '40px', margin: '5px' }}>
                        <div style={{ height: '24px', display: 'flex', alignItems: 'center' }}>
                            <input type="radio" value="Critical" defaultChecked name="alertTableType" />
                                    &nbsp; Critical &nbsp;&nbsp;
                                <input type="radio" value="Warning" name="alertTableType" />
                                    &nbsp; Warning &nbsp;&nbsp;
                                <input type="radio" value="Scheduled" name="alertTableType" />
                                    &nbsp; Scheduled &nbsp;&nbsp;
                                <input type="radio" value="All" name="alertTableType" />
                                    &nbsp; All &nbsp;&nbsp;
                                <IconTooltip title="Filter" enterDelay={600} leaveDelay={200}>
                                    <IconButton 
                                        aria-label="Filter Table"
                                        aria-controls="menu-filter"
                                        aria-haspopup="true"
                                        onClick={this.handleFilter}
                                        style={{ padding: '2px', borderRadius: '3px' }}
                                    >
                                        <FilterIcon className="db-blue db-blue-bg" style={{ borderRadius: '3px', height: '22px', width: '22px', padding: '2px' }} />
                                    </IconButton>
                                </IconTooltip>
                            </div>
                    </div>
                </div>

                <div>
                    <TriageAlertsTable alertList={this.props.alertList} alertMessaging={this.props.alertMessaging} currentAsset={this.state.currentAsset} currentCustomer={this.state.currentCustomer} onAlertClick={this.onAlertClick} alertSelected={this.state.alertSelected} />
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        
        let contents = this.props.loading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderContent();

        let runGraph = this.state.currentGraph !== undefined && this.state.currentGraph !== null ?
            this.state.acGraph && this.state.currentSource !== undefined && this.state.currentSource !== null && this.state.currentGraph.timeSeriesDataSources.length && this.state.currentGraph.run !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentAsset.assetId}-${this.state.currentSource?.timeString}-${this.state.runData?.length}-${this.state.visibility}-fullscreen`} isAirConditioner={true} dataMax={this.state.currentGraph.run.yDataMax}
                    showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.daily.dataArray[0]["RLA"]} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.daily.dataArray[0]["LRA"]} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentSource.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.currentAsset.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                : (this.state.currentGraph.run !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series[0] !== null && this.state.currentGraph.run.series[0] !== undefined ?
                    <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.runDataList)} key={`${this.state.currentSource?.timeString}-${this.state.runDataList?.length}-${this.state.visibility}-fullscreen`} isAirConditioner={false} dataMax={this.state.currentGraph.run.yDataMax}
                    showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.run.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString} pointStart={this.state.currentGraph.run.series[0].data[0]?.timestamp} />
                    : <div></div>)
            : <div></div>;

        let fullScreenRunModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null ?
            <FullScreenChartModal show={this.state.showRunModal} onClick={this.showFullRunModal} title={this.state.modalTitle} graph={runGraph} />
            : <div></div>;

        {/*let fullScreenRunModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null ?
            this.state.acGraph && this.state.currentSource !== undefined && this.state.currentSource !== null && this.state.currentGraph.timeSeriesDataSources.length ?
                (<FullScreenChartModal show={this.state.showRunModal} onClick={this.showFullRunModal} title={this.state.modalTitle} graph={<ACGraph ref={ref => ref && ref.clearPreviusState(this.state.clearPreviusState)} timeString={this.state.currentSource.timeString} graph={this.state.currentGraph} currentArray={this.state.currentSource.dataArray} assetSelected={this.state.alertSelected} isFullScreen={true} openModal={this.openFullRunModal}
                    graphData={this.state.currentSource} showAnnotations={this.state.showGraphAnnotations} graphAnnotations={this.state.currentSource.graphAnnotations} graphArrowAnnotations={this.state.currentSource.graphArrowAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics} handleModalCheckbox={this.handleCheckbox}
                    currentAsset={this.state.currentAsset} showAnalytics={this.state.visibility.analytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} showRLA={this.state.visibility.rla} showLRA={this.state.visibility.lra}>
                </ACGraph>}>
                </FullScreenChartModal>)
                : (this.state.currentGraph.run !== undefined && this.state.currentGraph.run !== null && this.state.currentGraph.run.series !== undefined && this.state.currentGraph.run.series !== null && this.state.currentGraph.run.series[0] !== undefined && this.state.currentGraph.run.series[0] !== null ?
                    <FullScreenChartModal show={this.state.showRunModal} onClick={this.showFullRunModal} title={this.state.modalTitle} graph={< CommonGraph data={this.state.currentGraph.run} showAnnotations={this.state.showRunAnnotations} showZoomButton={this.showZoomButton.bind(this)} ref={instance => { this.child = instance; }} />}>
                    </FullScreenChartModal>
                    : <div></div>)
            : <div></div>;*/}

        let dailyGraph = this.state.currentGraph !== undefined && this.state.currentGraph !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily !== null ?
            this.state.acGraph && this.state.currentGraph.daily.dataArray.length ?
                <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.timelineDataList)} key={`${this.state.currentGraph.daily?.timeString}-${this.state.visibility}-timeline-fullscreen`} isAirConditioner={true} dataMax={this.state.currentGraph.daily.yDataMax}
                    showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.daily.dataArray[0]["RLA"]} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.daily.dataArray[0]["LRA"]} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)}
                    showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentSource?.timeString}
                    pointStart={this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series.length && this.state.currentGraph.daily.series[0].data !== undefined && this.state.currentGraph.daily.series[0].data.length ?
                             this.state.currentGraph.daily.series[0]?.data[0]?.timestamp : (this.state.currentGraph.daily.dataArray !== undefined && this.state.currentGraph.daily.dataArray !== null && this.state.currentGraph.daily.dataArray.length ? this.state.currentGraph.daily.dataArray[0].Timestamp : null)} /> 
                : (this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series[0] !== undefined && this.state.currentGraph.daily.series[0].data !== undefined && this.state.currentGraph.daily.series[0].data[0] !== undefined ?
                    <HighchartGraph graphType={"ASSET"} chartData={this.getHighchartsGraphData(this.state.timelineDataList)} key={`${this.state.currentGraph.daily?.timeString}-${this.state.visibility}-fullscreen`} isAirConditioner={false} dataMax={this.state.currentGraph.daily.yDataMax}
                        showRLA={this.state.visibility.rla} rlaValue={this.state.currentGraph.rla} showLRA={this.state.visibility.lra} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.currentGraph.daily.graphAnnotations)}
                        showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={this.state.currentGraph.daily?.timeString} pointStart={this.state.currentGraph.daily.series[0].data[0]?.timestamp} />
                        :<div></div>)
            : <div></div>;

        let fullScreenDailyModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily !== null ?
            <FullScreenChartModal show={this.state.showDailyModal} onClick={this.showFullDailyModal} title={this.state.modalTitle} graph={dailyGraph} />
            : <div></div>;

        {/*let fullScreenDailyModal = this.state.currentGraph !== undefined && this.state.currentGraph !== null && this.state.currentGraph.daily !== undefined && this.state.currentGraph.daily !== null ?
            this.state.acGraph && this.state.currentGraph.daily.dataArray.length ?
                (<FullScreenChartModal show={this.state.showDailyModal} onClick={this.showFullDailyModal} title={this.state.modalTitle} graph={<ACTimelineGraph timeString={this.state.currentGraph.daily.timeString} currentArray2={this.state.currentGraph.daily.dataArray} alertSelected={this.state.alertSelected} currentGraph={this.state.currentGraph.daily} isFullScreen={true} openModal={this.openFullDailyModal}
                    currentGraphData={this.state.currentGraph.daily} showAnnotations={this.state.showRunAnnotations} graphAnnotations={this.state.currentGraph.daily.graphAnnotations} graphArrowAnnotations={this.state.currentGraph.daily.graphArrowAnnotations} hasAnalytics={this.state.currentAsset.hasAnalytics} handleModalCheckbox={this.handleCheckbox}
                    showAnalytics={this.state.visibility.analytics} showFurnace={this.state.visibility.furnace} showRLA={this.state.visibility.rla} showLRA={this.state.visibility.lra} showTemp={this.state.visibility.temp} />} >
                </FullScreenChartModal>)
                    : (this.state.currentGraph.daily.series !== undefined && this.state.currentGraph.daily.series !== null && this.state.currentGraph.daily.series[0] !== undefined && this.state.currentGraph.daily.series[0] !== null ?
                        <FullScreenChartModal show={this.state.showDailyModal} onClick={this.showFullDailyModal} title={this.state.modalTitle} graph={<CommonGraph data={this.state.currentGraph.daily} isPreviewMode={true} showAnnotations={this.state.showRunAnnotations} showZoomButton={this.showZoomButton.bind(this)} ref={instance => { this.child = instance; }} />} >
                        </FullScreenChartModal>
                        :<div></div>)
            : <div></div>;*/}

        let multiGraph = this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph !== null && this.state.multiseriesGraph.series !== undefined && this.state.multiseriesGraph.series !== null ?
            <HighchartGraph graphType={"MULTISERIES"} chartData={this.state.multiDataList} key={`Multiseries-${this.state.multiseriesGraph.series.length}-fullscreen`} graph={this.state.multiseriesGraph} isAirConditioner={true} dataMax={this.state.multiseriesGraph.yDataMax}
                showRLA={false} rlaValue={this.state.currentGraph.rla} showLRA={false} lraValue={this.state.currentGraph.lra} showFLA={false} flaValue={null} graphAnnotations={this.getHighchartsAnnotations(this.state.multiseriesGraph.graphAnnotations)}
                showAnalytics={this.state.visibility.analytics} hasAnalytics={this.state.hasAnalytics} showFurnace={this.state.visibility.furnace} showTemp={this.state.visibility.temp} timeString={null} pointStart={this.state.multiseriesGraph.series[0].data[0]?.timestamp} />
            : <div></div>;

        let fullScreenMultiModal = this.state.multiseriesGraph !== undefined && this.state.multiseriesGraph !== null && this.state.multiseriesGraph.series !== undefined && this.state.multiseriesGraph.series !== null ?
            <FullScreenChartModal show={this.state.showMultiModal} onClick={this.showFullMultiModal} title={this.state.modalTitle} graph={multiGraph} >
            </FullScreenChartModal>
            : <div></div>;

        return (
            <div>
                {contents}

                {fullScreenRunModal}
                {fullScreenDailyModal}
                {fullScreenMultiModal}

                <JobDetailModal show={this.state.showJobDetailConfirm} onClick={this.showJobDetailModal} cases={this.state.alertCases} />

                <AlertEditModal show={this.state.showEditConfirm} onClick={this.showEditAlertModal} title={this.state.modalTitle}
                    editAlert={this.state.editAlert} onMessageChange={this.onMessageChange} onRecommendationChange={this.onRecommendationChange}
                    onPriorityChange={this.onPriorityChange} onFalsePositiveChange={this.onFalsePositiveChange} onSave={this.onEditAlert}>
                </AlertEditModal>

                <CreateJobModal show={this.state.showCreateJobConfirm} onClick={this.showCreateJobModal} title={this.state.modalTitle}
                    editAlert={this.state.editAlert} createJob={this.state.currentOpportunity}
                    onSave={this.onCreateJob} onPriorityOpportunityChange={this.onPriorityOpportunityChange}
                    onCommentsChange={this.onCommentsChange} onSubjectChange={this.onSubjectChange}>
                </CreateJobModal>

                <ConfirmationModal show={this.state.showCloseConfirm} onYes={this.onCloseAlert} onClick={this.showCloseAlertModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>

                {/*<ConfirmationModal show={this.state.showDevicesConfirm} onYes={this.onViewDevices} onClick={this.showDevicesModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>*/}

                <DevicesModal show={this.state.showDevicesConfirm} onClick={this.showDevicesModal} message={this.state.modalMessage} alert={this.state.editAlert} customer={this.state.currentCustomer} isCurrentJob={!this.props.showButtons}
                    setCurrentPartnerAndCustomer={this.props.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.props.setCurrentPartnerId} setCurrentCustomerId={this.props.setCurrentCustomerId} setActivePath={this.props.setActivePath} >
                </DevicesModal>
            </div>
        );
    }
}

export default BaseAlertPage;