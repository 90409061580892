/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';

import HomeService from "../../services/home.service";
import AssetService from "../../services/asset.service";
import AlertService from '../../services/alerts.service';

import InstallCheckBreadcrumb from "./InstallCheckBreadcrumb";
import InstallCheckGraphModal from "./InstallCheckGraphModal";
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import DevTemp from './DevTemp';
import { Battery } from '@pxblue/react-progress-icons';
import '../../custom.css';

import memoize from "memoize-one";

import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import { jsAsset, jsDevice, jsHouse, jsAssetsGraphData, jsAvgCurrents, jsAddAlert, AlertTypeIdsEnum } from "../../componentObjects";

import moment from 'moment';

import FlashOnOutlinedIcon from '@mui/icons-material/FlashOnOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';

import installService from "../../services/install.service";
import RequestPayloadModal from  "../InstallReport/RequestPayloadModal";

const FAIL = -1;
const WARNING = 2;
const PASS = 1;
const NO_DATA = 0;

const Thermostat = "Thermostat";
const WaterLeakDetector = "WaterLeakDetector";
const AirConditioner = "AirConditioner";
const HeatPump = "HeatPump";
const PoolPump = "PoolPump";

const SubgRssi = "subgrssi";
const WifiRssi = "wifirssi";
const BatteryVoltage = "batteryvoltage";
const DeviceTemperature = "devicetemperature";

const WifiWeak = -90;
const WifiOkay = -85;
const WifiGreat = -75;
const WifiExcellent = -55;

const SubgRssiOkayLpx = -90;
const SubgRssiGreatLpx = -89;
const SubgRssiExcellentLpx = -78;

const SubgRssiOkayT10 = -100;
const SubgRssiGreatT10 = -60;
const SubgRssiExcellentT10 = -30;

const BatteryVoltageLow = 3.3;
const BatteryVoltageMedium = 3.5;
const BatteryVoltageHigh = 3.5;
const BatteryVoltageOshLoosebattery = 4.35;
const BatteryVoltageIsh = 4.35;

const LifePulseT10 = "LifePulseT10";

const Red = '#F14047';
const Yellow = '#F6EE59';
const Green = '#5AEB83';
const Grey = '#9e9e9e';

var contents = <div className="loading" style={{ display: "block" }}>
    <div className="loading-wrapper">
        <div className="modal-body"><Spinner animation="border" variant="light" /></div>
    </div></div>;

class InstallationCheck extends Component {
    static displayName = InstallationCheck.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onHomeSelect = this.onHomeSelect.bind(this);
        this.getDevices = this.getDevices.bind(this);

        this.runInstallCheck = this.runInstallCheck.bind(this);
        this.onCreateAlertClick = this.onCreateAlertClick.bind(this);
        this.getAssetAlerts = this.getAssetAlerts.bind(this);
        this.showRequestPayloadModal = this.showRequestPayloadModal.bind(this);
        this.openCreateAlertConfirmModal = this.openCreateAlertConfirmModal.bind(this);
        this.showCreateAlertConfirmModal = this.showCreateAlertConfirmModal.bind(this);
        this.onAwaitingDataClick = this.onAwaitingDataClick.bind(this);
        this.openAwaitingDataConfirmModal = this.openAwaitingDataConfirmModal.bind(this);
        this.showAwaitingDataConfirmModal = this.showAwaitingDataConfirmModal.bind(this);
        this.onTrainClick = this.onTrainClick.bind(this);
        this.openTrainConfirmModal = this.openTrainConfirmModal.bind(this);
        this.showTrainConfirmModal = this.showTrainConfirmModal.bind(this);

        this.onHVACSystemSelect = this.onHVACSystemSelect.bind(this);

        this.openGraphModal = this.openGraphModal.bind(this);
        this.clearAvgCurrentListObject = this.clearAvgCurrentListObject.bind(this);
        this.getWhileOnTestSymbol = this.getWhileOnTestSymbol.bind(this);
        this.getMultictAvgRunAmps = this.getMultictAvgRunAmps.bind(this);

        this.populateData = this.populateData.bind(this);
        this.populate = this.populate.bind(this);
        this.setPropertyData = this.setPropertyData.bind(this);

        this.state = {
            deviceList: [],
            homeList: [],
            hvacList: [],
            assetList: [],
            avgCurrentList: jsAvgCurrents,
            addAlertObj: jsAddAlert,

            currentHVACSystem: jsHouse,
            currentHome: jsHouse,
            currentAsset: jsAsset,
            currentDevice: jsDevice,
            currentInstall: {},
            currentInstallTechStr: "",

            loading: false,
            runningCheck: [],
            newAlerts: [],
            lastRunTime: null,
            newCustomerLoading: false,
            newHouseLoading: false,
            configLoading: false,
            isSelected: -1,
            runData: jsAssetsGraphData,
            currentRun: {},
            multictRunData: {},
            statusMessage: null,
            showGraphModal: false,
            showRequestPayloadModal: false,
            showCreateAlertConfirm: false,
            showAwaitingDataConfirm: false,
            showTrainConfirm: false,
            modalTitle: "",
            modalMessage: "",
            graphAssetIndex: 0,
            whileOnCheck: null,
            badDataDate: null,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,

            propertyResults: new Map()
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        /*if (this.state.loading === false) {
            this.setState({
                loading: true, avgCurrentList: {}, multictRunData: {}
            }, () => {
                this.populateData();
            });
        }*/
        if (!this.props.isLoading)
            this.populateData();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCustomerId !== this.props.currentCustomerId) {
            this.populateData();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    memHomeData = memoize(this.populateDataNew);

    populateData() {
        // get the default house
            this.setState({ loading: true, avgCurrentList: {}, multictRunData: {}, newAlerts: [] }, async () => {
                await this.populate(this.props.currentCustomerId);
            })
    }

    async populateDataNew(propId) {

        await this.populate(propId);
    }

    clearAvgCurrentListObject() {
        return {
            avgMainCurrent: [],
            avgInducerCurrent: [],
            avgBlowerCurrent: []
        }
    }

    async populate(id) {
        let startTime = performance.now();

        if (this.props.source !== "InstallReport") {
            await HomeService.get(id, false).then(response => {
                if (this._isMounted) {
                    this.setState({
                        homeList: response.data,
                        currentHome: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse,
                        avgCurrentList: this.clearAvgCurrentListObject(),
                        multictRunData: {},
                        newAlerts: []
                    }, async () => {
                        if (this.state.currentHome !== undefined && this.state.currentHome != null) {

                            await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                                if (this._isMounted)
                                    this.setState({
                                        hvacList: response.data,
                                        currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse
                                    }, async () => {
                                        // set a selected hvac system, then get the list of each asset for that system.. just like for home,

                                        if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                                            this.setState({propertyResults: new Map()}, async () => {
                                                await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                                    if (this._isMounted)
                                                        this.setPropertyData(response2.data);

                                                    this.setState({
                                                        assetList: response2.data,
                                                        currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset
                                                    }, async () => {
                                                        await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                                            .then(response => {
                                                                if (this._isMounted)
                                                                    this.updateDeviceProperties(response2.data, response.data);

                                                                this.setState({deviceList: response.data}, async () => {
                                                                    var macId = this.getMacId(this.state.assetList);

                                                                    await installService.getinstallbymacid(macId).then(response2 => {
                                                                        let install = response2;

                                                                        if (install !== null && install !== undefined) {
                                                                            let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";

                                                                            this.setState({
                                                                                currentInstall: install,
                                                                                currentInstallTechStr: techStr,
                                                                                loading: false
                                                                            }, () => {
                                                                                var elapsedTime = performance.now() - startTime;
                                                                                Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                            });
                                                                        }
                                                                    });
                                                                });
                                                            });
                                                    });
                                                });
                                            });
                                        } else {
                                            this.setState({loading: false}, () => {
                                                var elapsedTime = performance.now() - startTime;
                                                Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                            });
                                        }

                                    });
                            });
                        } else {
                            await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                .then(response => {
                                    if (this._isMounted)
                                        this.setState({deviceList: response.data}, async () => {
                                            var macId = this.getMacId(this.state.assetList);
                                            await installService.getinstallbymacid(macId).then(response2 => {
                                                let install = response2;
                                                if (install !== null && install !== undefined) {
                                                    let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                    this.setState({
                                                        currentInstall: install,
                                                        currentInstallTechStr: techStr,
                                                        loading: false
                                                    }, () => {
                                                        var elapsedTime = performance.now() - startTime;
                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                    });
                                                }
                                            });
                                        })
                                });
                        }
                    });
                }
            });
        } else {
            try {
                let accountId = this.props.currentCustomerId;
                
                if (this._isMounted && accountId !== "-1" && this.props.currentInstall) {
                    let hasCache = this.props.cachedResult?.macId?.toUpperCase() === this.props.currentInstall.macId?.toUpperCase();
                    
                    let cachedResult = hasCache ? this.props.cachedResult : {};
                    let installMac = this.props.currentInstall.installTypeId === 1 ? this.props.currentInstall.macId.toLowerCase() : (this.props.currentInstall.replacedMacId !== null && this.props.currentInstall.replacedMacId !== "" ? this.props.currentInstall.replacedMacId.toLowerCase() : this.props.currentInstall.macId.toLowerCase());

                    const assetHierarchyResult = hasCache ? cachedResult.assetHierarchyObj : await AssetService.assethierarchybyorgid(accountId);

                    if (assetHierarchyResult?.status === 200 && assetHierarchyResult?.data !== null) {
                        const hierarchyObj = assetHierarchyResult.data;

                        let homes = [];
                        let hvacSystems = [];

                        for (let homeAsset of hierarchyObj) {
                            homes.push({
                                orgId: homeAsset.id,
                                houseAssetId: homeAsset.id,
                                nickname: homeAsset.name,
                                name: homeAsset.name,
                                children: homeAsset.children
                            });
                        }

                        let currentHome = jsHouse;
                        let currentSystem = jsHouse;

                        if (homes.length === 1) {
                            currentHome = homes[0];
                        }
                        else {
                            currentHome = homes.find(h => h.children.some((s) => s.children.some(a => a.name.includes(installMac))));
                        }

                        if (currentHome !== jsHouse && currentHome !== undefined) {
                            for (let hvac of currentHome.children) {
                                hvacSystems.push({
                                    assetId: hvac.id,
                                    orgId: hvac.id,
                                    nickname: hvac.name,
                                    name: hvac.name,
                                    children: hvac.children
                                });
                            }
                            if (hvacSystems.length === 1)
                                currentSystem = hvacSystems[0];
                            else {
                                currentSystem = hvacSystems.find(s => s.children.some(a => a.name.includes(installMac)));
                            }

                            if (currentSystem !== jsHouse && currentSystem !== undefined) {
                                this.setState({
                                    homeList: homes,
                                    currentHome: currentHome,
                                    avgCurrentList: this.clearAvgCurrentListObject(),
                                    multictRunData: {},
                                    hvacList: hvacSystems,
                                    currentHVACSystem: currentSystem
                                }, () => {
                                    this.setState({ propertyResults: new Map() }, async () => {
                                        const assetResult = hasCache ? cachedResult.assetObj : await AssetService.get(this.state.currentHVACSystem.assetId);

                                        if (assetResult?.status === 200 && assetResult?.data !== null) {
                                            this.setPropertyData(assetResult.data);

                                            this.setState({
                                                assetList: assetResult.data,
                                                currentAsset: assetResult.data.length ? assetResult.data[0] : jsAsset
                                            }, async () => {
                                                const assetDevicesResult = hasCache ? cachedResult.assetDevicesObj : await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList);

                                                if (assetDevicesResult?.status === 200 && assetDevicesResult?.data != null) {
                                                    this.updateDeviceProperties(assetResult.data, assetDevicesResult.data);

                                                    this.setState({deviceList: assetDevicesResult.data}, async () => {
                                                        let macId = this.getMacId(this.state.assetList);

                                                        const installResult = hasCache ? cachedResult.installObj : await installService.getinstallbymacid(macId);

                                                        if (installResult !== null && installResult !== undefined) {
                                                            let techStr = installResult.technicianName !== null && installResult.technicianName !== undefined ? installResult.technicianName + " (" + installResult.technicianEmail + ") " + moment(installResult.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                                
                                                            cachedResult.macId = macId.split("-")[0].toUpperCase();
                                                            cachedResult.assetHierarchyObj = assetHierarchyResult;
                                                            cachedResult.assetObj = assetResult;
                                                            cachedResult.assetDevicesObj = assetDevicesResult;
                                                            cachedResult.installObj = installResult;
                                                            
                                                            this.props.cacheInstallCheck(cachedResult);
                                                            
                                                            this.setState({
                                                                currentInstall: installResult,
                                                                currentInstallTechStr: techStr,
                                                                loading: false
                                                            }, () => {
                                                                let elapsedTime = performance.now() - startTime;
                                                                Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                });
                            } else {
                                this.setState({ loading: false });
                            }
                        } else {
                            this.setState({ loading: false });
                        }
                    }
                } else {
                    this.setState({ loading: false });
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    getMacId(assets) {
        if (assets !== null && assets !== undefined && assets.length) {
            if (assets.length > 1) {
                if (assets.filter(a => a.type.trim() !== Thermostat).length || assets.filter(a => a.type.trim() !== WaterLeakDetector).length) {
                    return assets.filter(a => a.type.trim() !== Thermostat && a.type.trim() !== WaterLeakDetector)[0].mac;
                }
            }
            else {
                return assets[0].mac;
            }
        }
    }

    onHVACSystemSelect(e) {
        var startTime = performance.now();
        Event("Customer HVAC System Selected", "User selected one of the customers HVAC systems in dropdown", "Customer HVAC System Selected");
        this.setState({
            avgCurrentList: this.clearAvgCurrentListObject(),
            multictRunData: {},
            currentHVACSystem: this.state.hvacList[e.target.value], newHouseLoading: true, newAlerts: []
        }, async () => {

            // set a selected hvac system, then get the list of each asset for that system.. just like for home
            this.setState({propertyResults: new Map()}, async () => {
                await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                    if (this._isMounted)
                        this.setPropertyData(response2.data);

                        this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                            await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                .then(response => {
                                    if (this._isMounted)
                                        this.updateDeviceProperties(response2.data, response.data);

                                        this.setState({ deviceList: response.data }, async () => {
                                            var macId = this.getMacId(this.state.assetList);

                                            await installService.getinstallbymacid(macId).then(response2 => {
                                                let install = response2;

                                                if (install !== null && install !== undefined) {
                                                    let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";

                                                    this.setState({
                                                        currentInstall: install, currentInstallTechStr: techStr, newHouseLoading: false
                                                    }, () => {
                                                        var elapsedTime = performance.now() - startTime;
                                                        Timing("HVAC System Devices Loading", "newHouseLoading", elapsedTime, "Customer Devices Loading for Selected HVAC System");
                                                    });
                                                }
                                            });
                                        });
                                });
                        });
                });
            });
        });
    }

    async onHomeSelect(e) {
        let x = e.target.value;

        this.setState({ avgCurrentList: this.clearAvgCurrentListObject(), multictRunData: {},newHouseLoading: true, newAlerts: [] }, () => {
            this.getDevices(x);
        });
    }

    async getDevices(id) {
        var startTime = performance.now();
        this.setState({
            currentHome: this.state.homeList[id]
        }, async () => {
            await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                if (this._isMounted)
                    this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                        // set a selected hvac system, then get the list of each asset for that system.. just like for home,

                        if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                            this.setState({propertyResults: new Map()}, async () => {
                                await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                    if (this._isMounted)
                                        this.setPropertyData(response2.data);

                                        this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                            await AssetService.getassetdevices(this.state.currentHVACSystem.assetId, this.state.assetList)
                                                .then(response => {
                                                    if (this._isMounted)
                                                        this.updateDeviceProperties(response2.data, response.data);

                                                        this.setState({ deviceList: response.data }, async () => {
                                                            var macId = this.getMacId(this.state.assetList);

                                                            await installService.getinstallbymacid(macId).then(response2 => {
                                                                let install = response2;

                                                                if (install !== null && install !== undefined) {
                                                                    let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";

                                                                    this.setState({
                                                                        currentInstall: install, currentInstallTechStr: techStr, newHouseLoading: false
                                                                    }, () => {
                                                                        var elapsedTime = performance.now() - startTime;
                                                                        Timing("HVAC System Devices Loading", "newHouseLoading", elapsedTime, "Customer Devices Loading for Selected HVAC System");
                                                                    });
                                                                }
                                                            });
                                                        });
                                                });
                                        });
                                });
                            });
                        }
                        else {
                            this.setState({ newCustomerLoading: false });
                        }
                    });
            });
        });
    }

    async runInstallCheck() {
        Event("Begin Install Check Clicked", "User clicked begin install check button", "Begin Install Check Clicked");
        var runningCheckList = [];
        var newAlerts = [];
        for (let i = 0; i < this.state.assetList.length; i++) {
            runningCheckList[i] = true;
        }

        this.setState({ runningCheck: runningCheckList, avgCurrentList: this.clearAvgCurrentListObject(), multictRunData: {}, newAlerts: [] });

        if (this.state.currentDevice != null && this.state.currentDevice !== undefined && this.state.assetList)
        {
            var hoursBack = 6;
            var hoursSinceInstall = 6;
            var secondsSinceInstall = 6*3600;
            var secondsBack = 6*3600;
            var now = moment();
            var before = moment(now).add(-hoursBack, 'hours');
            var beforeStr = before.format();
            var nowStr = now.format();
            var dataRange;

            Event("Asset View Runs Click", "User clicked view runs button", "View Runs Clicked");
            for (const asset in this.state.assetList) {
                var index = asset;
                var assetType = "";
                var installDate;
                var installDateTime;
                for (const prop in this.state.assetList[asset].properties) {
                    if (this.state.assetList[asset].properties[prop].name == "Utility Type")
                        assetType = this.state.assetList[asset].properties[prop].value;
                    else if (this.state.assetList[asset].properties[prop].name == "lifepulseinstalldate") {
                        installDate = this.state.assetList[asset].properties[prop].timestamp;
                        if (installDate) {
                            installDate = installDate.substring(6, 19);
                            installDateTime = new Date(parseInt(installDate)).toISOString();
                            secondsSinceInstall = Math.round(Math.abs(new Date() - new Date(parseInt(installDate))) / 1000);
                            hoursSinceInstall = secondsSinceInstall / 3600;
                        }
                    }
                }

                if (hoursSinceInstall <= hoursBack) {
                    beforeStr = installDateTime;
                    secondsBack = secondsSinceInstall;
                }
                this.setState({ startDateTimeStrPlus: beforeStr, endDateTimeStrPlus: nowStr });


                    await AlertService.getassetgraphdata(this.state.assetList[index].name)
                        .then(response => {
                            if (this._isMounted)
                            {
                                this.setState({ currentRun: response.data.dataArray });
                                for (let currentKey in response.data.dataArray) {
                                    if (response.data.dataArray.hasOwnProperty(currentKey)) {
                                        var tempOutput = [];
                                        let currentArray = response.data.dataArray[currentKey];
                                        if (!this.state.deviceList[index].isMultiCT && (currentKey == "blowercurrent" || currentKey == "inducerCurrent")) {
                                            continue;
                                        }
                                        if (secondsBack >= currentArray.length) {
                                            dataRange = currentArray;
                                        }
                                        else {
                                            var runCount = currentArray.length - (secondsBack);
                                            dataRange = currentArray.slice(runCount);
                                        }

                                        var runDataItem = Object.keys(this.state.multictRunData).length > index ? this.state.multictRunData[index] : [];
                                        runDataItem[currentKey] = dataRange;

                                        this.setState(prevState => ({
                                            multictRunData: {
                                                ...prevState.multictRunData,
                                                [index]: runDataItem
                                            }
                                        }));
                                    }
                                }
                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                this.getAvgRunAmps(assetType, index);
                if (this.state.deviceList[index].isMultiCT) {
                    this.runWhileOnCheck(index);
                }
                this.setState(prevState => ({
                    runningCheck: {
                        ...prevState.runningCheck,
                        [index]: false
                    }
                }), () => {
                    var assetAlerts = this.getAssetAlerts(this.state.assetList[index], this.state.deviceList[index]);
                    if (assetAlerts !== null && assetAlerts !== undefined && assetAlerts.length) {
                        assetAlerts = assetAlerts.filter(a => !newAlerts.includes(n => n.alertTypeId === a.alertTypeId && n.assetId === a.assetId));
                        newAlerts = newAlerts.concat(assetAlerts);
                        this.setState({ newAlerts: newAlerts });
                    }
                })
            }
        }
        var timeNow = (new Date(moment().format())).toLocaleString();
        //this.setState({ lastRunTime: timeNow,  });
    }

    runWhileOnCheck(index) {
        var ctData = this.state.multictRunData[index];
        var isCorrectValueOrder = true;
        var isWithinTolerance = true;
        var badDataDate = "";
        for (let i = 0; i < ctData["current"].length; i++) {
            var mainCurrent = parseFloat(ctData["current"][i].Value);
            var blowerCurrent = parseFloat(ctData["blowercurrent"][i].Value);
            var inducerCurrent = parseFloat(ctData["inducercurrent"][i].Value);
            if (mainCurrent > 0.1 && blowerCurrent > 0.1 && inducerCurrent > 0.1) {
                var avgCurrentDiff = mainCurrent - (blowerCurrent + inducerCurrent);
                if (mainCurrent <= blowerCurrent || mainCurrent <= inducerCurrent || blowerCurrent <= inducerCurrent) {
                    isCorrectValueOrder = false;
                    badDataDate = new Date(moment(ctData["current"][i].Timestamp).format()).toLocaleString();
                    break;
                }
                if (Math.abs(mainCurrent - (inducerCurrent + blowerCurrent)) > (mainCurrent * 0.1)){
                    isWithinTolerance = false;
                    badDataDate = new Date(moment(ctData["current"][i].Timestamp).format()).toLocaleString();
                    break;
                }
            }
        }
        var tempWhileOnCheck = this.state.whileOnCheck != null ? this.state.whileOnCheck : [];
        var tempBadDataDate = this.state.badDataDate != null ? this.state.badDataDate : [];
        if (!isWithinTolerance || !isCorrectValueOrder) {
            tempWhileOnCheck[index] = FAIL;
            tempBadDataDate[index] = badDataDate;
            this.setState({ whileOnCheck: tempWhileOnCheck, badDataDate: tempBadDataDate });
        }
        else {
            tempWhileOnCheck[index] = PASS;
            this.setState({ whileOnCheck: tempWhileOnCheck });
        }
    }

    getAvgRunAmps(assetType, index) {
        var sameValueCheck = 0;
        var sameValueBool = true;
        var runStartIndex = 0;
        var onPointCount = 0;
        var onPointValueSum = 0.0;
        var offPointCount = 0;
        var offPointValueSum = 0.0;
        var offHigh = assetType.trim() == AirConditioner ? 0.2 : 0.3;
        var longestStretch = 0;
        var longestAvg = 0;
        var testResult = [];
        var tempOutput = [];
        var run = this.state.multictRunData[index]["current"];

        for (const point in run)
        {
            //Check if Every Point is the Same:
            if (point == 0) {
                sameValueCheck = run[point].Value;
            }
            else if (run[point].Value != sameValueCheck) {
                sameValueBool = false;
            }

            // Check on values and grab chunks of 30+ contiguous values above offHigh
            if (run[point].Value > offHigh) {
                onPointCount++;
                onPointValueSum += parseFloat(run[point].Value);
            }
            else if (run[point].Value <= offHigh && run[point].Value > 0) {
                if (onPointCount >= 30 && onPointCount > longestStretch)
                {
                    longestStretch = onPointCount;
                    longestAvg = onPointValueSum / onPointCount;
                    runStartIndex = point - (onPointCount);
                }

                onPointCount = 0;
                onPointValueSum = 0.0;
                offPointCount++;
                offPointValueSum += parseFloat(run[point].Value);
            }
        }

        if (onPointCount >= 30 && onPointCount > longestStretch)
        {
            longestStretch = onPointCount;
            longestAvg = onPointValueSum / onPointCount;
            runStartIndex = (run.length - 1) - (onPointCount);
        }

        if (sameValueBool) {
            testResult = [0, NO_DATA];
        }
        else if (longestStretch > 30) {
            testResult = [longestAvg, PASS];
        }
        else if (offPointCount > 0) {
            var avg = (offPointValueSum / offPointCount);
            testResult = [avg, FAIL];
        }
        else if (onPointCount > 0) {
            testResult = [0,FAIL];
        }
        else
            testResult = [0, NO_DATA];

        testResult[0] = testResult[0].toFixed(2);
        tempOutput[index] = testResult;
        var currentListItem = [];
        currentListItem = this.state.avgCurrentList["avgMainCurrent"];
        currentListItem[index] = tempOutput[index];

        this.setState(prevState => ({
            avgCurrentList: {
                ...prevState.avgCurrentList,
                ["avgMainCurrent"]: currentListItem
            }
        }));

        if (this.state.deviceList[index].isMultiCT) {
            var runEndIndex = runStartIndex + longestStretch;
            this.getMultictAvgRunAmps(index, runStartIndex, runEndIndex)
        }
    }

    getMultictAvgRunAmps(index, startIndex, endIndex) {
        var passValue = 0;
        var avgCurrent = 0;
        var testResultKey = "";
        var currentListItem = [];
        var tempOutput = [];

        if (this.state.deviceList[index].isMultiCT) {
            var blowerCurrent = this.state.multictRunData[index]["blowercurrent"].slice(startIndex, endIndex + 1);
            var inducerCurrent = this.state.multictRunData[index]["inducercurrent"].slice(startIndex, endIndex + 1);
        }

        for (let key in this.state.multictRunData[index]) {

            if (this.state.multictRunData[index].hasOwnProperty(key)) {

                var testResult = [];

                if (key == "current") {
                    continue;
                }
                if (key == "blowercurrent") {
                    passValue = 0.4;
                    testResultKey = "avgBlowerCurrent";
                }
                else if (key == "inducercurrent") {
                    passValue = 0.1;
                    testResultKey = "avgInducerCurrent";
                }
                try {
                    var current = this.state.multictRunData[index][key].slice(startIndex, endIndex + 1);
                    avgCurrent = current.map(a => parseFloat(a.Value)).reduce((b, c) => b + c, 0) / current.length;
                }
                catch (error) { }

                if (avgCurrent == 0) {
                    testResult = [0, FAIL];
                }
                else if (avgCurrent >= passValue) {
                    testResult = [avgCurrent, PASS];
                }
                else if (avgCurrent < passValue) {
                    testResult = [avgCurrent, FAIL];
                }
                else {
                    testResult = [0, NO_DATA];
                }

                testResult[0] = testResult[0].toFixed(2);
                tempOutput[index] = testResult;
                currentListItem = this.state.avgCurrentList[testResultKey];
                currentListItem[index] = tempOutput[index];

                this.setState(prevState => ({
                    avgCurrentList: {
                        ...prevState.avgCurrentList,
                        [testResultKey]: currentListItem
                    }
                }));
            }
        }
    }

    getAvgCurrentSymbol(index, ct) {
        if (this.state.runningCheck?.[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else if (Object.keys(this.state.runningCheck)?.length > 0 && this.state.avgCurrentList?.[ct]?.length > index) {
            return (
                <span href="#" id="TooltipCurrent">{this.state.avgCurrentList?.[ct]?.[index]?.[0]}
                    <CurrentSymbol avgCurrent={this.state.avgCurrentList?.[ct]?.[index]} />
                </span>
            );
        }
        else {
            return (
                <span href="#" id="TooltipCurrent">0.0
                    <CurrentSymbol avgCurrent={this.state.avgCurrentList?.[ct]?.[index]} />
                </span>
            );
        }
    }

    getTestResultSymbol(index) {
        if (this.state.runningCheck[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else {
            var testResult = null;
            if (this.state.deviceList[index].isMultiCT && this.state.avgCurrentList["avgMainCurrent"]?.length > 0) {
                var mainTest = this.state.avgCurrentList["avgMainCurrent"][index][1];
                var inducerTest = this.state.avgCurrentList["avgInducerCurrent"][index][1];
                var blowerTest = this.state.avgCurrentList["avgBlowerCurrent"][index][1];

                if (mainTest == PASS && inducerTest == PASS && blowerTest == PASS)
                    testResult = [0, PASS];
                else if (mainTest == NO_DATA && inducerTest == NO_DATA && blowerTest == NO_DATA)
                    testResult = [0, NO_DATA];
                else
                    testResult = [0, FAIL];
            }
            else {
                testResult = this.state.avgCurrentList["avgMainCurrent"][index];
            }
            return (
                <TestResultSymbol avgCurrent={testResult} propertyResults={this.state.propertyResults} deviceId={this.state.deviceList[index].deviceId} />
            );
        }
    }

    getWhileOnTestSymbol(index) {
        if (this.state.runningCheck[index]) {
            return (
                <Spinner animation="border" variant="dark" />
            );
        }
        else if (this.state.whileOnCheck == null || this.state.whileOnCheck.length <= index && this.state.whileOnCheck[index] == null) {
            return (null);
        }
        else if (this.state.whileOnCheck[index] == PASS) {
            return (
                <CheckCircleOutlineOutlinedIcon style={{ color: Green, fontSize: 40 }} />
            );
        }
        else if (this.state.whileOnCheck[index] == FAIL) {
            return (
                <span href="#" id="TooltipCurrent">
                    <HighlightOffOutlinedIcon style={{ color: Red, fontSize: 40 }} />
                    {this.state.badDataDate[index]}
                    </span>
            );
        }
        else if (this.state.whileOnCheck[index] == NO_DATA) {
            return (
                <RadioButtonUncheckedOutlinedIcon style={{ color: Grey, fontSize: 40 }} />
            );
        }
    }

    checkAllTestsPassed(deviceList, avgCurrentList) {
        let allTestResult = NO_DATA;
        let passCount = 0;
        let failCount = 0;
        if (deviceList !== undefined && deviceList !== null && avgCurrentList !== undefined && avgCurrentList !== null)
            for (let index = 0; index < deviceList.length; index++) {
                let testResult = null;
                if (deviceList[index].isMultiCT && avgCurrentList["avgMainCurrent"]?.length > 0) {
                    let mainTest = avgCurrentList["avgMainCurrent"][index] !== undefined ? avgCurrentList["avgMainCurrent"][index][1] : 0;
                    let inducerTest = avgCurrentList["avgInducerCurrent"][index] !== undefined ? avgCurrentList["avgInducerCurrent"][index][1] : 0;
                    let blowerTest = avgCurrentList["avgBlowerCurrent"][index] !== undefined ? avgCurrentList["avgBlowerCurrent"][index][1] : 0;

                    if (mainTest == PASS && inducerTest == PASS && blowerTest == PASS)
                        testResult = PASS;
                    else if (mainTest == NO_DATA && inducerTest == NO_DATA && blowerTest == NO_DATA)
                        testResult = NO_DATA;
                    else
                        testResult = FAIL;
                }
                if (avgCurrentList["avgMainCurrent"]?.length == 0) {
                    testResult = NO_DATA;
                }
                else {
                    testResult = avgCurrentList["avgMainCurrent"][index] !== undefined ?
                        avgCurrentList["avgMainCurrent"][index][1] : NO_DATA;
                }
                if (testResult === PASS)
                    passCount++;
                else if (testResult === FAIL)
                    failCount++;

                if (allTestResult === NO_DATA)
                    allTestResult = testResult;
                else if (allTestResult === PASS && testResult === FAIL)
                    allTestResult = testResult;
            }

        if (allTestResult == PASS && passCount != deviceList.length) {
            if (failCount > 0)
                allTestResult = FAIL;
            else
                allTestResult = NO_DATA;
        }

        return allTestResult;
    }

    async onCreateAlertClick() {
        Event("Create Provisioning Alert clicked", "User clicked create provisioning alert in Install Checks page", "Create Provisioning Alert clicked");
        this.setState({ loading: true, showCreateAlertConfirm: false }, async () => {

            let newAlerts = this.state.newAlerts;
            for (var i = 0; i < newAlerts.length; i++) {
                let newAlert = newAlerts[i];

                await AlertService.addalert(newAlert)
                    .then(response => {
                        if (response.status === 200)
                            this.setState({ message: `Successfully added Provisioning alert to assetId: ${newAlert.sourceId}` });
                        else
                            this.setState({ message: `Failed to add provisioning alert to assetId: ${newAlert.sourceId}.` });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ message: `Failed to add provisioning alert to assetId: ${newAlert.sourceId}.` });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 3000);
                    });
            }

            this.setState({ loading: false, newAlerts: [] });
        })
    }

    showRequestPayloadModal() {
        this.setState({
            showRequestPayloadModal: !this.state.showRequestPayloadModal
        }, () => {
            if (this.state.showRequestPayloadModal) {
                ModalView('RequestPayload');
            }
        })
    }
    
    openCreateAlertConfirmModal() {
        this.setState({
            modalTitle: "Create Provisioning Alert",
            modalMessage: `You are about to create a provisioning alert for assets that failed their install check. Would you like to continue?`
        }, () => {
            this.showCreateAlertConfirmModal();
        });
    }

    showCreateAlertConfirmModal() {
        this.setState({
            showCreateAlertConfirm: !this.state.showCreateAlertConfirm
        }, () => {
            if (this.state.showCreateAlertConfirm)
                ModalView('createAlertConfirm');
        })
    }

    async onAwaitingDataClick() {
        Event("Set Awaiting Data clicked", "User clicked set awaiting data in Install Checks page", "Set Awaiting Data clicked");
        let commentStr = "Set to Awaiting Data due to Repair Install Check passing";
        this.setState({ loading: true, showRetrainConfirm: false }, async () => {
            for (var i = 0; i < this.state.assetList.length; i++) {
                let currAssetId = this.state.assetList[i].assetId;
                await AssetService.setassetawaitingdata(currAssetId, commentStr)
                .then(response => {
                    if (response.status === 200)
                        this.setState({ message: "Successfully set the asset to Retrain!" });
                    else
                        this.setState({ message: "Failed to set asset to Retrain." });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ message: "Failed to set asset to Retrain." });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                });
            }

            this.setState({ loading: false });
        });
    }

    openAwaitingDataConfirmModal() {
        this.setState({
            modalTitle: "Set To Awaiting Data",
            modalMessage: `You are about to set assets to Awaiting Data and remove other state classes. Would you like to continue?`
        }, () => {
            this.showAwaitingDataConfirmModal();
        });
    }

    showAwaitingDataConfirmModal() {
        this.setState({
            showAwaitingDataConfirm: !this.state.showAwaitingDataConfirm
        }, () => {
            if (this.state.showAwaitingDataConfirm)
                ModalView('setAwaitingDataConfirm');
        })
    }

    async onTrainClick() {
        Event("Force Train asset clicked", "User clicked force train in Install Checks page", "Force Train clicked");
        let commentStr = "Set to Retrain due to Install Check passing";

        this.setState({ loading: true, showRetrainConfirm: false }, async () => {
            for (var i = 0; i < this.state.assetList.length; i++) {
                let currAssetId = this.state.assetList[i].assetId;
                await AssetService.retrainasset(currAssetId, commentStr)
                .then(response => {
                    if (response.status === 200)
                        this.setState({ message: "Successfully set the asset to Retrain!" });
                    else
                        this.setState({ message: "Failed to set asset to Retrain." });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ message: "Failed to set asset to Retrain." });

                    setTimeout(() => {
                        this.setState({
                            message: ""
                        });
                    }, 3000);
                });
            }

            this.setState({ loading: false });
        });
    }

    openTrainConfirmModal() {
        this.setState({
            modalTitle: "Force Train Assets",
            modalMessage: `You are about to force train assets by setting them to Retrain. Would you like to continue?`
        }, () => {
            this.showTrainConfirmModal();
        });
    }

    showTrainConfirmModal() {
        this.setState({
            showTrainConfirm: !this.state.showTrainConfirm
        }, () => {
            if (this.state.showTrainConfirm)
                ModalView('forceTrainAssetConfirm');
        })
    }

    openGraphModal(index = 0) {
        this.setState({ showGraphModal: !this.state.showGraphModal, graphAssetIndex: index }, () => {
            if (this.state.showGraphModal)
                ModalView('installCheckGraphModal');
        });
    }

    setPropertyData(assetList) {
        if (assetList && assetList.length > 0) {
            try {
                assetList.forEach(asset => {
                    if (asset.properties && asset.properties.length > 0) {
                        asset.properties.forEach(property => {
                            try {
                                switch (property.name.toLowerCase()) {
                                    case SubgRssi:
                                        asset.subgRSSI = property.value && this.isPropertyWithinLast2Hours(property.timestamp) ? property.value.replace(",", "") : null;
                                        break;
                                    case WifiRssi:
                                        asset.wifiRSSI = property.value && this.isPropertyWithinLast2Hours(property.timestamp) ? property.value.replace(",", "") : null;
                                        break;
                                    case BatteryVoltage:
                                        asset.batteryVoltage = property.value && this.isPropertyWithinLast2Hours(property.timestamp) ? property.value : null;
                                        break;
                                    case DeviceTemperature:
                                        if (property.value && this.isPropertyWithinLast2Hours(property.timestamp)) {
                                            let temp = property.value.split(" ");
                                            asset.deviceTemperature = temp.length > 0 ? temp[0] : temp;
                                        }
                                        else {
                                            asset.deviceTemperature = null;
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            } catch (ex) {
                                // Do nothing.
                            }
                        });
                    }
                });
            } catch (ex) {
                // Do nothing.
            }
        }
    }

    checkPropertyData(asset, isT10Asset) {
        let batteryVoltageResult = this.batteryVoltageCheck(asset.batteryVoltage, asset.type);
        let deviceTemperatureResult = this.deviceTemperatureCheck(asset.deviceTemperature);
        let wifiResult = this.wifiCheck(asset.wifiRSSI);
        let subgRssiResult = this.subgRssiCheck(asset.subgRSSI, isT10Asset);

        let deviceId = asset.mac.toLowerCase();

        const propertyResults = new Map(this.state.propertyResults);

        if (!propertyResults.has(deviceId)) {
            propertyResults.set(deviceId, []);
        }

        propertyResults.get(deviceId).push(batteryVoltageResult);
        propertyResults.get(deviceId).push(deviceTemperatureResult);
        propertyResults.get(deviceId).push(wifiResult);
        propertyResults.get(deviceId).push(subgRssiResult);

        this.setState({ propertyResults: propertyResults });
    }

    getAssetAlerts(asset, device) {
            let newAlerts = [];
            let assetHasAlert = false;
            const assetId = asset.assetId.toString();

            let type = asset.type;
            if ((type === AirConditioner || type === HeatPump || type === PoolPump) && asset.batteryVoltage !== null) {
                var bv = asset.batteryVoltage.split(" ");
                var batteryVoltage = parseFloat(bv[0]);
                if (batteryVoltage >= BatteryVoltageOshLoosebattery) {
                    var newAlertObj = jsAddAlert;
                    newAlertObj.alertTypeId = AlertTypeIdsEnum.LooseBattery;
                    newAlertObj.sourceId = assetId;
                    newAlertObj.priority = 3;
                    newAlertObj.message = "Loose Battery in OSH";
                    const newAlert = JSON.parse(JSON.stringify(newAlertObj));
                    if (!newAlerts.includes(a => a.alertTypeId == newAlert.alertTypeId && a.sourceId == newAlert.sourceId)) {
                        newAlerts = newAlerts.concat(newAlert);
                        assetHasAlert = true;
                    }
                }
            }
            if (asset.subgRSSI !== null)
                asset.subgRSSI = parseInt(asset.subgRSSI);

            if ((!device.isT10Asset && asset.subgRSSI <= SubgRssiOkayLpx) || (device.isT10Asset && asset.subgRSSI <= SubgRssiOkayT10)) {
                var newSubgAlertObj = jsAddAlert;
                newSubgAlertObj.alertTypeId = AlertTypeIdsEnum.LowSubgRSSI;
                newSubgAlertObj.sourceId = assetId;
                newSubgAlertObj.priority = 2;
                newSubgAlertObj.message = "Low Sub-G RSSI";
                const newAlert = JSON.parse(JSON.stringify(newSubgAlertObj));
                if (!newAlerts.includes(a => a.alertTypeId == newAlert.alertTypeId && a.sourceId == newAlert.sourceId)) {
                    newAlerts = newAlerts.concat(newAlert);
                    assetHasAlert = true;
                }
            }

            if (asset.wifiRSSI !== null)
                asset.wifiRSSI = parseInt(asset.wifiRSSI);
            if (asset.wifiRSSI !== null && asset.wifiRSSI <= WifiWeak) {
                var newWifiAlertObj = jsAddAlert;
                newWifiAlertObj.alertTypeId = AlertTypeIdsEnum.LowWifiSignal;
                newWifiAlertObj.sourceId = assetId;
                newWifiAlertObj.priority = 2;
                newWifiAlertObj.message = "Low WiFi RSSI";
                const newAlert = JSON.parse(JSON.stringify(newWifiAlertObj));
                if (!newAlerts.includes(a => a.alertTypeId == newAlert.alertTypeId && a.sourceId == newAlert.sourceId)) {
                    newAlerts = newAlerts.concat(newAlert);
                    assetHasAlert = true;
                }
            }

            if (!assetHasAlert && (this.checkAssetPassedTest(device, this.state.deviceList, this.state.avgCurrentList) != PASS)) {
                var args = jsAddAlert;
                args.alertTypeId = AlertTypeIdsEnum.Provisioning;
                args.sourceId = assetId;
                args.priority = 1;
                args.message = "Provisioning alert created from Install Checks";
                const newAlert = JSON.parse(JSON.stringify(args));
                if (!newAlerts.includes(a => a.alertTypeId == newAlert.alertTypeId && a.sourceId == newAlert.sourceId))
                    newAlerts = newAlerts.concat(newAlert);
            }

            return newAlerts;
    }

    checkAssetPassedTest(device, deviceList, avgCurrentList) {
        var testResult = null;
        var index = deviceList.indexOf(device);
        if (device.isMultiCT && avgCurrentList["avgMainCurrent"]?.length > 0) {
            var mainTest = avgCurrentList["avgMainCurrent"][index][1];
            var inducerTest = avgCurrentList["avgInducerCurrent"][index] !== undefined ? avgCurrentList["avgInducerCurrent"][index][1] : 0;
            var blowerTest = avgCurrentList["avgBlowerCurrent"][index] !== undefined ? avgCurrentList["avgBlowerCurrent"][index][1] : 0;

            if (mainTest == PASS && inducerTest == PASS && blowerTest == PASS)
                testResult = PASS;
            else if (mainTest == NO_DATA && inducerTest == NO_DATA && blowerTest == NO_DATA)
                testResult = NO_DATA;
            else
                testResult = FAIL;
        }
        if (avgCurrentList["avgMainCurrent"]?.length == 0) {
            testResult = NO_DATA;
        }
        else {
            testResult = avgCurrentList["avgMainCurrent"][index] !== undefined ?
                avgCurrentList["avgMainCurrent"][index][1] : NO_DATA;
        }

        return testResult;
    }

    batteryVoltageCheck(batteryVoltageProp, type) {
        let result = PASS;
        type = type.trim();

        if (batteryVoltageProp !== null && batteryVoltageProp !== undefined) {
            let bv = batteryVoltageProp.split(" ");
            let batteryVoltage = parseFloat(bv[0]);

            // Outdoor unit
            if (type === AirConditioner || type === HeatPump || type === PoolPump) {
                if (batteryVoltage < BatteryVoltageLow) {
                    result = FAIL;
                }
                else if (batteryVoltage >= BatteryVoltageLow && batteryVoltage <= BatteryVoltageMedium) {
                    result = WARNING;
                }
                else if (batteryVoltage >= BatteryVoltageOshLoosebattery) {
                    result = FAIL;
                }
            }
            else if (batteryVoltage < BatteryVoltageIsh) {
                result = FAIL;
            }
        }
        else {
            result = WARNING;
        }

        return result;
    }

    deviceTemperatureCheck(deviceTemperature) {
        let result = PASS;

        if (deviceTemperature === null || deviceTemperature === undefined) {
            result = WARNING;
        }

        return result;
    }

    wifiCheck(wifiRSSI) {
        let result = PASS;

        if (wifiRSSI == 0) {
            result = FAIL;
        }
        else if (wifiRSSI >= WifiExcellent) {
            result = PASS;
        }
        else if (wifiRSSI >= WifiGreat) {
            result = PASS;
        }
        else if (wifiRSSI >= WifiOkay) {
            result = WARNING;
        }
        else if (wifiRSSI >= WifiWeak) {
            result = FAIL;
        }
        else {
            result = FAIL;
        }

        return result;
    }

    subgRssiCheck(subgRSSI, isT10) {
        let result = PASS;

        if (isT10) {
            if (subgRSSI == 0) {
                result = FAIL;
            }
            else if (subgRSSI > SubgRssiExcellentT10) {
                result = PASS;
            }
            else if (subgRSSI >= SubgRssiGreatT10) {
                result = PASS;
            }
            else if (subgRSSI >= SubgRssiOkayT10) {
                result = WARNING;
            }
            else {
                result = FAIL;
            }
        }
        else {
            if (subgRSSI == 0) {
                result = FAIL;
            }
            else if (subgRSSI > SubgRssiExcellentLpx) {
                result = PASS;
            }
            else if (subgRSSI >= SubgRssiGreatLpx) {
                result = PASS;
            }
            else if (subgRSSI >= SubgRssiOkayLpx) {
                result = WARNING;
            }
            else {
                result = FAIL;
            }
        }

        return result;
    }

    isPropertyWithinLast2Hours(timestampString) {
        const timestamp = parseInt(timestampString.match(/\d+/)[0], 10);

        const timestampDate = new Date(timestamp);
        const currentTime = new Date();

        const hoursDifference = (currentTime - timestampDate) / (1000 * 60 * 60);

        return hoursDifference <= 2;
    }

    updateDeviceProperties(assetList, deviceList) {
        if (deviceList && deviceList.length > 0 && assetList && assetList.length > 0) {
            deviceList.forEach(async device => {
                let asset = assetList.find(a => a.mac.toLowerCase() === device.deviceId.toLowerCase());

                if (asset) {
                    device.subgRSSI = asset.subgRSSI;
                    device.wifiRSSI = asset.wifiRSSI;
                    device.batteryVoltage = asset.batteryVoltage;
                    device.deviceTemperature = asset.deviceTemperature;

                    await AssetService.getappliedtags(asset.assetId).then(response => {
                        if (response.status === 200 && response.data && response.data.some(t => t.name === LifePulseT10)) {
                            device.isT10Asset = true;
                        }
                        else {
                            device.isT10Asset = false;
                        }
                    });
                }

                this.checkPropertyData(asset, device.isT10Asset);
            });
        }
    }

    getIsAdmin(roles) {
        let isAdmin = false;

        if (roles !== null && roles !== undefined && roles.includes("ADMIN")) {
            if (roles.includes("PARTNER ADMIN")) {
                roles.replace("PARTNER ADMIN", "");
                if (roles.includes("ADMIN"))
                    isAdmin = true;
            }
            else
                isAdmin = true;
        }

        return isAdmin;
    }

    renderDevices(homeList, deviceList, currentId, loading) {
        const { classes } = this.props;

        var isAdmin = this.props.roles.includes("ADMIN") && !this.props.roles.includes("PARTNER")
        var whileOnCheckHeading = isAdmin ? "WHILE ON CHECK" : null;

        let testHeadings = this.state.deviceList.some(a => a.isMultiCT == true) ?
             <tr className="device-table-row">
                <th style={{ padding: '0.1px 0.1px 0.1px 10px' }}>NICKNAME</th>
                <th>MAC</th>
                <th>STATUS</th>
                <th>TEMP</th>
                <th>BATTERY</th>
                <th>WIFI</th>
                <th>SENSOR HUB</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>BLOWER CURRENT</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>INDUCER CURRENT</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>MAIN CURRENT</th>
                <th>{whileOnCheckHeading}</th>
                <th>TEST RESULT</th>
                <th>VIEW GRAPHS</th>
                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
            </tr>
            : <tr className="device-table-row">
                <th style={{ padding: '0.1px 0.1px 0.1px 10px' }}>NICKNAME</th>
                <th>MAC</th>
                <th>STATUS</th>
                <th>TEMP</th>
                <th>BATTERY</th>
                <th>WIFI</th>
                <th>SENSOR HUB</th>
                <th style={{ padding: '10px 50px 10px 0px' }}>AVERAGE CURRENT</th>
                <th>TEST RESULT</th>
                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
            </tr>


        let configLoader = this.state.configLoading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let tableContent = loading ?
            <tbody>
                <tr><td>loading devices...</td></tr>
            </tbody>
            : deviceList.length ?
                this.state.deviceList.some(a => a.isMultiCT == true) ?
                    <tbody>
                        {deviceList.map((device, index) =>
                            [<tr className={"device-table-row"} key={`${device.deviceId}-tableRow`} data={index} value={index}>
                                <td style={{ padding: '0.1px 0.1px 0.1px 10px' }}>{device.nickname}</td>
                                <td>{device.mac}</td>
                                <td>{device.status}</td>
                                <td  className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipWifi"><WiFiSymbol wifiStrength={device.wifiRSSI} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                        <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} isT10={device.isT10Asset} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgBlowerCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgInducerCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgMainCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {isAdmin ? this.getWhileOnTestSymbol(index) : null}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTestResult">
                                        {this.getTestResultSymbol(index)}
                                    </span>
                                </td>
                                <td>
                                    <button
                                        disabled={Object.keys(this.state.multictRunData).length < index + 1}
                                        type="button"
                                        className={Object.keys(this.state.multictRunData).length < index + 1 ? "secondary-btn" : "primary-btn"}
                                        style={{ borderRadius: ".75rem" }}
                                        onClick={() => { this.openGraphModal(index) }}
                                    >
                                        View
                                        </button>
                                </td>
                            </tr>
                        ])}
                    </tbody>
                    :
                    <tbody>
                        {deviceList.map((device, index) =>
                            [<tr className={"device-table-row"} key={`${device.deviceId}-tableRow`} data={index} value={index}>

                                <td style={{ padding: '0.1px 0.1px 0.1px 10px' }}>{device.nickname}</td>
                                <td>{device.mac}</td>
                                <td>{device.status}</td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipWifi"><WiFiSymbol wifiStrength={device.wifiRSSI} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} isT10={device.isT10Asset} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgMainCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTestResult">
                                        {this.getTestResultSymbol(index)}
                                    </span>
                                </td>
                            </tr>
                            ])}
                    </tbody>
                :
                <tbody>
                    <tr><td>No Devices</td></tr>
                </tbody>;

        let hvacSystemSelect = <SelectHVACSystem style={{ width: 300 + 'px' }} hvacList={this.state.hvacList} onChange={this.onHVACSystemSelect} techStr={this.state.currentInstallTechStr} />

        if (homeList !== null && homeList !== undefined && homeList.length) {
            let leadingButtonText = 'Once Installation is Complete, Press Button to   ';
            let runningCheckLabel = 'Running Installation Check   ';
            let lastCheckTimeLabel = 'Last Install Check Run on  ';

            let runningCheckSpinner = this.state.runningCheck[this.state.assetList.length - 1] ? <div className="modal-body">{runningCheckLabel}<Spinner animation="border" variant="dark" /></div> :
                <div />

            let lastCheckRunTime = this.state.lastRunTime ? <div>{lastCheckTimeLabel}{this.state.lastRunTime}</div> : <div />;

            let topContent = this.props.source === "InstallReport" ?
                null
                : <div className="white-container">
                <div className="install-check-container">
                    <InstallCheckBreadcrumb homes={this.state.homeList} hvacList={this.state.hvacList} onChange={this.onHomeSelect} onHvacChange={this.onHVACSystemSelect} techStr={this.state.currentInstallTechStr} />
                </div>
                {(!this.state.isCustomerSupport) && (
                    <div className="install-check-container center">
                        {leadingButtonText}
                        <button
                            disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                            type="button"
                            className="primary-btn btn-center"
                            onClick={this.runInstallCheck}
                        >
                            Begin Installation Check
                        </button>
                        {runningCheckSpinner}

                        {/*lastCheckRunTime*/}
                    </div>)}

            </div>;

            return (
                <div>
                    <div style={{ display: 'none' }}>{currentId}</div>
                    <div className="white-container">
                        <div className="install-check-container">
                            <InstallCheckBreadcrumb homes={this.state.homeList} hvacList={this.state.hvacList} onChange={this.onHomeSelect} onHvacChange={this.onHVACSystemSelect} techStr={this.state.currentInstallTechStr} />
                        </div>
                        {(!this.state.isCustomerSupport) && (
                            <div className="install-check-container center">
                                {leadingButtonText}
                                <button
                                    disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                                    type="button"
                                    className="primary-btn btn-center"
                                    onClick={this.runInstallCheck}
                                >
                                    Begin Installation Check
                                </button>
                                {runningCheckSpinner}

                                {/*lastCheckRunTime*/}
                            </div>)}

                    </div>
                    <br />

                    <div>
                        <table className="table-hover" style={{ width: '100%' }}>
                            <thead>
                                {testHeadings}
                            </thead>
                            {tableContent}
                        </table>
                        {/*
                         <SimplePopper />
                        */}
                    </div>



                    <div className="white-container">
                        {/*
                        <h5 className="sectionTitle" >Reports</h5>
                        */}
                        <form style={{ marginLeft: 15 + 'px' }}>
                          <p className="status-msg">{this.state.statusMessage}</p>
                        </form>
                    </div>
                    <br />
                </div>
            );
        }
        else if (this.props.currentCustomerId == -1) {
            return (
                <div>
                    <p className="status-msg">No customer device information to display</p>
                </div>
            );
        }
        else {
            return (<div style={{ marginLeft: '10px' }}><br />No Devices located!</div>);
        }
    }


    renderFullPage() {
        const { classes } = this.props;

        if (this.state.loading) {
            contents = <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else if (this.state.newCustomerLoading) {
            contents = <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else {
            contents = this.renderDevices(this.state.homeList, this.state.deviceList, this.props.currentCustomerId, this.state.newHouseLoading);
        }

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Installation Check
                    </h2>
                    {contents}
                    <InstallCheckGraphModal dataObject={this.state.multictRunData[this.state.graphAssetIndex]} show={this.state.showGraphModal} onClick={() => { this.openGraphModal() }} />
                </div>
            </div>
        );
    }

    renderExpandDevices(homeList, deviceList, currentId, loading) {
        const { classes } = this.props;

        var isAdmin = this.props.roles.includes("ADMIN") && !this.props.roles.includes("PARTNER")
        var whileOnCheckHeading = isAdmin ? "WHILE ON CHECK" : null;

        let testHeadings = this.state.deviceList.some(a => a.isMultiCT == true) ?
             <tr className="no-hover device-table-row">
                <th style={{ padding: '0.1px 0.1px 0.1px 10px' }}>NICKNAME</th>
                <th>MAC</th>
                <th>STATUS</th>
                <th>TEMP</th>
                <th>BATTERY</th>
                <th>WIFI</th>
                <th>SENSOR HUB</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>BLOWER CURRENT</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>INDUCER CURRENT</th>
                <th style={{ padding: '10px 10px 10px 0px' }}>MAIN CURRENT</th>
                <th>{whileOnCheckHeading}</th>
                <th>TEST RESULT</th>
                <th>VIEW GRAPHS</th>
                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
            </tr>
            : <tr className="no-hover device-table-row">
                <th style={{ padding: '0.1px 0.1px 0.1px 10px' }}>NICKNAME</th>
                <th>MAC</th>
                <th>STATUS</th>
                <th>TEMP</th>
                <th>BATTERY</th>
                <th>WIFI</th>
                <th>SENSOR HUB</th>
                <th style={{ padding: '10px 50px 10px 0px' }}>AVERAGE CURRENT</th>
                <th>TEST RESULT</th>
                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}></th>
            </tr>


        let configLoader = this.state.configLoading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>;

        let tableContent = loading ?
            <tbody>
                <tr><td>loading devices...</td></tr>
            </tbody>
            : deviceList.length ?
                this.state.deviceList.some(a => a.isMultiCT == true) ?
                    <tbody>
                        {deviceList.map((device, index) =>
                            [<tr className={"no-hover device-table-row"} key={`${device.deviceId}-tableRow`} data={index} value={index}>
                                <td style={{ padding: '0.1px 0.1px 0.1px 10px', borderLeft: '1px solid #ebeef0' }}>{device.nickname}</td>
                                <td>{device.mac}</td>
                                <td>{device.status}</td>
                                <td  className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipWifi"><WiFiSymbol wifiStrength={device.wifiRSSI} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                        <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} isT10={device.isT10Asset} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgBlowerCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgInducerCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgMainCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {isAdmin ? this.getWhileOnTestSymbol(index) : null}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTestResult">
                                        {this.getTestResultSymbol(index)}
                                    </span>
                                </td>
                                <td style={{ borderRight: '1px solid #ebeef0' }}>
                                    <button
                                        disabled={Object.keys(this.state.multictRunData).length < index + 1}
                                        type="button"
                                        className={Object.keys(this.state.multictRunData).length < index + 1 ? "secondary-btn" : "primary-btn"}
                                        style={{ borderRadius: ".75rem" }}
                                        onClick={() => { this.openGraphModal(index) }}
                                    >
                                        View
                                        </button>
                                </td>
                            </tr>
                        ])}
                    </tbody>
                    :
                    <tbody>
                        {deviceList.map((device, index) =>
                            [<tr className={"no-hover device-table-row"} key={`${device.deviceId}-tableRow`} data={index} value={index}>

                                <td style={{ padding: '0.1px 0.1px 0.1px 10px', borderLeft: '1px solid #ebeef0' }}>{device.nickname}</td>
                                <td>{device.mac}</td>
                                <td>{device.status}</td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipTemperature"><DevTemp devTemp={device.deviceTemperature} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipBattery"><BatterySymbol assetType={this.state.assetList.find(a => a.mac === device.deviceId).type} battVoltage={device.batteryVoltage} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipWifi"><WiFiSymbol wifiStrength={device.wifiRSSI} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    <span href="#" id="TooltipSensorhub"><SignalSymbol sigStrength={device.subgRSSI} isT10={device.isT10Asset} /></span>
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""}>
                                    {this.getAvgCurrentSymbol(index, "avgMainCurrent")}
                                </td>
                                <td className={this.state.isSelected === index ? "tableSelected" : ""} style={{ borderRight: '1px solid #ebeef0' }}>
                                    <span href="#" id="TooltipTestResult">
                                        {this.getTestResultSymbol(index)}
                                    </span>
                                </td>
                            </tr>
                            ])}
                    </tbody>
                :
                <tbody>
                    <tr><td>No Devices</td></tr>
                </tbody>;

        if (homeList !== null && homeList !== undefined && homeList.length) {
            let leadingButtonText = 'Once Installation is Complete, Press Button to   ';
            let runningCheckLabel = 'Running Installation Check   ';
            let lastCheckTimeLabel = 'Last Install Check Run on  ';

            let runningCheckSpinner = this.state.runningCheck[this.state.assetList.length - 1] ? <div className="modal-body">{runningCheckLabel}<Spinner animation="border" variant="dark" /></div> :
                <div />

            let lastCheckRunTime = this.state.lastRunTime ? <div>{lastCheckTimeLabel}{this.state.lastRunTime}</div> : <div />;

            return (
                <div>
                    <div>
                        <table className="table-hover" style={{ width: '100%', margin: '0px 20px', fontSize: '15px' }}>
                            <thead>
                                {testHeadings}
                            </thead>
                            {tableContent}
                        </table>
                    </div>
                </div>
            );
        }
        else if (this.props.currentCustomerId == -1) {
            return (
                <div>
                    <p className="status-msg">No customer device information to display</p>
                </div>
            );
        }
        else {
            return (<div style={{ marginLeft: '10px' }}><br />No Devices located!</div>);
        }
    }

    renderExpandContent() {
        const { classes } = this.props;

        if (this.state.loading) {
            contents = <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else if (this.state.newCustomerLoading) {
            contents = <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>;
        }
        else {
            contents = this.renderExpandDevices(this.state.homeList, this.state.deviceList, this.props.currentCustomerId, this.state.newHouseLoading);
        }

        let requestPayloadBtn = this.props.roles.includes("ADMIN") ?
            <button className="secondary-btn btn-small" style={{ width: '175px' }}
                    disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                    type="button"
                    onClick={this.showRequestPayloadModal}>
                View Request Payload
            </button>
            : null;
            
        let createAlertBtn = this.state.newAlerts.length ?
            <button className="secondary-btn btn-small" style={{ width: '175px' }}
                disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                type="button"
                onClick={this.openCreateAlertConfirmModal}>
                Create Provisioning Alert
            </button>
            : null;

        let trainBtn = this.checkAllTestsPassed(this.state.deviceList, this.state.avgCurrentList) == PASS ?
            <button className="secondary-btn btn-small" style={{ width: '175px' }}
                disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                type="button"
                onClick={this.openTrainConfirmModal}>
                Force Train Asset
            </button>
            : null;

        let awaitingDataBtn = this.props.currentRadio == "Repairs" && (this.checkAllTestsPassed(this.state.deviceList, this.state.avgCurrentList) == PASS) ?
            <button className="secondary-btn btn-small" style={{ width: '175px' }}
                disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                type="button"
                onClick={this.openAwaitingDataConfirmModal}>
                Set to Awaiting Data
            </button>
            : null;

        let installCheckButtons =
            <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '10px 20px 0px', padding: '10px 0' }}>
                <div className="col" style={{ paddingRight: '30px', gap: "5px", display: "flex" }}>
                    <button className="primary-btn btn-small" style={{ width: '175px' }}
                        disabled={this.state.runningCheck[this.state.assetList.length - 1]}
                        type="button"
                        onClick={this.runInstallCheck}>
                        Begin Provisioning Test
                    </button>
                    {requestPayloadBtn}
                    {createAlertBtn}
                    {trainBtn}
                    {awaitingDataBtn}
                </div>
            </div>;

        return (
            <div className="no-hover">
                {contents}
                {installCheckButtons}
                <InstallCheckGraphModal dataObject={this.state.multictRunData[this.state.graphAssetIndex]} show={this.state.showGraphModal} onClick={() => { this.openGraphModal() }} />

                <RequestPayloadModal show={this.state.showRequestPayloadModal} requestPayload={this.state.currentInstall.requestPayload} onClick={this.showRequestPayloadModal} />
                <ConfirmationModal show={this.state.showCreateAlertConfirm} onYes={this.onCreateAlertClick} onClick={this.showCreateAlertConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showAwaitingDataConfirm} onYes={this.onAwaitingDataClick} onClick={this.showAwaitingDataConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                <ConfirmationModal show={this.state.showTrainConfirm} onYes={this.onTrainClick} onClick={this.showTrainConfirmModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let contents = this.props.source === "InstallReport" ? this.renderExpandContent() : this.renderFullPage();

        return(contents);
    }
}

export class BatterySymbol extends React.Component {

    render() {
        if (this.props.battVoltage == null) {
            return (null);
        }
        else {
            let assetType = this.props.assetType.trim();
            let voltage = parseFloat(this.props.battVoltage);

            let battPercent = (voltage / 4.5) * 100;

            if (assetType === AirConditioner || assetType === HeatPump || assetType === PoolPump) {
                if (voltage < BatteryVoltageLow) {
                    return (
                        <Battery percent={battPercent} color={Red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
                else if (voltage >= BatteryVoltageLow && voltage <= BatteryVoltageMedium) {
                    return (
                        <Battery percent={battPercent} color={Yellow} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
                else if (voltage > BatteryVoltageHigh) {
                    if (voltage >= BatteryVoltageOshLoosebattery) {
                        return (
                            <Battery percent={battPercent} color={Red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                        );
                    }
                    else {
                        return (
                            <Battery percent={battPercent} color={Green} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                        );
                    }
                }
            }
            else {
                if (voltage > BatteryVoltageIsh) {
                    return (
                        <Battery percent={battPercent} color={Green} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
                else {
                    return (
                        <Battery percent={battPercent} color={Red} outlined={true} style={{ width: 3.5 + "em", height: 2.5 + "em" }} />
                    );
                }
            }
        }
    }
}

class CurrentSymbol extends React.Component {

    render() {
        if (this.props.avgCurrent == null) {
            return (null);
        }
        else {
            let current = parseFloat(this.props.avgCurrent[0]);
            let result = this.props.avgCurrent[1];

            if (result == PASS) {
                return (
                    <FlashOnOutlinedIcon style={{ color: Green, fontSize: 40 }} />
                );
            }
            else if (result == FAIL) {
                return (
                    <FlashOnOutlinedIcon style={{ color: Red, fontSize: 40 }} />
                );
            }
            else if (result == NO_DATA) {
                return (
                    <FlashOnOutlinedIcon style={{ color: Grey, fontSize: 40 }} />
                );
            }
            else {
                return (
                    <p>Error, got { current }</p>
                );
            }
        }
    }
}

class TestResultSymbol extends React.Component {

    render() {

        if (this.props.avgCurrent == null) {
            return (null);
        }
        else {
            let result = this.props.avgCurrent[1];
            let propertyResults = [];

            if (result == FAIL || result == NO_DATA) {
                return (
                    <HighlightOffOutlinedIcon style={{ color: Red, fontSize: 40 }} />
                );
            }

            if (this.props.propertyResults) {
                propertyResults = this.props.propertyResults.get(this.props.deviceId);
            }

            if (propertyResults) {
                if (propertyResults.includes(WARNING)) {
                    return (
                        <CheckCircleOutlineOutlinedIcon style={{ color: Yellow, fontSize: 40 }} />
                    );
                }
                else if (propertyResults.includes(FAIL)) {
                    return (
                        <HighlightOffOutlinedIcon style={{ color: Red, fontSize: 40 }} />
                    );
                }
            }

            if (result == PASS) {
                return (
                    <CheckCircleOutlineOutlinedIcon style={{ color: Green, fontSize: 40 }} />
                );
            }
            else {
                return (
                    <p>Test Error</p>
                );
            }
        }
    }
}

class WiFiSymbol extends React.Component {
    render() {
        let wifiRSSI = this.props.wifiStrength;

        if (wifiRSSI == 0 || wifiRSSI === null) {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }
        else if (wifiRSSI >= WifiExcellent) {
            return (
                <Image src={require('../../img/wifi-excellent.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }
        else if (wifiRSSI >= WifiGreat) {
            return (
                <Image src={require('../../img/wifi-great.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }
        else if (wifiRSSI >= WifiOkay) {
            return (
                <Image src={require('../../img/wifi-okay.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }
        else if (wifiRSSI >= WifiWeak) {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }
        else {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }
    }
}

class SignalSymbol extends React.Component {
    render() {
        let subgRSSI = this.props.sigStrength;

        if (subgRSSI == 0 || subgRSSI === null) {
            return (
                <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
            );
        }

        if (this.props.isT10) {
            if (subgRSSI > SubgRssiExcellentT10) {
                return (
                    <Image src={require('../../img/wifi-excellent.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
            else if (subgRSSI >= SubgRssiGreatT10) {
                return (
                    <Image src={require('../../img/wifi-great.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
            else if (subgRSSI >= SubgRssiOkayT10) {
                return (
                    <Image src={require('../../img/wifi-okay.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
            else {
                return (
                    <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
        }
        else {
            if (subgRSSI > SubgRssiExcellentLpx) {
                return (
                    <Image src={require('../../img/wifi-excellent.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
            else if (subgRSSI >= SubgRssiGreatLpx) {
                return (
                    <Image src={require('../../img/wifi-great.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
            else if (subgRSSI >= SubgRssiOkayLpx) {
                return (
                    <Image src={require('../../img/wifi-okay.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
            else {
                return (
                    <Image src={require('../../img/wifi-weak.png')} style={{ height: 2 + "em", width: 2.5 + "em" }} />
                );
            }
        }
    }
}

class SelectHVACSystem extends React.Component {
    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            return split[0];
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.hvacList != null) {
            let techInfo = this.props.techStr !== "" && this.props.techStr !== null && this.props.techStr !== undefined ?
                <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {this.props.techStr}</span>
                : null;
            return (
                <div className="select-container-2">
                    <span className="select-message">Select which HVAC System<br />
                        <select display="block" className="selectpicker" style={{ width: 300 + 'px' }} value={this.props.houseAssetId} onChange={this.props.onChange}>
                            {this.props.hvacList.map((obj, index) =>
                                <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={index}>{this.getPrettyName(obj.name)}</option>
                            )}
                        </select>
                    </span>
                    {techInfo}
                </div>
            );
        }
        else {
            return (null);
        }
    }
}

export default InstallationCheck;
