import {Item, Menu, useContextMenu} from "react-contexify";
import 'react-contexify/ReactContexify.css';
import React, {forwardRef, useImperativeHandle} from "react";

const MENU_ID = 'devContextMenu';

const ContextMenu = forwardRef(({onItemClick}, ref) => {
    const {show} = useContextMenu({
        id: MENU_ID,
    });
   
    useImperativeHandle(ref, () => ({
        show
    }));
    
    return (
        <Menu id={MENU_ID}>
            <Item onClick={()=> onItemClick("selected row")}>
                View Device Properties
            </Item>
        </Menu>
    );
});
export default ContextMenu;
export{MENU_ID}