/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {useContext, useEffect, useState} from "react";
import "../../custom.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {makeStyles} from '@mui/styles';
import {PageView} from "../GoogleAnalytics";
import LongArrowDownIcon from "@mui/icons-material/ArrowDownward";
import LongArrowUpIcon from "@mui/icons-material/ArrowUpward";
import {FleetStateContext} from "./FleetStateProvider";
import {Spinner} from "react-bootstrap";
import {useNavigate, useLocation} from "react-router-dom";
import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
}));

const CUSTOMER = "CUSTOMER";
const MAKE = "MAKE";
const MODEL = "MODEL";
const BLOWER_TYPE = "BLOWER TYPE";
const BLOWER_STAGES = "BLOWER STAGES";
const IGNITER_TYPE = "IGNITER TYPE";
const ASC = "asc";
const DESC = "desc";

function UpgradeOpportunities({ isLoading, roles = [] }) {

    const classes = useStyles();
    const location = useLocation();
    const history = useNavigate();

    const { partnerFleetDataList, isLoadingData, isShowAll, setIsShowAll } = useContext(FleetStateContext);

    const rowsPerPage = 20;

    const [isMounted, setIsMounted] = useState(false);
    const [data, setData] = useState([]);
    const [columnSort, setColumnSort] = useState({ column: "", direction: ASC });
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const paginatedRows = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    
    useEffect(() => {
        setIsMounted(true);
        PageView();

        return () => {
            setIsMounted(false);
        }
    }, []);

    useEffect(() => {
        PageView();

        if (location && location.state && location.state.currentTab) {
            setSelectedTab(location.state.currentTab === "igniterTypes" ? 1 : 0);

            history.replace({
                ...location,
                state: undefined,
            });
        }
    }, []);

    useEffect(() => {
        if (isMounted && partnerFleetDataList) {
            let filteredList = partnerFleetDataList.reduce((items, item) => {
                if (item.device && item.device.propertiesJson) {
                    items.push(item);
                }
                return items;
            }, []);
            
            setData(filteredList);
        }
    }, [isMounted, partnerFleetDataList]);

    const iconFunction = (columnName)  => {
        let arrowIcon = columnSort.direction === ASC ?
            <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
            : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />;

        return columnSort.column === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
    }

    const onColumnClick = (column) => {
        const newDirection = columnSort.direction === ASC ? DESC : ASC;
        setColumnSort({ column, direction: newDirection });
        sortTable(column);
    };

    const sortTable = (column) => {
        let sortedList = [...data];
        
        if (column === CUSTOMER) {
            sortedList.sort((a, b) => {
                const aValue = a.customer.name;
                const bValue = b.customer.name;

                return sort(aValue, bValue);
            });
        } else if (column === MAKE || column === MODEL) {
            sortedList.sort((a, b) => {
                const aValue = a.device[column.toLowerCase()];
                const bValue = b.device[column.toLowerCase()];

                return sort(aValue, bValue);
            });
        } else if (column === BLOWER_STAGES) {
            sortedList.sort((a, b) => {
                const aValue = a.device.propertiesJson ? JSON.parse(a.device.propertiesJson).BlowerStages : "";
                const bValue = b.device.propertiesJson ? JSON.parse(b.device.propertiesJson).BlowerStages : "";
                
                return sort(aValue ? aValue : "", bValue ? bValue : "");
            });
        } else if (column === BLOWER_TYPE) {
            sortedList.sort((a, b) => {
                const aValue = a.device.propertiesJson ? JSON.parse(a.device.propertiesJson).BlowerType : "";
                const bValue = b.device.propertiesJson ? JSON.parse(b.device.propertiesJson).BlowerType : "";

                return sort(aValue ? aValue : "", bValue ? bValue : "");
            });
        } else if (column === IGNITER_TYPE) {
            sortedList.sort((a, b) => {
                const aValue = a.device.propertiesJson ? JSON.parse(a.device.propertiesJson).IgniterType : "";
                const bValue = b.device.propertiesJson ? JSON.parse(b.device.propertiesJson).IgniterType : "";

                return sort(aValue ? aValue : "", bValue ? bValue : "");
            });
        }

        setData(sortedList);
    };
    
    const sort = (aValue, bValue) => {
        if (aValue < bValue) {
            return columnSort.direction === ASC ? -1 : 1;
        }

        if (aValue > bValue) {
            return columnSort.direction === ASC ? 1 : -1;
        }

        return 0;
    }

    const renderBlowerTypesContent = () => {
        return isLoadingData ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderBlowerTypesTable();
    }

    const renderBlowerTypesTable = () => {
        let tableContent = <tbody>
        {paginatedRows.map((item, index) => (
            <tr className={"alerts-table-row"} key={index} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{item.customer.name}</td>
                <td>{item.device.make}</td>
                <td>{item.device.model}</td>
                <td>{item.device.propertiesJson ? JSON.parse(item.device.propertiesJson).BlowerStages : 'N/A'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{item.device.propertiesJson ? JSON.parse(item.device.propertiesJson).BlowerType : 'N/A'}</td>
            </tr>
        ))}
        </tbody>;

        return (
            <div style={{margin: '20px 30px'}}>
                <div>
                    <table className="table-striped table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                        <thead>
                        <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                            <th>
                                <div onClick={() => onColumnClick(CUSTOMER)}>
                                    <span style={{cursor: 'pointer'}}>CUSTOMER</span>
                                    {iconFunction(CUSTOMER)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(MAKE)}>
                                    <span style={{cursor: 'pointer'}}>MAKE</span>
                                    {iconFunction(MAKE)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(MODEL)}>
                                    <span style={{cursor: 'pointer'}}>MODEL</span>
                                    {iconFunction(MODEL)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(BLOWER_STAGES)}>
                                    <span style={{cursor: 'pointer'}}>BLOWER STAGES</span>
                                    {iconFunction(BLOWER_STAGES)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(BLOWER_TYPE)}>
                                    <span style={{cursor: 'pointer'}}>BLOWER TYPE</span>
                                    {iconFunction(BLOWER_TYPE)}
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {tableContent}
                    </table>
                    {renderPagination()}
                </div>
            </div>
        );
    }

    const renderIgniterTypesContent = () => {
        return isLoadingData ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderIgniterTypesTables();
    }

    const renderIgniterTypesTables = () => {
        let tableContent = <tbody>
        {paginatedRows.map((item, index) => (
            <tr className={"alerts-table-row"} key={index} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{item.customer.name}</td>
                <td>{item.device.make}</td>
                <td>{item.device.model}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{item.device.propertiesJson ? JSON.parse(item.device.propertiesJson).IgniterType : 'N/A'}</td>
            </tr>
        ))}
        </tbody>;

        return (
            <div style={{margin: '20px 30px'}}>
                <div>
                    <table className="table-striped table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                        <thead>
                        <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                            <th>
                                <div onClick={() => onColumnClick(CUSTOMER)}>
                                    <span style={{cursor: 'pointer'}}>CUSTOMER</span>
                                    {iconFunction(CUSTOMER)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(MAKE)}>
                                    <span style={{cursor: 'pointer'}}>MAKE</span>
                                    {iconFunction(MAKE)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(MODEL)}>
                                    <span style={{cursor: 'pointer'}}>MODEL</span>
                                    {iconFunction(MODEL)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => onColumnClick(IGNITER_TYPE)}>
                                    <span style={{cursor: 'pointer'}}>IGNITER TYPE</span>
                                    {iconFunction(IGNITER_TYPE)}
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {tableContent}
                    </table>
                    {renderPagination()}
                </div>
            </div>
        );
    }
    
    const renderPagination = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={totalPages} page={currentPage} onChange={onChangePage} showFirstButton={totalPages > 7} showLastButton={totalPages > 7} size="small" />
            </div>
        );
    }

    const renderShowAllButton = () => {
        return (
            roles.includes("ADMIN") ?
                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '0 30px', padding: '10px 0' }}>
                    <div className="btn-right" style={{padding: '25px 25px 25px 30px'}}>
                        {isShowAll ? <p style={{marginRight: 10}}>*Showing all assets in system</p> : null}
                        <button className={isShowAll ? "primary-btn btn-large" : "secondary-btn btn-large"} style={{width: 150 + 'px'}} onClick={onShowAllClicked}>Show All</button>
                    </div>
                </div> : null
        );
    }

    const onShowAllClicked = () => {
        setCurrentPage(1);
        setIsShowAll(!isShowAll);
    }

    const onChangePage = (e, page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            <div>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel" style={{borderBottom: 'none', padding: '20px 0px 0px 30px'}}>
                        Upgrade Opportunities
                    </h2>

                    {renderShowAllButton()}
                    
                    <Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
                        <TabList style={{marginTop: '20px', paddingLeft: '15px'}}>
                            <Tab>Blower Types</Tab>
                            <Tab>Igniter Types</Tab>
                        </TabList>

                        <TabPanel>
                            {renderBlowerTypesContent()}
                        </TabPanel>

                        <TabPanel>
                            {renderIgniterTypesContent()}
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default UpgradeOpportunities;
