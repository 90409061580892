/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import http from "../http-common";

const controller = "/fleet";
class FleetService {

    async getpartnerfleet(partnerId) {
        const response = await http.get(`${controller}/${partnerId}`);
        const data = response.data;
        return data;
    }

    async getAll() {
        const response = await http.get(`${controller}`);
        return response?.data;
    }

}

export default new FleetService();
