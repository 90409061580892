/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, {useContext, useEffect, useState} from "react";
import "../../custom.css";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Spinner} from "react-bootstrap";
import {styled} from '@mui/system';
import {Event, PageView} from "../GoogleAnalytics";
import LongArrowDownIcon from "@mui/icons-material/ArrowDownward";
import LongArrowUpIcon from "@mui/icons-material/ArrowUpward";
import {FleetStateContext} from "./FleetStateProvider";
import {useNavigate} from "react-router-dom";
import Pagination from "@mui/material/Pagination";

const MAKE = "MAKE";
const MODEL = "MODEL";
const AGE = "AGE";
const ASC = "asc";
const DESC = "desc";
const SINGLE = "single";
const DUAL = "dual";
const VARIABLE = "variable";
const ECM = "ecm";
const PSC = "psc";
const SIC = "sic";
const SIN = "sin";
const SPARK = "spark";

const useStyles = styled((theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
}));

function Fleet({ isLoading, setActivePath, roles = [] }) {
    const classes = useStyles();
    const history = useNavigate();

    const { partnerFleetDataList, isLoadingData, isShowAll, setIsShowAll } = useContext(FleetStateContext);

    const rowsPerPage = 20;
    
    const [isMounted, setIsMounted] = useState(false);
    const [data, setData] = useState([]);
    const [columnSort, setColumnSort] = useState({ column: "", direction: ASC });
    const [selectedTab, setSelectedTab] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [upgradeOpportunities, setUpgradeOpportunities] = useState({
        singleECMCount: 0,
        singlePSCCount: 0,
        dualECMCount: 0,
        dualPSCCount: 0,
        variableECMCount: 0,
        variablePSCCount: 0,
        igniterSICCount: 0,
        igniterSINCount: 0,
        igniterSparkCount: 0
    });

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const paginatedRows = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    useEffect(() => {
        setIsMounted(true);
        PageView();
        
        return () => {
            setIsMounted(false);
        }
    }, []);

    useEffect(() => {
        if (isMounted && partnerFleetDataList) {
            setData(partnerFleetDataList);
            calculateUpgradeOpportunities(partnerFleetDataList);
        }
    }, [isMounted, partnerFleetDataList]);
    
    const calculateUpgradeOpportunities = (dataList) => {
        let counts = {
            singleECMCount: 0,
            singlePSCCount: 0,
            dualECMCount: 0,
            dualPSCCount: 0,
            variableECMCount: 0,
            variablePSCCount: 0,
            igniterSICCount: 0,
            igniterSINCount: 0,
            igniterSparkCount: 0
        };

        for (let i = 0; i < dataList.length; i++) {
            let item = dataList[i];

            if (item.device.propertiesJson) {
                let properties = JSON.parse(item.device.propertiesJson);

                if (properties.BlowerStages && properties.BlowerType) {
                    if (properties.BlowerStages.toLowerCase() === SINGLE && properties.BlowerType.toLowerCase() === ECM) {
                        counts.singleECMCount++;
                    } else if (properties.BlowerStages.toLowerCase() === SINGLE && properties.BlowerType.toLowerCase() === PSC) {
                        counts.singlePSCCount++;
                    } else if (properties.BlowerStages.toLowerCase() === DUAL && properties.BlowerType.toLowerCase() === ECM) {
                        counts.dualECMCount++;
                    } else if (properties.BlowerStages.toLowerCase() === DUAL && properties.BlowerType.toLowerCase() === PSC) {
                        counts.dualPSCCount++;
                    } else if (properties.BlowerStages.toLowerCase() === VARIABLE && properties.BlowerType.toLowerCase() === ECM) {
                        counts.variableECMCount++;
                    } else if (properties.BlowerStages.toLowerCase() === VARIABLE && properties.BlowerType.toLowerCase() === PSC) {
                        counts.variablePSCCount++;
                    }
                }
                
                if (properties.IgniterType) {
                    if (properties.IgniterType.toLowerCase() === SIC) {
                        counts.igniterSICCount++;
                    } else if (properties.IgniterType.toLowerCase() === SIN) {
                        counts.igniterSINCount++;
                    } else if (properties.IgniterType.toLowerCase() === SPARK) {
                        counts.igniterSparkCount++;
                    }    
                }
            }
        }

        setUpgradeOpportunities(counts);
    }

    const iconFunction = (columnName)  => {
        let arrowIcon = columnSort.direction === ASC ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />;

        return columnSort.column === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;
    }

    const onColumnClick = (column) => {
        const newDirection = columnSort.direction === ASC ? DESC : ASC;
        setColumnSort({ column, direction: newDirection });
        sortTable(column);
    };

    const sortTable = (column) => {
        let sortedList = [...data];
        
        if (column === MAKE || column === MODEL) {
            sortedList.sort((a, b) => {
                const aValue = a.device[column.toLowerCase()];
                const bValue = b.device[column.toLowerCase()];

                if (aValue < bValue) {
                    return columnSort.direction === ASC ? -1 : 1;
                }

                if (aValue > bValue) {
                    return columnSort.direction === ASC ? 1 : -1;
                }

                return 0;
            });
        } else if (column === AGE) {
            sortedList.sort((a, b) =>
                columnSort.direction === ASC ? a.device.age - b.device.age : b.device.age - a.device.age
            );
        }

        setData(sortedList);
    };

    const onBlowerTypesClick = () => {
        Event("Blower Types table clicked", "User clicked blower types table to go to upgrade opportunities page", "Blower Types table clicked");
        setActivePath("/upgradeOpportunities");

        const location = {
            pathname: "/upgradeOpportunities",
            state: {
                currentTab: 'blowerTypes'
            }
        }

        history(location);
    }

    const onIgniterTypesClick = () => {
        Event("Igniter Types table clicked", "User clicked ignitier types table to go to upgrade opportunities page", "Igniter Types table clicked");
        setActivePath("/upgradeOpportunities");

        const location = {
            pathname: "/upgradeOpportunities",
            state: {
                currentTab: 'igniterTypes'
            }
        }

        history(location);
    }
    
    const renderAgeOfFleetContent = () => {
        return isLoadingData ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderAgeOfFleetTable();
    }
    
    const renderAgeOfFleetTable = () => {
        let tableContent = <tbody>
        {paginatedRows.map((item, index) => (
            <tr className={"alerts-table-row"} key={index} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{item.device.make}</td>
                <td>{item.device.model}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{item.device.age}</td>
            </tr>
        ))}
        </tbody>;

        let pagination =
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Pagination count={totalPages} page={currentPage} onChange={onChangePage} showFirstButton={totalPages > 7} showLastButton={totalPages > 7} size="small" />
            </div>;

        return (
            <div style={{margin: '20px 30px'}}>
                <div>
                    <table className="table-striped table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                        <thead>
                            <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                                <th>
                                    <div onClick={() => onColumnClick(MAKE)}>
                                        <span style={{cursor: 'pointer'}}>MAKE</span>
                                        {iconFunction(MAKE)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick(MODEL)}>
                                        <span style={{cursor: 'pointer'}}>MODEL</span>
                                        {iconFunction(MODEL)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => onColumnClick(AGE)}>
                                        <span style={{cursor: 'pointer'}}>AGE</span>
                                        {iconFunction(AGE)}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {tableContent}
                    </table>
                    {pagination}
                </div>
            </div>
        );
    }

    const renderUpgradeOpportunitiesContent = () => {
        return isLoadingData ?
            <div className={["loading", classes.root].join(' ')} style={{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : renderUpgradeOpportunitiesTables();
    }

    const renderUpgradeOpportunitiesTables = () => {
        let blowerTypeTableContent = <tbody>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Single'}</td>
                <td>{upgradeOpportunities.singleECMCount}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.singlePSCCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Dual'}</td>
                <td>{upgradeOpportunities.dualECMCount}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.dualPSCCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Variable'}</td>
                <td>{upgradeOpportunities.variableECMCount}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.variablePSCCount}</td>
            </tr>
        </tbody>;

        let igniterTypeTableContent = <tbody>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'SIC'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.igniterSICCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'SIN'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.igniterSINCount}</td>
            </tr>
            <tr className={"alerts-table-row"} style={{textAlign: 'center'}}>
                <td style={{borderLeft: '1px solid #ebeef0'}}>{'Spark'}</td>
                <td style={{borderRight: '1px solid #ebeef0'}}>{upgradeOpportunities.igniterSparkCount}</td>
            </tr>
        </tbody>;

        return (
            <div style={{margin: '20px 30px'}}>
                <div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: 'space-between'}}>
                        <h6 style={{marginBottom: 0}}>Blower Types</h6>
                        <button type="button" className="secondary-btn btn-small" onClick={onBlowerTypesClick}>
                            View All
                        </button>
                    </div>
                    <table className="table-striped table-hover" style={{width: '100%', tableLayout: 'fixed'}}>
                        <thead>
                        <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>BLOWER</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>ECM</span>
                                </div>
                            </th>
                            <th>
                                <div>
                                    <span style={{cursor: 'pointer'}}>PSC</span>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        {blowerTypeTableContent}
                    </table>

                    <div style={{width: '100%'}}>
                        <div style={{width: '50%', display: "flex", alignItems: "center", marginTop: '50px', justifyContent: 'space-between'}}>
                            <h6 style={{marginBottom: 0}}>Igniter Types</h6>
                            <button type="button" className="secondary-btn btn-small" onClick={onIgniterTypesClick}>
                                View All
                            </button>
                        </div>
                        <table className="table-striped table-hover" style={{width: '50%', tableLayout: 'fixed'}}>
                            <thead>
                            <tr className="alerts-table-row" style={{textAlign: 'center'}}>
                                <th>
                                    <div>
                                        <span style={{cursor: 'pointer'}}>IGNITER</span>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span style={{cursor: 'pointer'}}>COUNT</span>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            {igniterTypeTableContent}
                        </table>
                    </div>

                </div>
            </div>
        );
    }
    
    const renderShowAllButton = () => {
        return (
            roles.includes("ADMIN") ?
                <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '0 30px', padding: '10px 0' }}>
                    <div className="btn-right" style={{padding: '25px 25px 25px 30px'}}>
                        {isShowAll ? <p style={{marginRight: 10}}>*Showing all assets in system</p> : null}
                        <button className={isShowAll ? "primary-btn btn-large" : "secondary-btn btn-large"} style={{width: 150 + 'px'}} onClick={onShowAllClicked}>Show All</button>
                    </div>
                </div> : null
        );
    }
    
    const onShowAllClicked = () => {
        setCurrentPage(1);
        setIsShowAll(!isShowAll);
    }

    const onChangePage = (e, page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            <div>
                <div className='infoPage'>
                    <h2 className="pageTitle" id="tabelLabel"
                        style={{borderBottom: 'none', padding: '20px 0px 0px 30px'}}>
                        Partner Fleet
                    </h2>
                    
                    {renderShowAllButton()}
                    
                    <Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
                        <TabList style={{marginTop: '20px', paddingLeft: '15px'}}>
                            <Tab>Age of Fleet</Tab>
                            <Tab>Upgrade Opportunities</Tab>
                        </TabList>

                        <TabPanel>
                            {renderAgeOfFleetContent()}
                        </TabPanel>

                        <TabPanel>
                            {renderUpgradeOpportunitiesContent()}
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}
export default Fleet;
