/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { NavigationBar } from '../NavigationBar';
import { SetPageViewTitle } from "../GoogleAnalytics";

import RegisterUserService from "../../services/registeruser.service";

class RegisterUser extends Component {
    static displayName = RegisterUser.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            userId: -1,
            loading: true,
            isVerified: false
        };
    }

    async componentWillMount() {
        await RegisterUserService.get(this.props.registeruserId).then(res => {
            this.setState({ isVerified: res, loading: false });
        });        
    }

    componentDidMount() {
        this._isMounted = true;
        SetPageViewTitle('registerUser');
        //RegisterUserService.get(this.props.registeruserId);        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderPage() {
        if (this.state.isVerified) {
            return (
                
                <div className='registerUserInfoPage'>
                        <h1 id="tabelLabel" >Register User</h1>
                        <p>Thanks for registering your LifeWhere account. Return to the LifeWhere app to log in, set up your devices and view your personal LifeWhere dashboard.</p>
                </div>

                
            );
        }
        else {
            return (
                <div className='registerUserInfoPage'>
                        <h1 id="tabelLabel" >Register User</h1>
                        <p>Something went wrong while registering your LifeWhere account. Please try again later or call customer support.</p>
                </div>
            );
        }
    }
    
    render() {
        const { classes } = this.props;

        let contents = this.state.loading
            ?
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
            <div className='registerUserInfoPage'>
                <h1 id="tabelLabel" >Register User</h1>
                
            </div>


                <div className={["loading"].join(' ')} style = {{ display: "block" }} sx={(theme)=>({
                    marginLeft: '225px',
                    [theme.breakpoints.down('md')]: {
                        marginLeft: '-225px !important',
                        width: `calc(100% + 450px)`,
                    },
                })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div></div>
            : this.renderPage();

        return (
            <div>
                <NavigationBar onLogOff={this.onLogOff} showButton={false} />                
                    {contents}
            </div>
        );
    }
}

export default RegisterUser;
