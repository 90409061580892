/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { TextField } from '@mui/material';
import { PageView, Event } from "../GoogleAnalytics";
import RegisterUserService from "../../services/registeruser.service";
import "../../CSS/DataModal.css";


class ConfirmationEmail extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onClick = this.onClick.bind(this);
        this.state = {
            ConfirmationEmailText: "",
            isEmailSentsucess: false,
            ConfirmationEmailMessage: "",
            errorDisplayClass: "red",
            isSentEmail:false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
    }

    onChangeEmail(e) {
        const email = e.target.value;

        this.setState({
            ConfirmationEmailText: email
        }
        );
    };

    onClick = async () => {
        Event("Confirmation Email Sent", "User clicked send button to confirm email", "Confirmation Email Sent");
        var response = await RegisterUserService.get(this.state.ConfirmationEmailText).then(res => {
            if (res) {
                this.setState({
                    ConfirmationEmailMessage: "Sucessfully Confirmed Email.",
                    isEmailSentsucess: res,
                    isSentEmail: true
                });
            }
            else {
                this.setState({
                    ConfirmationEmailMessage: "Failed to Confirmed Email.",
                    isEmailSentsucess: res,
                    isSentEmail: true
                });
            }

        });

        setTimeout(() => {
            this.setState({
                ConfirmationEmailMessage: " ",
                isEmailSentsucess: false,
                isSentEmail: false
            });
        }, 3000);

    };


    render() {
        const { classes } = this.props;
        return (
            <div>
                <div >
                    <div className='infoPage'>
                        <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                            Confirmation Email
                    </h2>


                        <div className={"email-wrapper"}>
                            <div className={"email-wrapper-body"}>
                                <label>Customer  Confirmation Email:</label>
                            </div>
                            <div style={{ width : '330px' }} className={"email-wrapper-body"}>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="last-name"
                                    value={this.state.ConfirmationEmailText}
                                    onChange={this.onChangeEmail}
                                />

                            </div>
                            <div className={"email-wrapper-body"}>
                                <button style={{ marginTop: ".7em" }} className="secondary-btn btn-small"
                                    onClick={this.onClick}> Send
                    </button>
                            </div>
                            {(this.state.isSentEmail) &&
                                (<div className={"email-wrapper-body"}>
                                    <label style={{ color: this.state.isEmailSentsucess ? 'green' : 'red' }}>{this.state.ConfirmationEmailMessage}</label>
                                </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default ConfirmationEmail;