import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { PageView, Event } from "../GoogleAnalytics";

const csvBaseName = "EnergyMetrics.csv";
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const columnsToExclude = ['partnerOrgId', 'customerOrgId', 'homeId', 'hvacId', 'assetId', 'events'];


function modifyArray(arrayOfObjects) {
    const modifiedArray = arrayOfObjects.map(obj => {
        const modifiedObj = { ...obj }; 
        columnsToExclude.forEach(variable => {
            delete modifiedObj[variable];
        });
        return modifiedObj;
    });
    return modifiedArray;
}

function convertToCSV(objArray) {
    const array = [Object.keys(objArray[0])].concat(objArray.map(obj => Object.values(obj)));
    return array.map(row => row.join(',')).join('\n');
};

function downloadCSV(data, deviceName, date) {
    Event("Download Data Clicked", "User clicked download data button in Partner Energy Metrics", "Download Data Clicked");
    var filenameWithDate = deviceName + "_" + months[date.getMonth()] + "_" + csvBaseName;
    var modifiedData = modifyArray(data);
    const csvContent = "data:text/csv;charset=utf-8," + encodeURIComponent(convertToCSV(modifiedData));

    const link = document.createElement("a");
    link.setAttribute("href", csvContent); // Set csvContent directly
    link.setAttribute("download", filenameWithDate || "data.csv");
    document.body.appendChild(link);
    link.click();
};

class DeviceEnergyMetricsTable extends Component {
    static defaultSortCol = "DATE";
    constructor(props) {
        super(props);

        //this.populateData = this.populateData.bind(this);

        this.state = {
            loading: false,
            columnSort: {
                enabled: true,
                type: "DATE",
                reverse: false
            },
            columnAltered: false,
            columnData: [],
            selected: -1,
            selectedDeviceName: ""
        };
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        let data = this.props.deviceData;
        let colData = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colData = this.props.columnSort(data, "DATE", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colData = this.props.columnSort(data, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colData = this.props.columnSort(data, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colData = this.props.columnSort(data, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, columnData: colData }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    formatDate(dateString) {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        // Pad single-digit month and day with leading zero
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        return `${formattedMonth}-${formattedDay}-${year}`;
    }

    renderTable() {
        let deviceData = this.state.columnAltered ? this.state.columnData : this.props.deviceData;

        let deviceContent = deviceData.length ?
            <tbody>
                {deviceData.map((item, index) =>
                    [<tr className="alerts-table-row" key={item.runDate} data={index} value={index}>
                        <td style={{ padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0' }}>
                            {this.formatDate(item.runDate)}
                        </td>
                        <td>{item.amps.toFixed(2)}</td>
                        <td>{item.kilowattHours.toFixed(2)}</td>
                        <td>{this.props.formatSeconds(item.timeRunning.toFixed(0))}</td>
                        <td>${item.cost.toFixed(2)}</td>
                        <td>${item.gasCost.toFixed(2)}</td>
                        <td>{item.runs}</td>
                        <td className="alerts-td-right">{item.alerts}</td>
                    </tr>
                    ])}
            </tbody>
            : <tbody>
                <tr>
                    <td>{""}</td>
                </tr>
            </tbody>;


        return (
            <div style={{ margin: '20px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                            <tr className="alerts-table-row">
                                <th style={{ padding: '0px 0px 0px 10px' }}>
                                    <div onClick={() => this.onColumnClick("DAY")}>
                                        <span style={{ cursor: 'pointer' }}>DAY</span>
                                        {this.props.iconFunction("DAY", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("AMPS")}>
                                        <span style={{ cursor: 'pointer' }}>AMPS</span>
                                        {this.props.iconFunction("AMPS", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("KWH")}>
                                        <span style={{ cursor: 'pointer' }}>KWH</span>
                                        {this.props.iconFunction("KWH", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("TIME_RUNNING")}>
                                        <span style={{ cursor: 'pointer' }}>TIME RUNNING</span>
                                        {this.props.iconFunction("TIME_RUNNING", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("ELECTRIC_COST")}>
                                        <span style={{ cursor: 'pointer' }}>ELECTRIC COST</span>
                                        {this.props.iconFunction("ELECTRIC_COST", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("GAS_COST")}>
                                        <span style={{ cursor: 'pointer' }}>GAS COST</span>
                                        {this.props.iconFunction("GAS_COST", this.state.columnSort)}
                                    </div>
                                </th>
                                <th>
                                    <div onClick={() => this.onColumnClick("RUNS")}>
                                        <span style={{ cursor: 'pointer' }}>RUNS</span>
                                        {this.props.iconFunction("RUNS", this.state.columnSort)}
                                    </div>
                                </th>
                                <th className="table-shrink" style={{ textAlign: 'center', padding: '10px 10px 10px 5px' }}>
                                    <div onClick={() => this.onColumnClick("ALERTS")}>
                                        <span style={{ cursor: 'pointer' }}>ALERTS</span>
                                        {this.props.iconFunction("ALERTS", this.state.columnSort)}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {deviceContent}
                    </table>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        let deviceName = this.props.deviceData[0].deviceName;
        let downloadBtn =
            <div className="row no-gutters alerts-tab-wrapper" style={{ margin: '0 30px', padding: '10px 0' }}>
                <div className="col" style={{ paddingRight: '30px' }}>
                    <button className="primary-btn btn-small" style={{ width: '150px' }} onClick={() => downloadCSV(this.props.deviceData, deviceName, this.props.dateSelected)}>Download Data</button>
                </div>
            </div>

        let tableContents = false ? //this.state.tableLoading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderTable();

        return (

            <div>
                {tableContents}
                {downloadBtn}
            </div>
        );
    }
}

export default DeviceEnergyMetricsTable;