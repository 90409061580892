/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Image, Spinner } from 'react-bootstrap';
import GatewayWorkflowModal from './GatewayWorkflowModal';
import HelpModal from './HelpModal';
import HomeService from "../../services/home.service";
import AssetService from "../../services/asset.service";
import DeviceService from "../../services/device.service";
import ProvisioningService from "../../services/provisioning.service";

import memoize from "memoize-one";
import moment from 'moment';

import { jsAsset, jsDevice, jsHouse } from "../../componentObjects";
import { PageView, Event, Timing, ModalView } from "../GoogleAnalytics";
import installService from "../../services/install.service";

const provisionPost = {
    homeList: [],
    assetList: [],
    hvacList: [] 
}

class Provisioning extends Component {
    static displayName = Provisioning.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.showGatewayModal = this.showGatewayModal.bind(this);
        this.openGatewayModal = this.openGatewayModal.bind(this);

        this.showHelpModal = this.showHelpModal.bind(this);
        this.openHelpModal = this.openHelpModal.bind(this);

        this.onHVACSelect = this.onHVACSelect.bind(this);
        this.onDeviceSelect = this.onDeviceSelect.bind(this);
        this.onUnparentedAssetSelect = this.onUnparentedAssetSelect.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.populateHomeData = this.populateHomeData.bind(this);
        this.populate = this.populate.bind(this);

        this.state = {

            provision: provisionPost,

            deviceList: [],
            homeList: [],
            hvacList: [],
            assetList: [],

            loading: false,
            currentHVACSystem: jsHouse,
            currentHome: jsHouse,
            currentDevice: jsAsset,
            currentInstall: {},
            currentInstallTechStr: "",
            unparentedAsset: jsAsset,            
            unparentedAssets: [],
            deviceProvisioningSteps: [],
            failedSteps: [],
            stepsLoading: false,
            showHelp: false,
            showAddUnparentedAsset: false,
            statusMessage: null,
            showStatusMessage: null,
            radio: 0,
            isDisabled: true,
            fixLoading: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();
                
        /*if (this.state.loading === false) {
            this.setState({
                loading: true
            }, () => {
                this.populateHomeData();
            });
        }*/
        if (!this.props.isLoading)
            this.populateHomeData();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCustomerId !== this.props.currentCustomerId) {
            this.populateHomeData();
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    memHomeData = memoize(this.populateHomeDataNew);

    async populate(id) {
        var startTime = performance.now();
        await HomeService.get(id, false).then(response => {
            if (this._isMounted)
                this.setState({ homeList: response.data, currentHome: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                    if (this.state.currentHome !== undefined && this.state.currentHome != null) {

                        await AssetService.gethvacassets(this.state.currentHome.houseAssetId).then(response => {
                            if (this._isMounted)
                                this.setState({ hvacList: response.data, currentHVACSystem: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsHouse }, async () => {
                                    // set a selected hvac system, then get the list of each asset for that system.. just like for home, 

                                    if (this.state.currentHVACSystem !== undefined && this.state.currentHVACSystem !== null) {
                                        await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                                            if (this._isMounted)
                                                this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                                                    var macId = this.getMacId(this.state.assetList);
                                                    await installService.getinstallbymacid(macId).then(response2 => {
                                                        let install = response2;
                                                        if (install !== null && install !== undefined) {
                                                            let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                                            this.setState({
                                                                currentInstall: install, currentInstallTechStr: techStr
                                                            });
                                                        }
                                                    });

                                                            await DeviceService.get(this.state.assetList)
                                                                .then(response => {
                                                                    if (this._isMounted)
                                                                        this.setState({ deviceList: response.data, currentDevice: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsDevice }, async () => {
                                                                            this.setState(prevState => ({
                                                                                provision: {
                                                                                    ...prevState.provision,
                                                                                    homeList: this.state.homeList,
                                                                                    assetList: this.state.assetList,
                                                                                    hvacList: this.state.hvacList
                                                                                }
                                                                            }), async () => {
                                                                                if (this.state.currentDevice != null && this.state.currentDevice !== undefined) {
                                                                                    await ProvisioningService.get(this.state.currentDevice.mac, this.props.currentCustomerId, this.state.currentAsset.assetId, this.state.provision)
                                                                                        .then(response => {
                                                                                            if (this._isMounted)
                                                                                                this.setState({ deviceProvisioningSteps: response.data !== null && response.data !== undefined ? response.data.steps : [], failedSteps: response.data !== null && response.data !== undefined ? response.data.failedSteps : [] }, () => {
                                                                                                    this.setState({ loading: false }, () => {
                                                                                                        var elapsedTime = performance.now() - startTime;
                                                                                                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                                                                                    });
                                                                                                });
                                                                                        })
                                                                                        .catch(e => {
                                                                                            console.log(e);
                                                                                        });

                                                                                    await AssetService.getunparented()
                                                                                        .then(response => {
                                                                                            if (this._isMounted)
                                                                                                this.setState({ unparentedAssets: response.data, unparentedAsset: response.data !== null && response.data !== undefined && response.data.length ? response.data[0] : jsAsset });
                                                                                        }).catch(e => {
                                                                                            console.log(e);
                                                                                        });
                                                                                }
                                                                                else {
                                                                                    this.setState({ loading: false });
                                                                                }
                                                                            });                                                                           

                                                                        })
                                                                });
                                                        //});                                                    
                                                });
                                        });
                                    }
                                    else {
                                        this.setState({ loading: false }, () => {
                                            var elapsedTime = performance.now() - startTime;
                                            Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                                        });
                                    }

                                });
                        });
                    }
                    //else {
                    //    await DeviceService.get(this.state.assetList)
                    //        .then(response => {
                    //            if (this._isMounted)
                    //                this.setState({ deviceList: response.data }, () => {
                    //                    this.setState({ loading: false }, () => {
                    //                        var elapsedTime = performance.now() - startTime;
                    //                        Timing("Devices Loading", "loading", elapsedTime, "Customer Devices Loading");
                    //                    });
                    //                });
                    //        });
                    //}
                });
        });
    }

    populateHomeData() {
        this.setState({ loading: true }, async () => {
            await this.populate(this.props.currentCustomerId);
        })
    }

    async populateHomeDataNew(propId) {
        this.populate(propId);
    }

    getMacId(assets) {
        if (assets !== null && assets !== undefined && assets.length) {
            if (assets.length > 1) {
                if (assets.filter(a => a.type.trim() !== "Thermostat").length)
                    return assets.filter(a => a.type.trim() !== "Thermostat")[0].mac;
            }
            else
                return assets[0].mac;
        }
    }

    async changeRadio(id) {
        var startTime = performance.now();
        Event("Provisioning Radio Clicked", "User clicked a radio button in provisioning screen", "Provisioning Radio Clicked");
        this.setState({ radio: id, stepsLoading: true }, async () => {
            if (this.state.radio === 0 || this.state.radio === 1) {
                // device // 
                if (this.state.currentDevice !== null && this.state.currentDevice !== undefined) {
                    await ProvisioningService.get(this.state.currentDevice.mac, this.props.currentCustomerId, this.state.currentDevice.assetId, this.state.provision)
                        .then(response => {

                            this.setState({ deviceProvisioningSteps: response.data !== null && response.data !== undefined ? response.data.steps : [], failedSteps: response.data !== null && response.data !== undefined ? response.data.failedSteps : [], stepsLoading: false }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("Provisioning Steps Loading", "stepsLoading", elapsedTime, "Provisioning Steps Loading");
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
                else {
                    this.setState({ stepsLoading: false }, () => {
                        var elapsedTime = performance.now() - startTime;
                        Timing("Provisioning Steps Loading", "stepsLoading", elapsedTime, "Provisioning Steps Loading");
                    });
                }

            }
            else if (this.state.radio === 2) {
                // unassigned //

                if (this.state.unparentedAsset !== null && this.state.unparentedAsset !== undefined) {
                    await ProvisioningService.get(this.state.unparentedAsset.name, this.props.currentCustomerId, this.state.unparentedAsset.assetId, this.state.provision)
                        .then(response => {
                            this.setState({ deviceProvisioningSteps: response.data !== null && response.data !== undefined ? response.data.steps : [], failedSteps: response.data !== null && response.data !== undefined ? response.data.failedSteps : [], stepsLoading: false }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("Provisioning Steps Loading", "stepsLoading", elapsedTime, "Provisioning Steps Loading");
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            }
            else {
                // ad hoc //
            }
        });
    }

    onHVACSelect(e) {
        var startTime = performance.now();
        Event("Customer HVAC System Selected", "User selected one of the customers HVAC systems in dropdown", "Customer HVAC System Selected");
        this.setState({
            currentHVACSystem: this.state.hvacList[e.target.value], stepsLoading: true
        }, async () => {

            // set a selected hvac system, then get the list of each asset for that system.. just like for home
            await AssetService.get(this.state.currentHVACSystem.assetId).then(response2 => {
                if (this._isMounted)
                    this.setState({ assetList: response2.data, currentAsset: response2.data !== null && response2.data !== undefined && response2.data.length ? response2.data[0] : jsAsset }, async () => {
                        var macId = this.getMacId(this.state.assetList);
                        await installService.getinstallbymacid(macId).then(response2 => {
                            let install = response2;
                            if (install !== null && install !== undefined) {
                                let techStr = install.technicianName !== null && install.technicianName !== undefined ? install.technicianName + " (" + install.technicianEmail + ") " + moment(install.startDateTime).format("MM/DD/YYYY hh:mm a") : "";
                                this.setState({
                                    currentInstall: install, currentInstallTechStr: techStr
                                });
                            }
                        });

                        await DeviceService.get(this.state.assetList)
                            .then(response => {
                                if (this._isMounted)
                                    this.setState({ deviceList: response.data }, () => {
                                        this.setState({ currentDevice: this.state.deviceList !== null && this.state.deviceList !== undefined && this.state.deviceList.length ? this.state.deviceList[0] : jsDevice, stepsLoading: false }, async () => {
                                            await ProvisioningService.get(this.state.currentDevice.mac, this.props.currentCustomerId, this.state.currentAsset.assetId, { homeList: this.state.homeList, assetList: this.state.assetList, hvacList: this.state.hvacList })
                                                .then(response => {

                                                    this.setState({ deviceProvisioningSteps: response.data !== null && response.data !== undefined ? response.data.steps : [], failedSteps: response.data !== null && response.data !== undefined ? response.data.failedSteps : [], stepsLoading: false }, () => {
                                                        var elapsedTime = performance.now() - startTime;
                                                        Timing("Provisioning Steps Loading", "stepsLoading", elapsedTime, "Provisioning Steps Loading");
                                                    });
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        });
                                    })
                            });
                    });
            });

        });
    }

    onDeviceSelect(e) {
        var startTime = performance.now();
        Event("Provisioning Device Selected", "User selected new device to provision", "Provisioning Device Selected");
        this.setState({
            currentDevice: this.state.deviceList[e.target.value], stepsLoading: true, currentAsset: this.state.assetList[e.target.value]
        }, async () => {
            await ProvisioningService.get(this.state.currentDevice.mac, this.props.currentCustomerId, this.state.currentAsset.assetId, { homeList: this.state.homeList, assetList: this.state.assetList, hvacList: this.state.hvacList })
                .then(response => {

                    this.setState({ deviceProvisioningSteps: response.data !== null && response.data !== undefined ? response.data.steps : [], failedSteps: response.data !== null && response.data !== undefined ? response.data.failedSteps : [], stepsLoading: false }, () => {
                        var elapsedTime = performance.now() - startTime;
                        Timing("Provisioning Steps Loading", "stepsLoading", elapsedTime, "Provisioning Steps Loading");
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    onUnparentedAssetSelect(e) {
        var startTime = performance.now();
        Event("Provisioning Unparented Selected", "User selected an unparented asset to provision", "Provisioning Unparented Asset Selected");
        this.setState({
            unparentedAsset: this.state.unparentedAssets[e.target.value], stepsLoading: true 
        }, async () => {
            await ProvisioningService.get(this.state.unparentedAsset.name, this.props.currentCustomerId, this.state.unparentedAsset.assetId, { homeList: this.state.homeList, assetList: this.state.assetList, hvacList: this.state.hvacList })
                .then(response => {
                    this.setState({ deviceProvisioningSteps: response.data !== null && response.data !== undefined ? response.data.steps : [], failedSteps: response.data !== null && response.data !== undefined ? response.data.failedSteps : [], stepsLoading: false }, () => {
                        var elapsedTime = performance.now() - startTime;
                        Timing("Provisioning Steps Loading", "stepsLoading", elapsedTime, "Provisioning Steps Loading");
                    });
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    showGatewayModal = e => {
        this.setState({
            showGateway: !this.state.showGateway
        }, () => {
                if (this.state.showGateway)
                    ModalView("gatewayWorkflow");
        });
        window.scrollTo(0, 0);
    }

    openGatewayModal() {
        Event("Open Gateway Workflow Clicked", "User clicked open gateway workflow button", "Open Gateway Workflow Clicked");
        this.showGatewayModal(null);
    }

    showHelpModal = e => {
        this.setState({
            showHelp: !this.state.showHelp,
        }, () => {
                if (this.state.showHelp)
                    ModalView("provisioningFix");
        });
        window.scrollTo(0, 0);
    }

    setupModal(fixTitle, fixMessage) {
        var startTime = performance.now();
        this.setState({ ModalTitle: fixTitle, ModalMessage: fixMessage, fixLoading: false }, () => {
            this.showHelpModal(null);
            var elapsedTime = performance.now() - startTime;
            Timing("Fix Modal Loading", "fixLoading", elapsedTime, "Fix Modal Loading");
            });
    }
        //else {
        //    this.setState({ ModalTitle: "No Information", ModalMessage: "Sorry, No information was found for this issue.", fixLoading: false }, () => {
        //        this.showHelpModal(null);
        //    });
        //}

    async openHelpModal(fixTitle, fixMessage) {
        // have to go get the modal content 
        
        Event("Fix Clicked", "User clicked fix button", "Fix Clicked");
        this.setState({ fixLoading: true });

        this.setupModal(fixTitle, fixMessage);
    }


    renderAssets(homeList, unparentedAssets, deviceList, currentId) {
        const { classes } = this.props;

        let fixContents = this.state.fixLoading ?
            <div className={["loading"].join(' ')} style = {{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : <div>{null}</div>
        
        let tableContents = this.state.stepsLoading ?
            <div className={["loading"].join(' ')} style = {{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
                : <div>
                <table className="table table-striped table-bordered table-sm" aria-labelledby="tableLabel" >
                    <thead>
                        <tr>
                            <th>Step #</th>
                            <th>Status</th>
                            <th>Provisioning Step</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead><tbody>
                        {this.state.deviceProvisioningSteps.map(step =>
                            <DescriptionAndFix key={step.stepNumber} description={step.description} status={step.status} fix={this.state.fix} onClick={this.openHelpModal} stepNumber={step.stepNumber} name={step.name} fixMessage={step.fixModal.message} fixTitle={step.fixModal.title} />
                        )}
                    </tbody>
                 </table>
                {fixContents}</div>

        if (this.props.currentCustomerId === -1) {
            return (
                <div>
                    <p className="status-msg">No customer selected. Please select a customer to view provisioning information.</p>
                </div>
            );
        }
            
        return (
            <div className="edit-form">
                <div className="white-container">
                    <div className="form">

                        <h6 className="sectionTitle" >Select Source for Asset Provisioning</h6>
                        <div className="radio-group">
                            <label className="col-md-5 col-lg-4 px-1" style={{ maxWidth: '250px' }}><input type="radio" disabled className="q1 col-3" value="q10" id="q10" checked={false} style={{ display: 'none' }} />HVAC System</label>
                            <SelectHVACSystem hvacList={this.state.hvacList} onChange={this.onHVACSelect} radio={this.state.radio} techStr={this.state.currentInstallTechStr} />
                            <br />
                            <label className="col-md-5 col-lg-4 px-1" style={{ maxWidth: '250px' }}><input type="radio" className="q1 col-3" value="q11" id="q11" checked={this.state.radio === 0} onChange={this.changeRadio.bind(this, 0)} />User Device</label>
                            <SelectDevice deviceList={this.state.deviceList} onChange={this.onDeviceSelect} radio={this.state.radio} />
                            <br />
                            <label className="col-md-5 col-lg-4 px-1" style={{ maxWidth: '250px' }}><input type="radio" className="q1 col-3" value="q12" id="q12" checked={this.state.radio === 1} onChange={this.changeRadio.bind(this, 1)} />Unassigned Devices</label>
                            <SelectUnparentedAsset unparentedAssets={unparentedAssets} onChange={this.onUnparentedAssetSelect} radio={this.state.radio} />
                            <br />

                            <label className="col-md-5 col-lg-4 px-1" style={{ maxWidth: '250px' }}><input type="radio" className="q1 col-3" value="q13" id="q13" checked={this.state.radio === 2} onChange={this.changeRadio.bind(this, 2)} />Ad hoc MAC</label>
                            <input
                                type="text"
                                className="selectpicker col-sm-7 col-md-6 col-lg-5"
                                id="adHocMac"
                                placeholder="Type here..."
                                disabled={this.state.radio === 2 ? false : true}
                                style={{ marginBottom: '10px', border: '1px solid grey', borderRadius: '.3em', backgroundColor: "white", maxWidth: '250px' }}
                            />

                            <br />
                        </div>
                    </div>
                </div>
                <br /> 
                <form>
                    <div className="table-container">
                        {tableContents}
                    </div>
                </form>
                <div>

                    <button
                        type="button"
                        className="gatewaybtn-right secondary-btn btn-small"
                        onClick={() => this.openGatewayModal()}
                    >
                        Open Gateway Workflow...
                    </button>
                </div>

                <GatewayWorkflowModal backdrop="static" onClick={this.showGatewayModal} show={this.state.showGateway}>
                </GatewayWorkflowModal>

                <HelpModal onClick={this.showHelpModal} show={this.state.showHelp} ModalTitle={this.state.ModalTitle} ModalMessage={this.state.ModalMessage} />

            </div >
        );

    }

    render() {
        const { classes } = this.props;
        
        let notPartnerAdminMsg = <div style={{ marginLeft: '10px' }}>
            <br />
            <h5>Must be a Admin to access this page!</h5>
        </div>;
        
        let contents = this.state.loading
            ? <div className={["loading"].join(' ')} style = {{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : (this.props.roles.includes("ADMIN")  ?
                this.renderAssets(this.state.homeList, this.state.unparentedAssets, this.state.deviceList, this.props.currentCustomerId)
                : notPartnerAdminMsg
            );


        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className='infoPage'>
                    <h3 className="pageTitle">Provisioning</h3>
                    {contents}
                </div>
            </div>
        );
    }
}

class SelectUnparentedAsset extends React.Component {
    render() {
        if (this.props.unparentedAssets != null) {
            return (
                <select style={{ maxWidth: '250px' }} className="selectpicker col-sm-7 col-md-6 col-lg-5" value={this.props.organizationId} onChange={this.props.onChange} disabled={this.props.radio === 1 ? false : true}>
                    {this.props.unparentedAssets.map((obj, index) =>
                        <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.orgId}`} value={index}>{obj.name}</option>
                    )}
                </select>

            );
        }
        else {
            return (null);
        }
    }
}

class SelectDevice extends React.Component {
    render() {

        if (this.props.deviceList != null) {

            return (

                <select style={{ maxWidth: '250px' }} className="selectpicker col-sm-7 col-md-6 col-lg-5" value={this.props.currentCustomerId} onChange={this.props.onChange} disabled={this.props.radio === 0 ? false : true}>
                    {this.props.deviceList.map((obj, index) =>
                        <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={index}>{obj.mac}</option>
                    )}
                </select>

            );
            
        }
        else {
            return (null);
        }
    }
}

class SelectHVACSystem extends React.Component {
    getPrettyName(name) {
        if (name !== null && name !== undefined) {

            var split = name.split("__");
            return split[0];
        }
        else {
            return "";
        }
    }

    render() {
        if (this.props.hvacList != null) {
            let techInfo = this.props.techStr !== "" && this.props.techStr !== null && this.props.techStr !== undefined ?
                <span style={{ paddingLeft: '20px', color: 'slategrey' }}>Installed By: {this.props.techStr}</span>
                : null;
            return (<span>
                <select className="selectpicker col-sm-7 col-md-6 col-lg-5" style={{ maxWidth: '250px' }} value={this.props.houseAssetId} onChange={this.props.onChange}>
                    {this.props.hvacList.map((obj, index) =>
                        <option key={`${index}-${obj.assetId}`} id={`${index}-${obj.assetId}`} value={index}>{this.getPrettyName(obj.name)}</option>
                    )}
                </select>
                {techInfo}
            </span>
            );
        }
        else {
            return (null);
        }
    }
}

class SelectStatusIcon extends React.Component {
    render() {

        if (this.props.status === 1) {
            return (
                <Image src={require('../../img/icon-good-16.png')} />
                );
        }
        else if (this.props.status === -1) {
            return (
                <Image src={require('../../img/icon-bad-16.png')} />
                );
        }
        else {
            return (
                <Image src={require('../../img/icon-unknown-16.png')} />
                );
        }
    }
}

class DescriptionAndFix extends React.Component {
    render() {
        return (
            <tr key={this.props.stepNumber}>
                <td>{this.props.stepNumber}</td>
                <td style={{ textAlign: 'center' }}><SelectStatusIcon status={this.props.status} /></td>
                <td>{this.props.name}</td>
                <td>
                    {this.props.description}
                </td>
                <td>
                    <button
                        type="button"
                        className="primary-btn btn-small"
                        onClick={() => { this.props.onClick(this.props.fixTitle, this.props.fixMessage) }}
                        style={{ marginLeft: 10 + 'px', display: this.props.status !== -1 ? 'none' : 'block' }}
                    >
                        Fix
                    </button>
                </td>
            </tr>   
        );  
    }
}

export default Provisioning;