/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { Image } from 'react-bootstrap';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

class SearchCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.onSearchTypeChange = this.onSearchTypeChange.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);

        this.state = {
            searchType: "name",
            inputValue: "",
            autocompletedItems: []
        }
    }

    async onSearchTypeChange(e) {
        var str = e.target.value;

        if (str === "email" || str === "address") {
            //this.props.setCustomerList(str);
        }

        //todo create a callback for loading effecting the search bar ? (set a bool to change the disabled & placeholder text below)

        this.props.setSearchType(str);
    }

    onFocus = () => {

    }

    handleOnSearch = (string, results) => {
        if (results !== undefined && results.length > 0) {
            this.setState({ inputValue: string, autocompletedItems: results });
        }
    };

    handleOnSelect = (e) => {
        this.setState({ inputValue: "", autocompletedItems: [] }, () => {
            if (e !== undefined) {
                this.props.onCustomerSearch(e);
            }
        });
    }

    componentDidUpdate() {
        document.querySelector(".search-container input").value = "";
    }

    render() {
        var searchStr = this.props.searchType;
        var customersRemapped = this.props.customers.map(customer => ({ id: customer.organizationId, name: customer.name, phone: customer.phone, organizationId: customer.organizationId }));

        return (
            <div className="search-container" style={{ marginBottom: 3 + 'px' }}>
                <span className="search-message" style={{ marginLeft: 10 + 'px' }}>
                    <select value={this.props.searchType} onChange={this.onSearchTypeChange} style={{ height: 30 + 'px', marginLeft: 1 + 'px', backgroundColor: "transparent", border: 0 + 'px', color: "#212529", paddingRight: 12 + 'px' }}>
                        <option key="1" id="1" value="name">Search Name &nbsp;</option>
                        <option key="2" id="2" value="phone">Search Phone # &nbsp;</option>
                        {/*<option key="3" id="3" value="email" disabled={!this.props.emailLoaded}>Search Email &nbsp;</option>
                        <option key="4" id="4" value="address" disabled={!this.props.addressLoaded} > Search Address &nbsp;</option>*/}
                    </select>
                </span>
                <div style={{ width: 200 + 'px', height: 30 + 'px', marginLeft: 10 + 'px', marginRight: 0 + 'px', border: '1px solid #ebeef0', borderRadius: '.3em' }}>
                    <span style={{ display: "inline-flex" }}><span style={{ width: 165 + 'px', margin: 0 + 'px', display: "inline-block" }}>
                        <ReactSearchAutocomplete
                        items={customersRemapped/*this.props.customers*/}
                        fuseOptions={{ threshold: "0.2", distance: "2", ignoreLocation: true, keys: [searchStr] }}
                        onSelect={this.handleOnSelect}
                        onFocus={this.onFocus}
                        onSearch={this.handleOnSearch}
                        inputSearchString={this.state.inputValue}
                        placeholder={"Search Customers"}
                        showIcon={false}
                        styling={{
                            height: 28 + 'px',
                            width: 150 + 'px',
                            border: 0 + 'px',
                            borderRadius: '.3em',
                            placeholderColor: "gray",
                            color: "black"
                        }}
                        />
                        </span>
                        <button style={{ backgroundColor: "white", height: 26 + 'px', border: 0 + 'px', verticalAlign: "top", float: "right" }} onClick={() => this.handleOnSelect(this.state.autocompletedItems[0])}> 
                            <Image src={require('../../img/search-icon.png')} style={{ width: 20 + 'px', height: 20 + 'px', display: "inline-block" }} />
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

SearchCustomer.defaultProps= {
        customers: [],
        customerName: null,
        onCustomerSearch: null,
        setCustomerList: [],
        searchType: null,
        setSearchType: null,
        emailLoaded: false,
        addressLoaded: false
};
export default SearchCustomer;