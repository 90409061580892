/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import "../../CSS/DataModal.css";
import { Spinner } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';

class ConfirmCancelModal extends Component {

    constructor(props) {
        super(props);

        this.onNameChange = this.onNameChange.bind(this);
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this);
        this.onConfirmCancel = this.onConfirmCancel.bind(this);
        this.getDevicesContent = this.getDevicesContent.bind(this);
        this.onDeleteChange = this.onDeleteChange.bind(this);
        this.state = {
            message: "",
            confirmCustomerName: "",
            confirmDeviceId: "",
            confirmDelete: "",
            disableCancelBtn: true
        }
    };

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onNameChange(e) {
        const name = e.target.value;
        let confirmDevId = this.props.deviceContracts.length ? this.props.deviceContracts[0].deviceId : "";

        this.setState({
            confirmCustomerName: name
        }, () => {
                let disableBtn = true;
                let msg = "";
                if (this.state.confirmCustomerName === this.props.customerName && this.state.confirmDeviceId === confirmDevId)
                    disableBtn = false;
                else
                    msg = "Customer Name and Confirmation must both match the info above";
                this.setState({
                    disableCancelBtn: disableBtn,
                    message: msg
                });
        });
    }

    onDeviceIdChange(e) {
        const devId = e.target.value;
        let confirmDevId = this.props.deviceContracts.length ? this.props.deviceContracts[0].deviceId : "";

        this.setState({
            confirmDeviceId: devId
        }, () => {
                let disableBtn = true;
                let msg = "";
                if (this.state.confirmCustomerName === this.props.customerName && this.state.confirmDeviceId === confirmDevId)
                    disableBtn = false;
                else 
                    msg = "Customer Name and Confirmation must both match the info above";
                this.setState({
                    disableCancelBtn: disableBtn,
                    message: msg
                });
        });
    }
    onDeleteChange(e) {
        const str = e.target.value;
        this.setState({
            confirmDelete: str
        }, () => {
            let disableBtn = true;
            let msg = "";
            if (this.state.confirmCustomerName === this.props.customerName && this.state.confirmDelete === "DELETE")
                disableBtn = false;
            else
                msg = "Customer Name and Confirmation must both match the info above";
            this.setState({
                disableCancelBtn: disableBtn,
                message: msg
            });
        });
    }
    onConfirmCancel() {
        this.setState({
            message: "",
            confirmCustomerName: "",
            confirmDeviceId: "",
            disableCancelBtn: true
        }, () => {
                this.props.onConfirmCancel();
        });
    }
    getDevicesContent(deviceContracts){
        let content = [];
        for (let device of deviceContracts) {
            content.push(<p key={device.deviceId} className="unselectable" style={{margin: '0.25em 0 0.5em 5px'}}>{device.deviceId}</p>);
        }
        return content;
    }

    render() {

        if (!this.props.show) {
            return null;
        } else {
            let cancelBtn = this.state.disableCancelBtn ?
                <Tooltip title="Customer name and Confirmation word DELETE must be inputted correctly to proceed" enterDelay={200}
                         leaveDelay={200} style={{backgroundColor: '#f7f9fa'}}>
                    <span>
                        <button type='button' className="red-btn btn-small" onClick={this.onConfirmCancel}
                            disabled={this.state.disableCancelBtn}>Cancel Subscription</button>
                    </span>
        </Tooltip>
                : <button type='button' className="red-btn btn-small" onClick={this.onConfirmCancel} disabled={this.state.disableCancelBtn}>Cancel Subscription</button>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Confirm Cancellation</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); }}>×</span>
                        </div>

                        <div className="modal-body">
                            <br />
                            <p style={{ padding: '0px 70px' }}>
                                To cancel the subscription, please type the customer name and device ID for the device to be deleted as they appear below:
                            </p>
                            <br />
                            <div className="alerts-tab-wrapper" style={{ margin: '0 30px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
                                <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                                    <div className="row no-gutters">
                                        <div className="col-4" style={{ textAlign: 'right', paddingRight: '15px' }}>
                                            <p className="semibold">Customer Name:</p>
                                        </div>
                                        <div className="col-8" style={{ textAlign: 'left' }}>
                                            <p className="unselectable" style={{ margin: '0.25em 0 0.5em 5px' }}>{this.props.customerName}</p>
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-4" style={{textAlign: 'right', paddingRight: '15px'}}>
                                            <p className="semibold"> Device ID:</p>
                                        </div>
                                        <div className="col-8" style={{textAlign: 'left'}}>
                                            {this.getDevicesContent(this.props.deviceContracts)}
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="alerts-tab-wrapper" style={{margin: '0 15px 0 30px' }}>
                                <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                                    <div className="row no-gutters">
                                        <div className="col-4" style={{ textAlign: 'right', paddingRight: '15px' }}>
                                            <p className="semibold">Customer Name:</p>
                                        </div>
                                        <div className="col-8">
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="customerName"
                                                value={this.state.confirmCustomerName}
                                                onChange={this.onNameChange}
                                                style={{ margin: '0 0 0.75em', height: '32px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row no-gutters">
                                        <div className="col-4" style={{ textAlign: 'right', paddingRight: '15px' }}>
                                            <p className="semibold">Type DELETE for confirm:</p>
                                        </div>
                                        <div className="col-8">
                                            <input 
                                                type="text"
                                                className="form-control"
                                                id="deviceId"
                                                value={this.state.confirmDelete}
                                                onChange={this.onDeleteChange}
                                                style={{ height: '32px' }}
                                            />
                                        </div>
                                    </div>
                                    
                                </div> 
                            </div>
                            <div>
                                <p style={{ color: 'red', margin: '10px' }}><small>{this.state.message}</small></p>
                            </div>
                            {/*<br />*/}
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); }}>Back</button>
                            {cancelBtn}
                        </div>
                    </div>
                </div>
            );
        }
    }
}


export default ConfirmCancelModal;