/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Form, FormGroup, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { PageView, Event } from "../GoogleAnalytics";
import forgotpasswordService from '../../services/forgotpassword.service';
import {withRouter} from "../Routing/withRouter";

class ChangePassword extends Component {
    static displayName = ChangePassword.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        //this.checkCommonPassword = this.checkCommonPassword.bind(this);

        this.state = {
            message: "",
            password: "",
            confirmPassword: "",
            passwordReset: false,
            btnClicked: false,
            loading: false,
            isPortalUser: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        //todo figure out way to check if user is portal or mobile

        this.setState({ isPortalUser: !this.props.isMobile });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangePassword(e) {
        const password = e.target.value;

        this.setState({ password: password });
    }

    onChangeConfirmPassword(e) {
        const confirmPassword = e.target.value;

        this.setState({ confirmPassword: confirmPassword });
    }

    onResetClick() {
        const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*]).+$");
        const pwd = this.state.password;
        let errorMsg = "New password must ";
        let valid = true;
        let numErrors = 0;

        if (pwd !== this.state.confirmPassword) {
            valid = false;
            errorMsg += "match confirm password"
            numErrors += 1;
        }
        //if (this.checkCommonPassword(pwd.toLowerCase())) {
        //    valid = false;
        //    if (numErrors > 0)
        //        errorMsg += ", ";
        //    errorMsg += "is too common";
        //    numErrors += 1;
        //}
        if (this.checkPersonalData(pwd.toLowerCase())) {
            valid = false;
            if (numErrors > 0)
                errorMsg += ", ";
            errorMsg += "cannot contain personal information";
            numErrors += 1;
        }
        if (pwd.length <= 7) {
            valid = false;
            if (numErrors > 0)
                errorMsg += ", ";
            errorMsg += "be 8+ characters";
            numErrors += 1;
        }
        if (!pattern.test(pwd)) {
            valid = false;
            if (numErrors > 0)
                errorMsg += ", and ";
            errorMsg += "contain at least 1 lowercase, uppercase, digit and special character";
            numErrors += 1;
        }
        errorMsg += ".";

        if (valid) {
            this.setState({ loading: true }, () => {
                var resetObj = {
                    email: this.props.registerUserId,
                    url: (this.props.changePasswordUri != null) ? this.props.changePasswordUri : "Test",
                    password: this.state.password
                };

                if (this.props.isAdminReset) {
                    forgotpasswordService.forcepasswordreset(resetObj).then(async response => {
                        if (this._isMounted) {
                            this.setState({ passwordReset: true, loading: false });
                        }
                    }).catch(e => {
                        //BadRequest (400) will be caught here.
                        console.log(e);
                        this.setState({ message: "Something went wrong, please try again.", loading: false });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 6000);
                    });
                }
                else {
                    forgotpasswordService.resetpassword(resetObj).then(async response => {
                        if (this._isMounted) {
                            this.setState({ passwordReset: true, loading: false });
                            Event("Password Changed", "User changed password", "Password Changed");
                        }
                    }).catch(e => {
                        //BadRequest (400) will be caught here.
                        console.log(e);
                        this.setState({ message: "Something went wrong, please try again.", loading: false });

                        setTimeout(() => {
                            this.setState({
                                message: ""
                            });
                        }, 6000);
                    });
                }

            });
        }
        else {
            this.setState({ message: errorMsg });

            setTimeout(() => {
                this.setState({
                    message: ""
                });
            }, 6000);
        }
    }

    checkPersonalData(pwd) {
        let isPersonal = false;
        let userEmail = this.props.registerUserId.split("@")[0];

        if (pwd.includes(this.props.registerUserId.toLowerCase()))
            isPersonal = true;
        if (pwd.includes(userEmail.toLowerCase()))
            isPersonal = true;
        /*if (pwd.includes("firstName")) 
            isPersonal = true;
        if (pwd.includes("lastName"))
            isPersonal = true;*/

        return isPersonal;
    }

    //checkCommonPassword(pwd) {
    //    let isCommon = false;

    //    fetch('10k-most-common.txt')
    //        .then(p => p.text())
    //        .then(text => {
    //            let passwords = text.split("\n");
    //            for (let i = 0; i < passwords.length; i++) {
    //                let str = passwords[i];
    //                if (pwd.includes(str))
    //                    isCommon = true;
    //            }
    //        });

    //    return isCommon;
    //}

    validatePassword() {
        return this.state.password.length > 0 && this.state.confirmPassword.length > 0;
    }

    render() {
        let successHeader = this.props.isNewUser ? "Password Set!" : "Password Changed!";
        let passwordMessage = this.props.isNewUser ? "Set Your Password" : "Change Your Password";
        let loader = this.state.loading ?
            <div className="loading" style={{ display: "block", marginLeft: '0px', paddingRight: '0px', width: '100%' }}>
                <div className="loading-wrapper loading-wrapper-login" style={{ display: "block", justifyContent: "center", alignItems: "center" }}>
                    <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                </div>
            </div>
            : null;

        let successButtonLabel = this.props.isAdminReset ? "Return" : "Go to Login";

        let successMessage = this.props.isNewUser ?
            <div>
                <div className="passwordBody">
                    Congratulations! You have successfully registered your account with Pro-IQ&reg; LifeWhere. Go to the Pro-IQ&reg; LifeWhere mobile application to log into your account.
                </div>
                <br />
            </div>
            : this.state.isPortalUser ?
            <div>
                <div className="passwordBody">
                    Your password has been changed successfully.
                </div>
                <br />
                <div className="centered">
                    <button onClick={this.props.endForgotPassword} className="btn-wide primary-btn btn-medium">{successButtonLabel}</button>
                </div>
            </div>
            : <div>
                <div className="passwordBody">
                    Your password has been changed successfully. Go back to the Pro-IQ&reg; LifeWhere mobile application to log into your account.
                </div>
                <br />
            </div>;

        let body = !this.state.passwordReset ?
            <div>
                <h5>{passwordMessage}</h5>
                <div className="passwordBody" style={{ paddingTop: '0px' }}>
                    Enter a new password below.
                </div>
                <form autoComplete="off">
                    <div style={{ padding: "15px 0px" }}>
                        <div className="row">
                            <div className="col">
                                <p className="formLbl" style={{ textAlign: 'left' }}>New Password</p>
                                <input 
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    value={this.state.password}
                                    onChange={this.onChangePassword}
                                    placeholder="Enter new password"
                                />
                                <div className="detailTitle" style={{ textAlign: 'left', paddingTop: '5px', fontSize: '70%' }}>
                                    8+ characters, at least 1 lowercase, uppercase, digit and special character (!@#$%^&*)
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p className="formLbl" style={{ textAlign: 'left' }}>Confirm Password</p>
                                <input 
                                    type="password"
                                    className="form-control"
                                    id="confirmPassword"
                                    value={this.state.confirmPassword}
                                    onChange={this.onChangeConfirmPassword}
                                    placeholder="Re-enter new password"
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <br />
                <div className="centered">
                    <input type="button" disabled={!this.validatePassword()} onClick={e => { this.onResetClick(e); }} value="Reset Password" className="btn-wide primary-btn btn-medium" />
                </div>
            </div>
            : <div>
                <h5>{successHeader}</h5>
                {successMessage}
            </div>;


        return (
            <div style={{ textAlign: 'center', backgroundColor: "#f7f9fa" }} className='passwordPage'>
                <div className="row centered">
                    <div className="password-container">
                        {body}
                    </div>
                </div>
                <div className="row centered">
                    <div style={{ width: '400px' }}>
                        <small>{this.state.message}</small>
                    </div>
                </div>
                {loader}
            </div>
        );
    }
}

export default withRouter(ChangePassword)