/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { PageView, ModalView, Event } from "../GoogleAnalytics";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Snackbar from '@mui/material/Snackbar';
import PartnerService from "../../services/partner.service";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import AddMultipleEmailAddresses from "../AccountManagement/Account/AddMultipleEmailAddresses";
import makeAnimated from "react-select/animated";
import moment from 'moment';

import PhoneNumberInput from "../Phone/PhoneNumberInput";
import {withRouter} from "../Routing/withRouter";

const jsPartner = {
    name: "",
    company: "",
    description: "",
    typeId: "",
    isBillable: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    notificationEmailAddresses: [],
    secretKey: "",
    website: "",
    contractType: "",
    firstBillingDate: "",
    complementaryUnits: ""
};

const animatedComponents = makeAnimated();

class AddPartner extends Component {
    static displayName = AddPartner.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.loadForm = this.loadForm.bind(this);
        this.handleCreatePartner = this.handleCreatePartner.bind(this);

        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.onChangeBilling = this.onChangeBilling.bind(this);
        this.onChangeCompany = this.onChangeCompany.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
        this.onChangeZip = this.onChangeZip.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangePhone = this.onChangePhone.bind(this);
        this.onChangeSecretKey = this.onChangeSecretKey.bind(this);
        this.onChangeWebsite = this.onChangeWebsite.bind(this);
        this.onChangeContractType = this.onChangeContractType.bind(this);
        this.onChangeFirstBillingDate = this.onChangeFirstBillingDate.bind(this);
        this.onChangeCompUnits = this.onChangeCompUnits.bind(this);

        this.onAddEmailClicked = this.onAddEmailClicked.bind(this);
        this.onDeleteEmailClicked = this.onDeleteEmailClicked.bind(this);
        this.onEmailChanged = this.onEmailChanged.bind(this);

        this.openCreatePartnerModal = this.openCreatePartnerModal.bind(this);
        this.showCreatePartnerModal = this.showCreatePartnerModal.bind(this);
        this.onCreatePartner = this.onCreatePartner.bind(this);
        this.setPartnerCallback = this.setPartnerCallback.bind(this);

        this.onSaveThenContinue = this.onSaveThenContinue.bind(this);
        this.onContinueWithoutSaving = this.onContinueWithoutSaving.bind(this);
        this.handleError = this.handleError.bind(this);
        
        this.state = {
            loading: false,
            message: "",
            modalMessage: "",
            modalTitle: "",
            newPartner: jsPartner,
            pilotNum: 0,
            validated: false,
            showMessage: false,
            showCreatePartnerConfirm: false,
            newPartnerList: [],
            billingDate: moment().date(1),
            unsavedChanges: false,
            showUnsavedModal: null,
            goNextPage: false,
            error: "",
            isUpdating: false
        }
    }

    getStorageValue(key, defaultValue, isJson = false) {
        const saved = localStorage.getItem(key);
        var value = saved !== null ? saved : defaultValue;
        if (isJson && value !== defaultValue && value !== "") {
            try {
                value = JSON.parse(value);
            }
            catch(e) {
                console.log(e);
                console.log(`error parsing value: ${value}`);
            }
        }
        return value;
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        let unsavedChanges = this.getStorageValue("unsavedChanges", false);
        if (unsavedChanges === 'true' || unsavedChanges === true) {
            let newPartner = this.getStorageValue("newPartner", jsPartner, true);
            let pilotNum = this.getStorageValue("pilotNum", 0);
            let validated = this.getStorageValue("validated", false) === 'true';
            let newPartnerList = this.getStorageValue("newPartnerList", [], true);
            this.setState({ 
                loading: false, newPartner: newPartner, validated: validated, 
                pilotNum: pilotNum, newPartnerList: newPartnerList 
            });
        }
        else 
            this.loadForm(true);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    handleError(error){
        this.setState({error});
    }
    loadForm(isInitial) {
        this.setState({ loading: false, newPartner: jsPartner, validated: false }, () => {
            let pilotNum = 0;
            if (isInitial) {
                let pilotPartners = this.props.partners !== undefined ? this.props.partners.filter(p => p.name.includes("Pilot Partner")) : [];
                pilotPartners.forEach(partner => {
                    var num = parseInt(partner.name.slice(partner.name.lastIndexOf(' ')));
                    if (num > pilotNum) {
                        pilotNum = num;
                    }
                });
                pilotNum = pilotNum + 1;
            }
            else {
                pilotNum = this.state.pilotNum + 1;
            }

            this.setState(function (prevState) {
                return {
                    loading: false,
                    pilotNum: pilotNum,
                    newPartner: {
                        ...prevState.newPartner,
                        name: "Pilot Partner " + pilotNum
                    }
                }
            });
        });
    }

    handleCreatePartner(e) {
        e.preventDefault();
        e.stopPropagation();
        const form = e.currentTarget;

        if (form.checkValidity() == true) {
            if (!this.state.newPartner.phone || this.state.error) return;
            this.setState({isUpdating: true},()=>{this.openCreatePartnerModal();});
        }

        this.setState({ validated: true, isUpdating: false });
    }

    openCreatePartnerModal() {
        this.setState({ modalTitle: "Create Partner", modalMessage: "Are you ready to create this Partner Account?" }, () => {
            this.showCreatePartnerModal();
        });
    }

    showCreatePartnerModal() {
        this.setState({ showCreatePartnerConfirm: !this.state.showCreatePartnerConfirm }, () => {
            if (this.state.showCreatePartnerConfirm)
                ModalView('createPartnerConfirm');
        });
    }

    onCreatePartner(goNextPage = false) {
        Event("Create Partner", "User created new partner account", "Create Partner");
        this.setState({ loading: true, showCreatePartnerConfirm: false, goNextPage: goNextPage }, async () => {
            await PartnerService.addnewpartner(this.state.newPartner)
                .then(response => {
                    if (this._isMounted) {
                        if (response == true)
                            this.setState({ showMessage: true, message: "Successfully created new partner account for " + this.state.newPartner.company }, () => {       
                                //this.loadForm(false);
                                let partnerObj = {
                                    organization: this.props.loggedInCustomerOrganization,
                                    isPartnerAdmin: this.props.isPartnerAdmin,
                                    isActive: true
                                }; 
                                PartnerService.get(partnerObj) //this.props.loggedInCustomerOrganization, this.props.isPartnerAdmin, true) 
                                    .then(response2 => { 
                                        let newPartnerList = response2.table;
                                        var currentPartner = newPartnerList.find(p => p.name == this.state.newPartner.name);
                                        
                                        this.setState({ newPartnerList: newPartnerList }, () => {
                                            if (currentPartner !== null && currentPartner !== undefined)
                                                PartnerService.adddeletedinfrastructure(currentPartner.organizationId, this.state.newPartner.company)
                                                    .then(response3 => {
                                                        if (response3 == false)
                                                            console.log("Error Creating Deleted Infrastructure");
                                                        
                                                        if (this._isMounted)
                                                            this.setState({ loading: false, message: "Successfully created partner infrastructure" });
                                                    });
                                        });

                                        localStorage.setItem("unsavedChanges", false);
                                        
                                        if (goNextPage) {
                                            this.onContinueWithoutSaving();
                                        }
                                        else {
                                            this.props.setPartnerList(newPartnerList);
                                            this.props.setCurrentPartnerId(currentPartner.organizationId, this.setPartnerCallback); 
                                        }
                                        //this.props.switchToNewPartner(newPartnerList, currentPartner); 
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            });
                        else 
                            this.setState({ loading: false, showMessage: true, message: "Failed to create new partner account" });

                        /*setTimeout(() => {
                            this.setState({
                                message: "",
                                showMessage: false
                            });
                        }, 3000);*/
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        })
    }

    setPartnerCallback = () => {
        if(this.state.goNextPage) {
            let nextPath = this.getStorageValue("nextPath", null);

            this.props.navigate(nextPath);
        }
        else {
            this.props.setPartnerList(this.state.newPartnerList);
            this.props.setActivePath("/accountManagement");
            this.props.navigate("/accountManagement");
            window.location.reload(true);
        }
    }

    onSaveThenContinue() {
        this.setState({ loading: true, showUnsavedModal: false, validated: true }, () => {
            localStorage.setItem("showUnsavedModal", false);
            let newPartner = this.getStorageValue("newPartner", jsPartner, true);
            this.setState({ newPartner: newPartner }, () => {
                let validated = this.checkValidity(this.state.newPartner);
                if (validated)
                    this.onCreatePartner(true);
                else {
                    this.setState({ validated: true, loading: false });
                }
            })
        })
    }

    onContinueWithoutSaving() {
        localStorage.setItem("unsavedChanges", false);
        localStorage.setItem("showUnsavedModal", false);
        localStorage.setItem("newPartner", jsPartner);
        localStorage.setItem("pilotNum", 0);
        localStorage.setItem("validated", false);
        localStorage.setItem("newPartnerList", []);
        localStorage.setItem("billingDate", "");

        this.props.onContinueFromAddPartner(this.state.newPartnerList, this.setPartnerCallback);
    }

    onChangeName(e) {
        const newName = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    name: newName
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeType(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    typeId: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeCompany(e) {
        const newCompany = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    company: newCompany
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeDescription(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    description: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeAddress(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    address: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeCity(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    city: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeState(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    state: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeZip(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    zip: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeCountry(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    country: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangePhone(e) {
        const newValue = e;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    phone: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeSecretKey(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    secretKey: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeWebsite(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    website: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeBilling(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    isBillable: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeContractType(e) {
        const newValue = e.target.value;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    contractType: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onChangeFirstBillingDate(e) {
        var newDate = e.target.value;
        var dateArr = newDate.split('-');
        dateArr[2] = "01";
        var newDateStr = dateArr.join('-');
        var newValue = moment(newDateStr).format("MM/DD/YYYY");
        console.log("newDate = " + newDate + ", newDateStr = " + newDateStr + ", newValue:");
        console.log(newValue);

        this.setState({ billingDate: newDateStr }, async () => {
            this.setState(function (prevState) {
                return {
                    newPartner: {
                        ...prevState.newPartner,
                        firstBillingDate: newValue
                    }
                };
            }, () => {
                localStorage.setItem("unsavedChanges", true);
                localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
                localStorage.setItem("billingDate", this.state.billingDate);
            });
        })
    }

    onChangeCompUnits(e) {
        const newValue = e.target.value;
        if (parseInt(newValue) > 10)
            newValue = 10;
        else if (parseInt(newValue) < 0)
            newValue = 0;

        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    complementaryUnits: newValue
                }
            };
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    onAddEmailClicked = () => {
        this.state.newPartner.notificationEmailAddresses.push("");
        this.updateNotificationEmailAddresses(this.state.newPartner.notificationEmailAddresses);
    }

    onDeleteEmailClicked = (position) => {
        this.state.newPartner.notificationEmailAddresses.splice(position, 1);
        this.updateNotificationEmailAddresses(this.state.newPartner.notificationEmailAddresses);
    }

    onEmailChanged = (e, value, position) => {
        let newEmailAddresses = this.state.newPartner.notificationEmailAddresses.slice();
        newEmailAddresses[position] = value;
        this.updateNotificationEmailAddresses(newEmailAddresses);
    }

    updateNotificationEmailAddresses(emailAddresses) {
        this.setState(function (prevState) {
            return {
                newPartner: {
                    ...prevState.newPartner,
                    notificationEmailAddresses: emailAddresses
                }
            };
        });
    }

    async cleanseEmailAddresses() {
        if (this.state.currentPartner.notificationEmailAddresses.length > 0) {
            const cleansedEmails = [];

            this.state.currentPartner.notificationEmailAddresses.forEach(email => {
                if (email !== "") {
                    cleansedEmails.push(email.trim());
                }
            });

            this.updateNotificationEmailAddresses(cleansedEmails);
        }
    }

    onChangeEmail(e) {
        // todo
        const newEmails = e.target.value;
        const emailArray = [];
        var isValid = true;

        this.setState(function (prevState) {
            return {
                validEmails: isValid,
                emails: newEmails,
                newPartner: {
                    ...prevState.newPartner,
                    notificationEmailAddresses: emailArray
                }
            }
        }, () => {
            localStorage.setItem("unsavedChanges", true);
            localStorage.setItem("newPartner", JSON.stringify(this.state.newPartner));
        });
    }

    checkValidity(newPartner) {
        let isValid = true;
        if (newPartner.name == null || newPartner.name.trim() == "")
            isValid = false;
        if (newPartner.typeId === null || newPartner.typeId.trim() === "" || newPartner.typeId === "-1")
            isValid = false;

        return isValid;
    }

    onAddEmail() {

    }

    renderForm() {
        return (
            <div style={{ margin: '15px 30px', padding: '15px 25px 20px', border: '2px solid #ebeef0', borderRadius: '.3rem' }}>
                <Form noValidate validated={this.state.validated} onSubmit={this.handleCreatePartner}>
                    <Row>
                        <Form.Group as={Col} md="6" controlId="orgName">
                            <Form.Label>Organization Name</Form.Label>
                            <Form.Control required type="text" placeholder="Pilot Partner 1" value={this.state.newPartner.name} onChange={this.onChangeName} />
                            <Form.Control.Feedback type="invalid">Organization Name is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="orgType">
                            <Form.Label>Organization Type</Form.Label>
                            <Form.Control required as="select" value={this.state.newPartner.typeId} onChange={this.onChangeType} isInvalid={(this.state.validated && this.state.newPartner.typeId == "") ? true : false}>
                                <option value="" disabled defaultValue hidden>Select Organization Type...</option>
                                <option value="1">Trial</option>
                                <option value="2">Test</option>
                                <option value="3">Production</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Organization Type is required</Form.Control.Feedback>
                        </Form.Group>
                        {/*<Form.Group as={Col} md="3" controlId="billingStatus">
                            <Form.Label>Billing Status</Form.Label>
                            <Form.Control required as="select" value={this.state.newPartner.isBillable} onChange={this.onChangeBilling} isInvalid={(this.state.validated && this.state.newPartner.isBillable == "") ? true : false}>
                                <option value="" disabled defaultValue hidden>Select Billing Status...</option>
                                <option value="1">Billable</option>
                                <option value="0">Not Billable</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Billing is required</Form.Control.Feedback>
                        </Form.Group>*/}
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="6" controlId="companyName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control required type="text" placeholder="ex: Resideo" value={this.state.newPartner.company} onChange={this.onChangeCompany} />
                            <Form.Control.Feedback type="invalid">Company name is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="companyDescription">
                            <Form.Label>Company Description</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Company Description" value={this.state.newPartner.description} onChange={this.onChangeDescription} />
                            <Form.Control.Feedback type="invalid">Company Description is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="12" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control required type="text" placeholder="1234 Main St." value={this.state.newPartner.address} onChange={this.onChangeAddress} />
                            <Form.Control.Feedback type="invalid">Address is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="7" controlId="city">
                            <Form.Label>City</Form.Label>
                            <Form.Control required type="text" placeholder="City" value={this.state.newPartner.city} onChange={this.onChangeCity} />
                            <Form.Control.Feedback type="invalid">City is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="state">
                            <Form.Label>State</Form.Label>
                            <Form.Control required type="text" placeholder="State" value={this.state.newPartner.state} onChange={this.onChangeState} />
                            <Form.Control.Feedback type="invalid">State is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="zip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control required type="text" placeholder="Zip" value={this.state.newPartner.zip} onChange={this.onChangeZip} />
                            <Form.Control.Feedback type="invalid">Zip is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="7" controlId="country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" value={this.state.newPartner.country} onChange={this.onChangeCountry} />
                        </Form.Group>
                        <Form.Group as={Col} md="5" controlId="phone">
                            <PhoneNumberInput
                                phoneNumber={this.state.newPartner.phone}
                                setPhoneNumber={this.onChangePhone}
                                onError={this.handleError}
                            />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="6" controlId="website">
                            <Form.Label>Website</Form.Label>
                            <Form.Control type="text" placeholder="www.company.com" value={this.state.newPartner.website} onChange={this.onChangeWebsite} />
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="secretKey">
                            <Form.Label>Secret Key</Form.Label>
                            <Form.Control required type="text" placeholder="Secret key" value={this.state.newPartner.secretKey} onChange={this.onChangeSecretKey} />
                            <Form.Text muted>This is the password used for the Lifewhere Mobile app to connect to Twin Thread.  This is often a variation of the Partner name that can be remembered until reset later.</Form.Text>
                            <Form.Control.Feedback type="invalid">Secret Key is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} md="3" controlId="billingStatus">
                            <Form.Label>Billing Status</Form.Label>
                            <Form.Control required as="select" value={this.state.newPartner.isBillable} onChange={this.onChangeBilling} isInvalid={(this.state.validated && this.state.newPartner.isBillable == "") ? true : false}>
                                <option value="" disabled  hidden>Select Billing Status...</option>
                                <option value="1">Billable</option>
                                <option value="0" defaultValue>Not Billable</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Billing is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="contractType">
                            <Form.Label>Contract Type</Form.Label>
                            <Form.Control required={this.state.newPartner.isBillable === "1" ? true : false} disabled={this.state.newPartner.isBillable === "1" ? false : true} as="select" value={this.state.newPartner.contractType} onChange={this.onChangeContractType} isInvalid={(this.state.validated && this.state.newPartner.contractType == "" && this.state.newPartner.isBillable === "1") ? true : false}>
                                <option value="" disabled  hidden>Select Contract Type...</option>
                                <option value="Monitoring" defaultValue>Monitoring</option>
                                <option value="Service">Service</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Contract Type is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="firstBillingDate">
                            <Form.Label>First Billable Date</Form.Label>
                            <Form.Control required={this.state.newPartner.isBillable === "1" ? true : false} disabled={this.state.newPartner.isBillable === "1" ? false : true} type="date" value={this.state.billingDate} onChange={this.onChangeFirstBillingDate} isInvalid={(this.state.validated && this.state.newPartner.firstBillingDate == "" && this.state.newPartner.isBillable === "1") ? true : false} />
                            <Form.Text muted>This must be the 1st of the month.</Form.Text>
                            <Form.Control.Feedback type="invalid">First Billable Date is required</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="3" controlId="compUnits">
                            <Form.Label>Complementary Units</Form.Label>
                            <Form.Control required={this.state.newPartner.isBillable === "1" ? true : false} disabled={this.state.newPartner.isBillable === "1" ? false : true} type="number" min="0" max="10" placeholder="0" value={this.state.newPartner.complementaryUnits} onChange={this.onChangeCompUnits} isInvalid={(this.state.validated && this.state.newPartner.complementaryUnits == "" && this.state.newPartner.isBillable === "1") ? true : false} />
                            <Form.Control.Feedback type="invalid">Complementary Unit count between 0 and 10 is required</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <Form.Label>System Notification Emails</Form.Label>
                            {this.state.newPartner.notificationEmailAddresses.length ?
                                null
                                : <Form.Text muted>
                                    These are used to notify the designated individuals of updates to our system. Select <b className="sb">Add Email Address</b> to configure.
                                </Form.Text>
                            }
                            <AddMultipleEmailAddresses existingEmailAddresses={this.state.newPartner.notificationEmailAddresses} onAddEmailClicked={this.onAddEmailClicked}
                                onDeleteEmailClicked={this.onDeleteEmailClicked} onEmailChanged={this.onEmailChanged} showMessage={false} />
                        </Col>
                    </Row>

                    <Button 
                        type="submit" 
                        style={{ backgroundColor: '#0033a0', borderColor: '#0033a0' }}
                        disabled={!!this.state.error || !this.state.newPartner.phone || this.state.isUpdating}
                    >{this.state.isUpdating ? 'Creating Partner...' : 'Create Partner'}</Button>
                </Form>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let loader = this.state.loading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                marginLeft: '225px',
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                    width: `calc(100% + 450px)`,
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : null;
        let contents = this.renderForm();

        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="infoPage">
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Add Partner
                    </h2>

                    {contents}
                    {loader}

                    <ConfirmationModal show={this.state.showCreatePartnerConfirm} onYes={this.onCreatePartner} onClick={this.showCreatePartnerModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>
                    
                    <ConfirmationModal 
                        show={this.state.showUnsavedModal == null ? this.getStorageValue("showUnsavedModal", false) === 'true' : this.state.showUnsavedModal} 
                        onYes={this.onSaveThenContinue} 
                        onClick={this.onContinueWithoutSaving} 
                        message={"You have unsaved changes, would like to save before leaving?"} 
                        title={"Unsaved Changes"}></ConfirmationModal>

                    <Snackbar open={this.state.showMessage} message={this.state.message} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} />
                </div>
            </div>
        );
    }
}

export default withRouter(AddPartner);