/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Event } from "../GoogleAnalytics";
import DragIcon from '@mui/icons-material/Reorder';
import "../../CSS/DataModal.css";

class SensorMapModal extends React.Component {

    constructor(props) {
        super(props);

        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            loading: false
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
    };

    onCancelClick() {
        this.props.onClick();
    };

    onUpdateClick = () => {
        this.props.updateSensorMap();
    }

    onDragStart = (evt) => {
        let element = evt.currentTarget;
        element.classList.add("dragged");
        evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
        evt.dataTransfer.effectAllowed = "move";
    };

    onDragEnd = (evt) => {
        evt.currentTarget.classList.remove("dragged");
    };

    onDragEnter = (evt) => {
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.add("dragged-over");
        evt.dataTransfer.dropEffect = "move";
    };

    onDragLeave = (evt) => {
        let currentTarget = evt.currentTarget;
        let newTarget = evt.relatedTarget;
        if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
            return;
        evt.preventDefault();
        let element = evt.currentTarget;
        element.classList.remove("dragged-over");
    };

    onDragOver = (evt) => {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
        this.setState({ selectedPropertyIndex: evt.currentTarget.id });
    };

    onDrop = (evt, value, propertyIndex) => {
        Event("User dragged sensor to property", "User dragged a sensor onto a property in repair sensor map modal", "User dragged sensor to prperty");
        evt.preventDefault();
        evt.currentTarget.classList.remove("dragged-over");
        let dragIndex = parseInt(evt.dataTransfer.getData("text/plain"));

        this.props.mapSensor(propertyIndex, dragIndex);
    };

    getPropName(name) {
        if (name !== null && name !== undefined && name.includes(".")) {
            name = name.split('.')[1];
            name = name.split('-')[0];
        }
        return name;
    }

    getPropertyMapped(propertyIndex, propName) {
        if (this.props.mapDict[propertyIndex] !== -1) {
            let propSensor = this.props.sensorList[this.props.mapDict[propertyIndex]];
            return (
                <div className="col-7" style={{ textAlign: 'right', padding: '8px 10px' }}>
                    {propSensor.name}
                </div>
            );
        }
        else {
            let mappedSensor = null;
            let propertyName = this.getPropName(propName);
            if (this.props.mappedSensors !== null && this.props.mappedSensors !== undefined && this.props.mappedSensors.length) {
                let mappedIndex = this.props.mappedSensors.findIndex(m => m.mappedTo.includes(propertyName));
                if (mappedIndex !== -1) {
                    mappedSensor = this.props.mappedSensors[mappedIndex];
                }
            }
            return (
                <div className="col-7" style={{ textAlign: 'right', padding: '8px 10px', color: '#c4c4c4' }}>
                    {mappedSensor !== null ? mappedSensor.name : "Unmapped"}
                </div>
            )
        }
        
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        else {
            let sensorContent = this.props.sensorList !== null && this.props.sensorList !== undefined && this.props.sensorList.length ?
                this.props.sensorList.map((item, index) =>
                    <div className={Object.values(this.props.mapDict).includes(index) ? "row no-gutters sensor-selected sensor-map-item" : "row no-gutters sensor-map-item"} 
                        key={`sensor-${index}`} id={index} draggable
                        onDragStart={(e) => this.onDragStart(e)}
                        onDragEnd={(e) => this.onDragEnd(e)}
                    >
                        <div className="col-11" style={{ textAlign: 'left', fontWeight: '500', borderRight: '1px solid #c4c4c4', padding: '8px 0px 8px 10px' }}>
                            {item.name}
                        </div>
                        <div className="col-1" style={{ paddingLeft: '5px' }}>
                            <DragIcon style={{ width: '40px', height: '40px', padding: '3px', color: '#c4c4c4' }} />
                        </div>
                    </div>
                )
                : <div className="row no-gutters sensor-map-item" key={`sensor-new`} id="-1">
                    <div className="col-11" style={{ textAlign: 'left', fontWeight: '500', borderRight: '1px solid #c4c4c4', padding: '8px 0px 8px 10px' }}>
                        {this.props.loading ? "Fetching sensors..." : "No Unmapped Sensors"}
                    </div>
                    <div className="col-1" style={{ paddingLeft: '5px' }}>
                        <DragIcon style={{ width: '40px', height: '40px', padding: '3px', color: '#c4c4c4' }} />
                    </div>
                </div>;

            let propContent = this.props.unmappedProperties !== null && this.props.unmappedProperties !== undefined && this.props.unmappedProperties.length ?
                this.props.unmappedProperties.map((item, index) =>
                    <div
                        className={this.props.mapDict[index] !== -1 ? "row no-gutters sensor-selected sensor-map-item" : "row no-gutters sensor-map-item"}
                        key={`property-${index}`} id={index}
                        onDragLeave={(e) => this.onDragLeave(e)}
                        onDragEnter={(e) => this.onDragEnter(e)}
                        onDragEnd={(e) => this.onDragEnd(e)}
                        onDragOver={(e) => this.onDragOver(e)}
                        onDrop={(e) => this.onDrop(e, false, index)}
                    >
                        <div className="col-5" style={{ textAlign: 'left', fontWeight: '500', padding: '8px 0px 8px 10px' }}>
                            {this.getPropName(item.name)}
                        </div>
                        {this.getPropertyMapped(index, item.name)}
                    </div>
                )
                : <div className="row no-gutters sensor-map-item">
                    <div className="col-12" style={{ textAlign: 'left', fontWeight: '500', padding: '8px 10px' }}>
                        No Unmapped Properties
                    </div>
                </div>;

            let loader = this.props.loading ?
                <div className="loading" style={{ display: "block", marginLeft: '0', width: '100%' }}>
                    <div className="loading-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="loading-body"><Spinner animation="border" variant="light" /></div>
                    </div></div>
                : <div>{null}</div>;

            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper wide-modal"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>

                        <div className="modal-header">
                            <h3>Repair Sensor Map</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ backgroundColor: 'whitesmoke' }}>

                            <p>Drag a sensor from the left to the property on the right it should be mapped to.</p>

                            <div className="row">
                                <div className="col-6" style={{ padding: '10px' }}>
                                    <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #c4c4c4', borderRadius: '0.4rem', height: '100%', maxHeight: '500px' }}>
                                        <h6 style={{ color: 'dimgray', padding: '5px' }}>UNMAPPED SENSORS</h6>
                                        <div style={{ border: '1px solid #c4c4c4', borderRadius: '0.4rem', backgroundColor: 'whitesmoke', height: 'calc(100% - 38px)', maxHeight: '462px', overflow: 'scroll' }}>
                                            {sensorContent}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6" style={{ padding: '10px' }}>
                                    <div style={{ padding: '10px', backgroundColor: 'white', border: '1px solid #c4c4c4', borderRadius: '0.4rem', height: '100%', maxHeight: '500px' }}>
                                        <h6 style={{ color: 'dimgray', padding: '5px' }}>PROPERTIES</h6>
                                        <div style={{ border: '1px solid #c4c4c4', borderRadius: '0.4rem', backgroundColor: 'whitesmoke', height: 'calc(100% - 38px)', maxHeight: '462px', overflow: 'scroll' }}>
                                            {propContent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            <button className="primary-btn btn-small" onClick={e => { this.onUpdateClick(e); }} disabled={false /*todo*/}>Update Mapping</button>

                        </div>
                        { loader } 
                    </div>
                </div>
            );
        }
    }
}

export default SensorMapModal;