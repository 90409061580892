import React, { createContext, useState, useEffect } from 'react';
import FleetService from "../../services/fleet.service";
import {Timing} from "../GoogleAnalytics";

export const FleetStateContext = createContext([]);

function FleetStateProvider ({ children, currentPartnerId }) {

    const [isLoadingData, setIsLoadingData] = useState(false);
    const [partnerFleetDataList, setPartnerFleetDataList] = useState([]);
    const [isShowAll, setIsShowAll] = useState(false);
    
    useEffect(() => {
        if (currentPartnerId !== "-1") {
            if (isShowAll) {
                getAll().then()
            } else {
                getPartnerFleet().then();
            }    
        }
    }, [currentPartnerId, isShowAll]);
    
    const getPartnerFleet = async () => {
        setIsLoadingData(true);

        const startTime = performance.now();

        await FleetService.getpartnerfleet(currentPartnerId)
            .then(response => {
                if (response !== undefined && response !== null) {
                    setPartnerFleetDataList(response);
                }

                const elapsedTime = performance.now() - startTime;
                Timing("Partner Fleet Loading", "loading", elapsedTime, "Partner Fleet Loading");

                setIsLoadingData(false);
            })
            .catch(e => {
                setIsLoadingData(false);
                console.log(e);
            });
    }

    const getAll = async () => {
        setIsLoadingData(true);

        const startTime = performance.now();

        await FleetService.getAll()
            .then(response => {
                if (response !== undefined && response !== null) {
                    setPartnerFleetDataList(response);
                }

                const elapsedTime = performance.now() - startTime;
                Timing("All Fleet Loading", "loading", elapsedTime, "All Fleet Loading");

                setIsLoadingData(false);
            })
            .catch(e => {
                setIsLoadingData(false);
                console.log(e);
            });
    }

    return (
        <FleetStateContext.Provider value={{ partnerFleetDataList, isLoadingData, isShowAll, setIsShowAll }}>
            {children}
        </FleetStateContext.Provider>
    );
}

export default FleetStateProvider;
