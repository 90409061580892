/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material/styles";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const container = document.getElementById('root');
const root = createRoot(container);
const theme = createTheme();
root.render(
  <BrowserRouter basename={baseUrl}>
      <ThemeProvider theme={theme}>
          <App />
      </ThemeProvider>
  </BrowserRouter>);

