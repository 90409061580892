/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from '../../services/account.service';
import AlertService from '../../services/alerts.service';
import PartnerService from '../../services/partner.service';
import CommentsService from '../../services/comments.service';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import BaseAlertPage from '../LWTriageAlerts/BaseAlertPage';
import FullScreenChartModal from '../LWTriageAlerts/FullScreenChartModal';
import AlertEditModal from '../LWTriageAlerts/AlertEditModal';
import CreateJobModal from '../LWTriageAlerts/CreateJobModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import memoize from 'memoize-one';
import moment from 'moment';
import { PageView, Event, Timing } from "../GoogleAnalytics";

//import { jsAsset, alertCustomer, jsAlert, jsGraph, jsOpportunity, jsUpdateOpportunity, jsGraphData } from "../../componentObjects";

import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import TriageAlertsTable from '../TriageAlerts/TriageAlertsTable';
import { AlertTypeIdsEnum, TagIdsEnum } from '../../componentObjects';
import alerttypemessagingService from "../../services/alerttypemessaging.service";

class AlertQuestions extends Component {
    static displayName = AlertQuestions.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getAlerts = this.getAlerts.bind(this);
        this.getUpdatedAlerts = this.getUpdatedAlerts.bind(this);
        this.changeRadio = this.changeRadio.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.filterAlerts = this.filterAlerts.bind(this);
        this.consolidateAlerts = this.consolidateAlerts.bind(this);
        this.addConversationToAlert = this.addConversationToAlert.bind(this);
        this.updateAlertList = this.updateAlertList.bind(this);

        this.state = {
            alertArgs: {
                queryId: "custom",
                startTime: null,
                endTime: null,
                isActive: true,
                priority: 3
            },
            alertType: "Active",
            alertList: [],
            loading: true,
            alertTableLoading: false,
            alertMessaging: [],
            assetComments: [],
            partnerInfo: {},
            tableObj: {},
            startTabIndex: 0,
            consolidateAlertsDict: {}
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading) {
            //this.setState({ loading: true }, async () => {
            //    this.getAlerts();
            //});
            this.getAlerts();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentCustomerId !== this.props.currentCustomerId) {
            if (!this.props.isLoading) {
                this.getAlerts();
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    memAlerts = memoize(this.getAlerts);

    async getAlerts(id, start = null, end = null) {
        var startTime = performance.now();
        var now = (end == null ? moment() : end);
        var before = (start == null ? moment(now).add(-2, 'years') : start);//moment(now).add(-2, 'weeks'); //two weeks ago; set only for demo set 04/01/21
        var nowStr = now.format();
        var beforeStr = before.format();
        var startTabIndex = 0;
        var alertList = [];

        var alertArgs = {
            alertTagIds: [], //[TagIdsEnum.QuestionAsked],
            alertTypeIds: null,
            startTime: beforeStr,
            endTime: nowStr,
            isClosed: false,
            isActive: true,
            isMuted: false,
            parentAssetId: null
        }

        this.setState(prevState => ({
            alertTableLoading: true,
            alertArgs: {
                ...prevState.alertArgs,
                startTime: beforeStr,
                endTime: nowStr
            }
        }), async () => {
            await CommentsService.getassetcomments()
                .then(response3 => {
                    if (this._isMounted) {
                        let assetComments = response3.data;
                        if (assetComments !== null && assetComments !== undefined && assetComments.length) {
                            assetComments = assetComments.filter(c => c.hasQuestion);
                            console.log("assetComments:");
                            console.log(assetComments);
                        }
                        this.setState({ assetComments: assetComments }, () => {
                            if (assetComments !== null && assetComments !== undefined && assetComments.length) {

                                    alerttypemessagingService.getalertmessaging()
                                        .then(response2 => {
                                            if (this._isMounted) {
                                                this.setState({ alertMessaging: response2 }, async () => {
                                                    if (this._isMounted) {
                                                        let questionArgs = {
                                                            assetComments: assetComments,
                                                            args: this.state.alertArgs
                                                        }; 
                                                        await AlertService.getquestionalertlist(questionArgs) 
                                                            .then(response => {
                                                                if (this._isMounted) {
                                                                    var tempData = [];
                                                                    for (let x in response.data) {
                                                                        if (response.data.hasOwnProperty(x)) {
                                                                            var alert = response.data[x];
                                                                            var tempObject = {
                                                                                alertId: alert.alertId,
                                                                                typeId: alert.typeId,
                                                                                typeName: alert.type,
                                                                                alertMessage: alert.message,
                                                                                severity: "Critical",
                                                                                alert: alert
                                                                            }
                                                                            tempData.push(tempObject);
                                                                        }
                                                                    };
                                                                    var tempTableObj = {
                                                                        alerts: response.data,
                                                                        equipmentAlertsList: tempData,
                                                                        equipmentCount: tempData.length
                                                                    };

                                                                    this.setState({ tableObj: tempTableObj, loading: false, alertTableLoading: false, startTabIndex: 0 }, async () => {
                                                                        this.consolidateAlerts(tempData);
                                                                    });
                                                                }
                                                            })
                                                            .catch(e => {
                                                                console.log(e);
                                                                this.setState({ alertTableLoading: false, loading: false });
                                                            });
                                                    }

                                                });
                                            }
                                                
                                        });
                            }
                            else {
                                this.setState({ alertTableLoading: false, loading: false });
                            }
                        });
                    }

                })
                .catch(e => {
                    console.log(e);
                    this.setState({ alertTableLoading: false, loading: false });
                })
        });
    }

    getUpdatedAlerts(comments) {
        this.setState({ loading: true, alertTableLoading: true, assetComments: comments }, async () => {
            if (this._isMounted) {
                let questionArgs = {
                    assetComments: comments,
                    args: this.state.alertArgs
                }; 
                await AlertService.getquestionalertlist(questionArgs) 
                    .then(response => {
                        if (this._isMounted) {
                            var tempData = [];
                            for (let x in response.data) {
                                if (response.data.hasOwnProperty(x)) {
                                    var alert = response.data[x];
                                    var tempObject = {
                                        alertId: alert.alertId,
                                        typeId: alert.typeId,
                                        typeName: alert.type,
                                        alertMessage: alert.message,
                                        severity: "Critical",
                                        alert: alert
                                    }
                                    tempData.push(tempObject);
                                }
                            };
                            var tempTableObj = {
                                alerts: response.data,
                                equipmentAlertsList: tempData,
                                equipmentCount: tempData.length
                            };

                            this.setState({ tableObj: tempTableObj, loading: false, alertTableLoading: false, startTabIndex: 0 }, async () => {
                                this.consolidateAlerts(tempData);
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.setState({ alertTableLoading: false, loading: false });
                    });
            }
        })
    }

    consolidateAlerts(alerts) {
        let distinctAlerts = [];
        let collapseAlerts = [];
        let consolidateAlertsDict = {};
        let collapseUnitAlertsDict = {}; // {{ assetId: assetId used in consolidateDict }}
        let isDevAlerts = this.state.tabIndex === 1;

        for (let i = 0; i < alerts.length; i++) {
            let currAlert = alerts[i];

            if (!distinctAlerts.some(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId)) {
                var assetTypeAlerts = alerts.filter(a => a.typeId == currAlert.typeId && a.alert.assetId == currAlert.alert.assetId);
                var alertConversationId = Math.max(...assetTypeAlerts.map(o => o.alert.conversationId));
                var alertCommentCount = assetTypeAlerts.find(a => a.alert.conversationId == alertConversationId);

                assetTypeAlerts.sort((a, b) => {
                    const startA = new Date(a.alert.startTime);
                    const startB = new Date(b.alert.startTime);
                    return startB - startA;
                });

                var distinctAlert = [...assetTypeAlerts][0];
                let distinctUnitId = distinctAlert.alert.pathNames !== null && distinctAlert.alert.pathNames.length > 2 ? distinctAlert.alert.pathNames[2].split('-')[0] : null;

                if (isDevAlerts && distinctAlert.typeId == AlertTypeIdsEnum.UnitOffline && distinctUnitId !== null && distinctAlerts.some(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[2].split('-')[0] == distinctUnitId)) {
                    let prevDistinctAlert = distinctAlerts.find(d => d.typeId == distinctAlert.typeId && d.alert.pathNames.length > 2 && d.alert.pathNames[2].split('-')[0] == distinctUnitId);
                    let prevUnitAlerts = consolidateAlertsDict[distinctAlert.typeId][prevDistinctAlert.alert.assetId];

                    let unitAlerts = prevUnitAlerts.concat(assetTypeAlerts);

                    collapseAlerts = collapseAlerts.concat(distinctAlert);
                    collapseUnitAlertsDict[distinctAlert.alert.assetId] = prevDistinctAlert.alert.assetId;
                    consolidateAlertsDict[prevDistinctAlert.typeId][prevDistinctAlert.alert.assetId] = unitAlerts;
                }
                else {
                    if (alertConversationId != 0) {
                        distinctAlert.alert.conversationId = alertConversationId.toString();
                        distinctAlert.commentsCount = alertCommentCount.alert.conversationItemCount;
                        distinctAlert.alert.conversationItemCount = alertCommentCount.alert.conversationItemCount;
                    }
                    distinctAlerts = distinctAlerts.concat(distinctAlert);

                    if (consolidateAlertsDict[currAlert.typeId] === undefined) {
                        consolidateAlertsDict[currAlert.typeId] = {};
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                    else {
                        consolidateAlertsDict[currAlert.typeId][currAlert.alert.assetId] = assetTypeAlerts;
                    }
                }
            }
        }

        this.setState({ alertList: distinctAlerts, consolidateAlertsDict: consolidateAlertsDict, alertTableLoading: false });
    }

    async filterAlerts(tabIndex, radio, isActive, isScheduled, selectedTypes) {
        if (this.state.tableObj.equipmentAlertsList !== null && this.state.tableObj.equipmentAlertsList !== undefined)
            this.setState({ alertTableLoading: true }, () => {
                let alerts = tabIndex == 0 ? this.state.tableObj.equipmentAlertsList : this.state.tableObj.deviceAlertsList;

                if (radio === "Critical")
                    alerts = alerts.filter(a => a.severity === "Critical");
                else if (radio === "Warning")
                    alerts = alerts.filter(a => a.severity === "Warning");

                if (isActive !== null) {
                    if (isActive == true)
                        alerts = alerts.filter(a => a.alert.isActive == true);
                    else
                        alerts = alerts.filter(a => a.alert.isActive == false);
                }

                if (isScheduled !== null) {
                    if (isScheduled == true)
                        alerts = alerts.filter(a => a.alert.isMuted == true);
                    else
                        alerts = alerts.filter(a => a.alert.isMuted == false);
                }

                this.consolidateAlerts(alerts); //this.setState({ alertList: alerts, alertTableLoading: false });  
            });
    }

    reloadTable() {
        this.setState({
            alertTableLoading: true,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: this.state.alertType === "Inactive" ? false : true
            },
            alertTableLoading: true,
            alertList: []
        }), async () => {
            await this.getAlerts();
        });
    }

    async changeRadio(val) {
        let x = val;
        Event("Alert Questions Radio Clicked", "User clicked one of the radio buttons for the alert table in Alert Questions", "Alert Questions Radio Clicked");

        this.setState({
            alertType: x,
            alertList: []
        });

        this.setState(prevState => ({
            alertArgs: {
                ...prevState.alertArgs,
                isActive: x === "Inactive" ? false : true
            }
        }));

        this.setState({ alertTableLoading: true }, async () => {
            await this.getAlerts();
        });
    }

    addConversationToAlert(conversationId, alertId) {
        var index = 0;
        var alert = null;

        index = this.state.tableObj.alerts.findIndex(a => {
            return a.alertId === alertId;
        });

        var tempTableObj = [...this.state.tableObj.alerts];
        alert = { ...tempTableObj[index] };
        alert.conversationId = conversationId.toString();
        tempTableObj[index] = alert;

        this.setState(prevState => ({
            tableObj: {
                ...prevState.tableObj,
                alerts: tempTableObj
            }
        }));
    }

    updateAlertList(alertsTable) {
        this.setState({ alertList: alertsTable });
    }

    render() {
        const { classes } = this.props;
        return (
            <div sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="infoPage">
                    <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                        Alert Questions
                    </h2>

                    <TriageAlertsTable alertList={this.state.alertList} startTabIndex={this.state.startTabIndex} _isMounted={this._isMounted} loading={this.state.loading} alertTableLoading={this.state.alertTableLoading}
                        alertMessaging={this.state.alertMessaging} assetComments={this.state.assetComments} tableObj={this.state.tableObj}
                        filterAlerts={this.filterAlerts} reloadTable={this.reloadTable} customers={this.props.customers} consolidateAlertsDict={this.state.consolidateAlertsDict} addConversationToAlert={this.addConversationToAlert} userOrg={this.props.userOrg} updateAlertList={this.updateAlertList}
                        setActivePath={this.props.setActivePath} source="AlertQuestions" updateSearch={this.getAlerts} getUpdatedAlerts={this.getUpdatedAlerts}/>

                </div>
            </div>
        );
    }
}

export default AlertQuestions;