/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner, Image } from 'react-bootstrap';
import { PageView, Event } from "../GoogleAnalytics";
import DatePicker from 'react-datepicker';
import InstallCheck from "../Devices/InstallationCheck";
import InstallService from "../../services/install.service";
import AlertService from "../../services/alerts.service";
import LongArrowUpIcon from '@mui/icons-material/ArrowUpward';
import LongArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from "moment/moment";

import { roles, alertCustomer } from "../../componentObjects";
import { withRouter } from '../Routing/withRouter';

const currentDate = new Date();

class InstallReport extends Component {
    static displayName = "Install Report";
    static defaultSortCol = "DATE";
    _isMounted = false;

    constructor(props) {
        super(props);

        this.populateData = this.populateData.bind(this);
        this.changeTableRadio = this.changeTableRadio.bind(this);
        this.onColumnClick = this.onColumnClick.bind(this);
        this.columnSort = this.columnSort.bind(this);
        this.onInstallClick = this.onInstallClick.bind(this);
        this.cacheInstallCheck = this.cacheInstallCheck.bind(this);
        this.clearAvgCurrentListObject = this.clearAvgCurrentListObject.bind(this);

        this.state = {
            loading: false,
            installLoading: false,
            customerLoading: false,
            currentRadio: "NewInstalls",
            monthlyInstallsObj: {},
            installsList: [],
            repairsList: [],
            inProgressList: [],
            tableList: [],

            columnSort: {
                enabled: true,
                type: this.defaultSortCol,
                reverse: false
            },

            dateSelected: currentDate,
            selected: 0,
            selectedDeviceName: "",
            currentCustomer: alertCustomer,
            currentPartnerName: "",
            cachedResults: {}
        }
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (
            this.props.location !== undefined && 
            this.props.location !== null && 
            this.props.location.state !== undefined && 
            this.props.location.state !== null && 
            this.props.location.state.currentRadio === "InProgress") {
            this.setState({
                currentRadio: "InProgress"
            }, () => this.populateData(this.state.dateSelected));

            this.props.history.replace({
                ...this.props.location,
                state: undefined,
            });
        }
        else {
            this.populateData(this.state.dateSelected);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPartnerId !== this.props.currentPartnerId) {
            this.populateData(this.state.dateSelected);
        }
    }

    changeTableRadio(e) {
        let x = e.target.value;

        this.setState({
            currentRadio: x,
            selected: -1,
            columnAltered: false,
            columnSort: {
                enabled: false,
                type: null,
                reverse: false
            },
            tableList: x === "NewInstalls" ? this.state.installsList : x === "Repairs" ? this.state.repairsList : this.state.inProgressList
        });
    }

    populateData(date) {
        if (!this.props.isLoading) {
            this.setState({ loading: true, selected: -1, selectedDeviceName: ""  }, () => {
                let yearSelected = date.getFullYear();
                let monthSelected = date.getMonth() + 1;
                var partnerId = this.props.currentPartnerId;
                InstallService.getmonthlypartnerinstalls(monthSelected, yearSelected, partnerId).then(response => {
                    if (this._isMounted) {
                        let allInstalls = response.data;
                        let installsList = allInstalls.filter(i => i.installTypeId === 1);
                        let repairsList = allInstalls.filter(i => i.installTypeId !== 1);
                        let inProgressList = allInstalls.filter(i => i.installStatusId === 1);

                        this.setState({
                            monthlyInstallsObj: response.data,
                            installsList: installsList,
                            repairsList: repairsList,
                            inProgressList: inProgressList,
                            tableList: this.state.currentRadio === "NewInstalls" ? installsList : this.state.currentRadio === "Repairs" ? repairsList : inProgressList,
                            loading: false 
                        });
                    }
                    else {
                        this.setState({
                            monthlyInstallsObj: {},
                            installsList: [],
                            repairsList: [],
                            inProgressList: [],
                            tableList: [],
                            loading: false
                         });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
            })
        }
    }

    onColumnClick(selectedCol) {
        const colSort = this.state.columnSort;
        let newSort = colSort;
        let data = this.state.monthlyInstallsObj;
        let colData = [];
        let colAltered = false;

        if (colSort.enabled) {
            if (colSort.type == selectedCol) {
                if (colSort.reverse) {
                    newSort = {
                        enabled: false,
                        type: null,
                        reverse: false
                    };
                    colData = this.columnSort(data, "DATE", false); /* default sort by is start time */
                    colAltered = false;
                }
                else {
                    newSort.reverse = true;
                    colData = this.columnSort(data, selectedCol, true);
                    colAltered = true;
                }
            }
            else {
                newSort = {
                    enabled: true,
                    type: selectedCol,
                    reverse: false
                };
                colData = this.columnSort(data, selectedCol, false);
                colAltered = true;
            }
        }
        else {
            newSort = {
                enabled: true,
                type: selectedCol,
                reverse: false
            };
            colData = this.columnSort(data, selectedCol, false);
            colAltered = true;
        }

        this.setState({ columnSort: newSort, tableList: colData }, () => {
            this.setState({ columnAltered: colAltered });
        });
    }

    columnSort(dataList, col, isReverse) {
        let colData = [];
        let data = dataList;

        if (col === "TECHNICIAN") {
            colData = data.sort((a, b) => {
                const nameA = a.technicianName || '';
                const nameB = b.technicianName || '';

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DEVICE") {
            colData = data.sort((a, b) => {
                const nameA = a.macId || '';
                const nameB = b.macId || '';

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "GATEWAY_TYPE") {
            colData = data.sort((a, b) => {
                const nameA = a.gatewayType || '';
                const nameB = b.gatewayType || '';

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "INSTALL_TYPE") {
            colData = data.sort((a, b) => {
                const nameA = a.installType || '';
                const nameB = b.installType || '';

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "INSTALL_STATUS") {
            colData = data.sort((a, b) => {
                const nameA = a.installStatus || '';
                const nameB = b.installStatus || '';

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "BILLING_PLAN") {
            colData = data.sort((a, b) => {
                const nameA = a.billingPlan || '';
                const nameB = b.billingPlan || '';

                return nameA.localeCompare(nameB);
            });
        }
        else if (col === "DATE") {
            colData = data.sort((a, b) => {
                const dateA = new Date(a.startDateTime);
                const dateB = new Date(b.startDateTime);
                return dateB - dateA;
            });
        }
        else if (col === "TOTAL_TIME") {
            colData = data.sort((a, b) => {
                const nameA = a.totalTime || '';
                const nameB = b.totalTime || '';

                return nameA.localeCompare(nameB);
            });
        }

        if (isReverse) {
            colData = colData.reverse();
        }

        return colData;
    }

    clearAvgCurrentListObject() {
        return {
            avgMainCurrent: [],
            avgInducerCurrent: [],
            avgBlowerCurrent: []
        }
    }

    onInstallClick(index, deviceName) {
        Event("Install Clicked", "User clicked an install in Installs Check", "Installs Check Install Clicked");
        let x = index;
        let install = this.state.tableList?.[x];
        
        if (this.state.selected === x) {
            this.setState({ selected: -1, currentCustomer: alertCustomer, currentPartnerName: "", customerLoading: false });
        } else {
            this.setState({
                selected: x,
                selectedDeviceName: deviceName,
                customerLoading: true
            }, async () => {
                let cachedResult = this.state.cachedResults?.[install?.macId?.toUpperCase()] || null;
                
                if (cachedResult?.customerName?.length > 0) {
                    this.setState({
                        currentCustomer: { name: cachedResult?.customerName },
                        customerLoading: false
                    });
                } else {
                    await AlertService.get(install.customerOrgId)
                        .then(response => {
                            if (this._isMounted)
                                this.setState({
                                    currentCustomer: response.data
                                }, () => {
                                    this.getCustomerPartner(this.state.currentCustomer.parentOrganizationId);
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }
            });
        }
    }

    getCustomerPartner(id) {
        const x = this.props.partners.find(p => p.organizationId === id);
        let partnerStr = "";

        if (x !== undefined && x != null && x.company !== null) {
            partnerStr = x.company + " (" + x.name + ")";
        }
        else {
            partnerStr = "Name unavailable for PartnerID  " + id; /* todo fetch info? */
        }

        this.setState({ currentPartnerName: partnerStr, customerLoading: false });
    }

    iconFunction(columnName, columnSort) {
        let arrowIcon = columnSort.enabled ?
            (columnSort.reverse ?
                <LongArrowDownIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />
                : <LongArrowUpIcon style={{ height: '16px', width: '15px', paddingBottom: '2px' }} />)
            : null;

        let icon = columnSort.enabled && columnSort.type === columnName ?
            <span style={{ height: '15px', paddingLeft: '3px' }}>
                {arrowIcon}
            </span>
            : null;

        return icon;
    }

    renderDatePicker() {

        const handleChange = async date => {
            this.setState({ dateSelected: date, loading: true });
            await this.populateData(date);
        };

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ marginLeft: '10px' }}>Data From:</div>
                <DatePicker
                    customInput={<input className="txt-detail" style={{ minWidth: '200px', width: '15%', textAlign: "center" }} />}
                    selected={this.state.dateSelected}
                    onChange={handleChange}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    popperPlacement="auto"
                    popperProps={{
                        positionFixed: true
                    }}
                />
            </div>
        );
    }

    getExpandContent(index, deviceName) {
        let install = this.state.tableList?.[index];
        let cachedResult = this.state.cachedResults?.[install?.macId?.toUpperCase()] || null;
        
        let customerInfo = this.state.customerLoading ?
            null
            : <p style={{paddingLeft: '25px', marginBottom: '10px'}}><span
                className='semibold'>Customer:</span> &nbsp; {cachedResult?.customerName?.length > 0 ? cachedResult?.customerName : this.state.currentCustomer.name}</p>;

        return (
            <tr className="no-hover expanded" key={`expandedContent-${index}`}
                style={{backgroundColor: 'white !important'}}>
                <td colSpan={this.state.currentRadio === "NewInstalls" ? 11 : 12} style={{color: 'unset'}}>
                    {customerInfo}
                    <InstallCheck currentCustomerId={install?.customerOrgId} roles={this.props.roles}
                                  isLoading={this.state.installLoading} source="InstallReport"
                                  currentInstall={install} currentRadio={this.state.currentRadio}
                                  cachedResult={cachedResult}
                                  cacheInstallCheck={this.cacheInstallCheck} />
                </td>
            </tr>
        );
    }
    
    cacheInstallCheck(installCheckObject) {
        installCheckObject.customerName = this.state.currentCustomer?.name?.trim();
        
        this.setState((prevState) => ({
            cachedResults: {
                ...prevState.cachedResults,
                [installCheckObject.macId]: installCheckObject
            },
        }));
    }

    getReplaced(currentRadio, item) {
        if (currentRadio === "NewInstalls") {
            return (null);
        }
        else {
            return (
                <td>{item.replacedMacId}</td>
            )
        }
    }

    renderTable() {
        let summaryData = this.state.tableList;//this.state.monthlyInstallsObj;

        let summaryContent = summaryData.length ?
            <tbody>
            {summaryData.map((item, index) =>
                [<tr
                    className={this.state.selected == index ? "alerts-table-selected alerts-table-row" : "alerts-table-row"}
                    key={item.id} data={index} value={index}
                    onClick={() => this.onInstallClick(index, item.macId)}>
                    <td style={{padding: '16px 0px 16px 10px', borderLeft: '1px solid #ebeef0'}}>
                        {item.technicianName}
                    </td>
                    <td>{item.macId}</td>
                    {this.getReplaced(this.state.currentRadio, item)}
                    <td>{item.gatewayType}</td>
                        <td>{item.installType}</td>
                        <td>{item.installStatus}</td>
                        <td>{item.billingPlan}</td>
                        <td>{moment.utc(item.startDateTime).local().format("MM/DD/YYYY hh:mm a")}</td>
                        <td>{item.totalTime}</td>
                        <td className="alerts-td-right">
                            {this.state.selected == index ?
                                <ArrowUpIcon className="db-blue-reverse" style={{borderRadius: '12px'}}/> :
                                <ArrowDownIcon className="db-blue db-blue-bg" style={{borderRadius: '12px'}}/>}
                        </td>
                    </tr>,
                        this.state.selected == index && (this.getExpandContent(index, item.macId))
                    ])}
            </tbody>
            : <tbody>
                <tr>
                    <td>{""}</td>
                </tr>
            </tbody>;


        let replaceHeader = this.state.currentRadio === "NewInstalls" ?
            null
            : <th>
                <div onClick={() => this.onColumnClick("REPLACE")}>
                    <span style={{ cursor: 'pointer' }}>REPLACED ID</span>
                    {this.iconFunction("REPLACE", this.state.columnSort)}
                </div>
            </th>;

        return (
            <div style={{ margin: '20px 30px' }}>

                <div>
                    <table className="table-hover" style={{ width: '100%' }}>

                        <thead>
                        <tr className="alerts-table-row">
                            <th style={{padding: '0px 0px 0px 10px'}}>
                                <div onClick={() => this.onColumnClick("TECHNICIAN")}>
                                    <span style={{cursor: 'pointer'}}>TECHNICIAN</span>
                                    {this.iconFunction("TECHNICIAN", this.state.columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => this.onColumnClick("DEVICE")}>
                                    <span style={{cursor: 'pointer'}}>MAC ID</span>
                                    {this.iconFunction("DEVICE", this.state.columnSort)}
                                </div>
                            </th>
                            {replaceHeader}
                            <th>
                                <div onClick={() => this.onColumnClick("GATEWAY_TYPE")}>
                                    <span style={{cursor: 'pointer'}}>GATEWAY TYPE</span>
                                    {this.iconFunction("GATEWAY_TYPE", this.state.columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => this.onColumnClick("INSTALL_TYPE")}>
                                    <span style={{cursor: 'pointer'}}>INSTALL TYPE</span>
                                    {this.iconFunction("INSTALL_TYPE", this.state.columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => this.onColumnClick("INSTALL_STATUS")}>
                                    <span style={{cursor: 'pointer'}}>INSTALL STATUS</span>
                                    {this.iconFunction("INSTALL_STATUS", this.state.columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => this.onColumnClick("BILLING_PLAN")}>
                                    <span style={{cursor: 'pointer'}}>BILLING PLAN</span>
                                    {this.iconFunction("BILLING_PLAN", this.state.columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => this.onColumnClick("DATE")}>
                                    <span style={{cursor: 'pointer'}}>DATE</span>
                                    {this.iconFunction("DATE", this.state.columnSort)}
                                </div>
                            </th>
                            <th>
                                <div onClick={() => this.onColumnClick("TOTAL_TIME")}>
                                    <span style={{cursor: 'pointer'}}>TOTAL TIME</span>
                                    {this.iconFunction("TOTAL_TIME", this.state.columnSort)}
                                </div>
                            </th>
                            <th className="table-shrink"
                                style={{textAlign: 'center', padding: '10px 10px 10px 5px'}}></th>
                        </tr>
                        </thead>
                        {summaryContent}
                    </table>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        var isPartnerAdmin = this.props.roles.includes(roles.partnerAdmin);

        let tableContents = this.state.loading ?
            <div className={["loading"].join(' ')} style={{ display: "block" }} sx={(theme)=>({
                [theme.breakpoints.down('md')]: {
                    marginLeft: '-225px !important',
                },
            })}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : this.renderTable();

        let datePicker = this.renderDatePicker();

        let tableOptions = <div className="alerts-tab-wrapper" style={{ margin: '20px 30px 0px', backgroundColor: "#f7f9fa", padding: '10px 0' }}>
            <div className="alerts-tab-label" style={{ paddingLeft: '45px' }}>Table Options</div>
            <div style={{ paddingLeft: '40px', margin: '5px' }}>
                <div style={{ height: '24px', display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    <div>
                        <input type="radio" value="NewInstalls" checked={this.state.currentRadio === 'NewInstalls'} name="installTableType" onChange={e => this.changeTableRadio(e)} />
                            &nbsp; New Installs &nbsp;&nbsp;
                        <input type="radio" value="Repairs" checked={this.state.currentRadio === 'Repairs'} name="installTableType" onChange={e => this.changeTableRadio(e)} />
                            &nbsp; Repairs &nbsp;&nbsp;
                        <input type="radio" value="InProgress" checked={this.state.currentRadio === 'InProgress'} name="installTableType" onChange={e => this.changeTableRadio(e)} />
                            &nbsp; In Progress &nbsp;&nbsp;
                    </div>
                    <div style={{ display: "flex" }}>
                        {datePicker}
                    </div>
                    <div style={{ display: "flex" }}>
                        {/*<button className="secondary-btn btn-small" onClick={this.prevPage} disabled={this.state.pageNum == 1 ? true : false} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Prev Page</button>
                        <p style={{ padding: '0px 10px', marginTop: "7px", marginBottom: "7px" }}>
                            Page {this.state.pageNum} of {pages}
                        </p>
                        <button className="secondary-btn btn-small" onClick={this.nextPage} disabled={this.state.pageNum == pages ? true : false} style={{ minWidth: '50px', height: '38px', borderRadius: '.25rem', padding: '0px 10px', marginLeft: '12px' }}>Next Page</button>*/}
                    </div>
                </div>
            </div >
        </div >;

        return (

            <div>
                <div >
                    <div className='infoPage'>
                        <h2 className="pageTitle" id="tabelLabel" style={{ borderBottom: 'none', padding: '20px 0px 0px 30px' }}>
                            Install Report
                        </h2>
                        {tableOptions}
                        { tableContents }
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(InstallReport);
