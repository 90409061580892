/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "../../CSS/DataModal.css";
import "react-datepicker/dist/react-datepicker.css";

const verificationOptions = [
    {
        name: "Unverified",
        value: null,
        disabled: true
    },
    {
        name: "Verified",
        value: false,
        disabled: false
    },
    {
        name: "False Positive",
        value: true,
        disabled: false
    }
];

const animatedComponents = makeAnimated();

class UpdateJobModal extends Component {
    constructor(props) {
        super(props);

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTechnician = this.onChangeTechnician.bind(this);
        this.onChangeVerification = this.onChangeVerification.bind(this);
        this.onChangeJobImpact = this.onChangeJobImpact.bind(this);
        this.onChangeNotes = this.onChangeNotes.bind(this);
        this.onUpdate = this.onUpdate.bind(this);

        this.state = {
            selectedDate: null, 
            selectedDateStr: "", 
            selectedTechnician: null, 
            selectedVerificiation: null, 
            jobImpact: this.props.currentJobDetails.impactEstimate,
            notes: this.props.currentJobDetails.comment
        }
    }

    onClick = e => {
        this.props.onClick && this.props.onClick(e);
        this.props.onCancelClick();
    };

    onCancelClick() {
        this.props.onCancelClick();  
    };

    onChangeDate(e) {
        var date = moment(e).toDate();
        var dateStr = moment(e).format();
        this.setState({
            selectedDate: date,
            selectedDateStr: dateStr
        }, () => {
                this.props.onChangeDate(dateStr);
        });
    }
    onChangeTechnician = (selectedTech) => {
        this.setState({ selectedTechnician: selectedTech }, () => {
            this.props.onChangeTechnician(selectedTech);
        });
    }

    onChangeVerification = (selectedVer) => {
        this.setState({ selectedVerificiation: selectedVer }, () => {
            this.props.onChangeVerification(selectedVer);
        });
    }

    onChangeJobImpact(e) {
        const impact = e.target.value;

        this.props.onChangeJobValue(impact);
    }

    onChangeNotes(e) {
        const notes = e.target.value;

        this.props.onChangeNotes(notes);
    }

    async onUpdate() {
        this.setState({
            selectedDate: null,
            selectedDateStr: "",
            selectedTechnician: null,
            selectedVerificiation: null,
            jobImpact: null,
            notes: ""
        }, () => {
                this.props.onUpdate();
        });
    }

    getOptionLabel = (option) => {
        return (option.lastName + ", " + option.firstName + " (" + option.userId + ")");
    }

    getOptionValue = (option) => {
        return (option.userId);
    }

    getVerOptionLabel = (option) => {
        return (option.name);
    }

    getVerOptionValue = (option) => {
        return (option.value);
    }

    getDate() {
        var date = this.state.selectedDate == null ?  this.props.scheduledDate : this.state.selectedDate;
            return date;
    }

    

    getTechnician() {
        let selectedTech;
        if (this.state.selectedTechnician == null) {
            if (this.props.selectedTechnician?.userId == null) {
                return "";
            }
            else {
                return this.props.technicianList?.find(x => x.userId == this.props.selectedTechnician?.userId);
            }
        }
        else {
            return this.state.selectedTechnician;
        }

    }

    getVerification() {
        let selectedVer = this.state.selectedVerificiation != null ? this.state.selectedVerificiation : (this.props.currentJobDetails.isFalsePositive == null ? verificationOptions[0] : (this.props.currentJobDetails.isFalsePositive ? verificationOptions[2] : verificationOptions[1]));
        return selectedVer;
    }

    render() {
        const { classes } = this.props;

        if (!this.props.show) {
            return null;
        }
        else {
            return (
                <div className="modal" style={{ display: this.props.show ? "block" : "none" }}>
                    <div className="modal-wrapper"
                        style={{
                            transform: this.props.show ? 'translateY(0vh)' : 'translateY(-100vh)',
                            opacity: this.props.show ? '1' : '0'
                        }}>
                        <div className="modal-header">
                            <h3>Schedule Job: {this.props.currentJob.subject}</h3>
                            <span className="close-modal-btn" onClick={e => { this.onClick(e); this.onCancelClick(); }}>×</span>
                        </div>

                        <div className="modal-body" style={{ textAlign: "left" }}>
                            <div className="col-6 offset-3">
                                <form>
                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Schedule For</label>
                                        <DatePicker
                                            customInput={<input className="form-control" style={{ padding: '2px 8px' }} />}
                                            id="scheduleDate"
                                            name="scheduleDate"
                                            selected={this.getDate()}
                                            onChange={date => this.onChangeDate(date)}
                                            dateFormat="MM/dd/yyyy hh:mm:ss aa"
                                            timeIntervals={15}
                                            showTimeSelect
                                            placeholderText="--Select Date/Time--"
                                            disabled={(this.props.currentJob.status == "Closed")? true :  this.props.isJobClose? false : (this.props.currentPartnerSTDetails?.betaST == true )? true : false }
                                        />
                                    </div>

                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Assign To</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999 }) }}
                                            menuPortalTarget={document.body}
                                            components={animatedComponents}
                                            options={this.props.technicianList}
                                            getOptionLabel={this.getOptionLabel}
                                            getOptionValue={this.getOptionValue}
                                            value={this.getTechnician()}
                                            onChange={this.onChangeTechnician}
                                            placeholder="--Select Technician--"
                                            isDisabled={ this.props.currentJob.status == "Closed"? true : this.props.isJobClose? false : (this.props.currentPartnerSTDetails?.betaST == true )? true : false }
                                        />
                                    </div>

                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Verification</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999 }) }}
                                            menuPortalTarget={document.body}
                                            components={animatedComponents}
                                            options={verificationOptions}
                                            getOptionLabel={this.getVerOptionLabel}
                                            getOptionValue={this.getVerOptionValue}
                                            value={this.getVerification()}
                                            onChange={this.onChangeVerification}
                                            isOptionDisabled={(option) => option.disabled}
                                          
                                        />
                                    </div>

                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Job Impact</label>
                                        <input 
                                            type="number"
                                            className="form-control"
                                            style={{ padding: '2px 8px' }}
                                            id="jobValue"
                                            name="jobValue"
                                            placeholder="Dollars($)"
                                            value={this.props.currentJobDetails.impactEstimate == null ? "" : String(this.props.currentJobDetails.impactEstimate)}
                                            onChange={this.onChangeJobImpact}
                                        />
                                    </div>

                                    <div style={{ paddingRight: 2 + "px" }}>
                                        <label htmlFor="description">Notes</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            style={{ padding: '2px 8px' }}
                                            id="notes"
                                            name="notes"
                                            value={this.props.currentJobDetails.comment == null ? "" : this.props.currentJobDetails.comment}
                                            onChange={this.onChangeNotes}
                                        />
                                    </div>

                                    <br />
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="secondary-btn btn-small" onClick={e => { this.onClick(e); this.onCancelClick(); }}>Cancel</button>
                            {this.props.isJobClose ? < button className="primary-btn btn-small" onClick={this.onUpdate}>Close</button> : < button className="primary-btn btn-small" onClick={this.onUpdate}>Update</button>}
                        </div>
                    </div>
                </div>
            );
        }
    }

}

export default UpdateJobModal;