/*
* Resideo/LifeWhere
* Copyright (C) 2018-2023 Resideo/LifeWhere
* mailto:nathan.williams@resideo.com
*/

import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import AccountService from '../../../services/account.service';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import UserEditModal from './UserEditModal';
import InviteUserModal from './InviteUserModal';
import ForgotPasswordModal from '../../Account/ForgotPasswordModal';
import HelpModal from '../../Provisioning/HelpModal'
import { PageView, Event, Timing, ModalView } from "../../GoogleAnalytics";

import { jsPartner } from "../../../componentObjects";

import memoize from 'memoize-one';
import { withStyles } from '@mui/styles';

import * as Roles from '../Users/Roles';

const styles = theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
        },
    },
    loader: {
        marginLeft: '225px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '-225px !important',
            width: `calc(100% + 450px)`,
        },
    }
});

const ota = {
    role: "OTA",
    roleId: "11"
}

const api = {
    role: "API",
    roleId: "3"
}


class Users extends Component {
    static displayName = Users.name;
    _isMounted = false;

    constructor(props) {
        super(props);

        this.getAccount = this.getAccount.bind(this);

        this.onDelete = this.onDelete.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.openDeleteUserModal = this.openDeleteUserModal.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);

        this.onEdit = this.onEdit.bind(this);
        this.editUser = this.editUser.bind(this);
        this.openEditUserModal = this.openEditUserModal.bind(this);
        this.showEditModal = this.showEditModal.bind(this);

        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onRoleChange = this.onRoleChange.bind(this);

        this.onInvite = this.onInvite.bind(this);
        this.openInviteUserModal = this.openInviteUserModal.bind(this);
        this.showInviteUserModal = this.showInviteUserModal.bind(this);

        this.openInviteErrorModal = this.openInviteErrorModal.bind(this);
        this.showInviteErrorModal = this.showInviteErrorModal.bind(this);

        this.showResetPasswordModal = this.showResetPasswordModal.bind(this);

        this.state = {
            currentAccount: {
                parentAccountId: null,
            },
            currentPartner: jsPartner,
            roleList: [],
            currentUser: {
                userId: null,
                firstName: null,
                lastName: null,
                email: null,
                isActive: null,
                lastLogin: null,
                roleList: null,
                roleListId: [],
                roleListIds: null,
                roleListIdStr: null
            },
            inviteUser: {
                firstName: null,
                lastName: null,
                email: null,
                roleList: null,
                roleListId: [],
                roleListIdStr: null
            },
            newUsers: [
                {
                    firstName: null,
                    lastName: null,
                    email: null,
                    rolelist: null,
                    roleListId: [],
                    roleListIdStr: null
                }
            ],
            loading: false,
            inviteLoading: false,
            modalMessage: "",
            modalTitle: "",
            message: "",
            emailToReset: "",
            showResetPassword: false,
            showDeleteConfirm: false,
            showEditConfirm: false,
            showInviteConfirm: false,
            showInviteErrorConfirm: false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            isAdmin: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        PageView();

        if (!this.props.isLoading)
            this.getAccount(this.props.currentPartnerId);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPartnerId !== this.props.currentPartnerId) {
            this.getAccount(this.props.currentPartnerId);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    memUsers = memoize(this.getAccount);

    onEdit() {
        this.editUser();
        this.setState({ showEditConfirm: !this.state.showEditConfirm });
    }

    editUser() {
        AccountService.updateuser(this.state.currentPartner.organizationId, this.state.currentUser)
            .then(response => {
                if (response.data === true) {
                    this.setState({
                        message: "The user was edited successfully!"
                    });

                    Event("Partner User Edit", "User edited partner user", "Partner User Edited Successfully");
                }
                else {
                    this.setState({
                        message: "Failed to edit the user!"
                    });
                }

                if (this.state.loading === false) {
                    this.setState({
                        loading: true
                    }, () => {
                        this.getAccount(this.props.currentPartnerId);
                    });
                }

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    openEditUserModal(user) {
        this.setState({ currentUser: user }, () => {
            if (this.state.currentUser.roleListIds !== "") {
                let str = this.state.currentUser.roleListIds;
                let roleArray = this.state.currentUser.roleListIds.split(", ");
                //let userRoles = [];

                this.setState(prevState => ({
                    currentUser: {
                        ...prevState.currentUser,
                        roleListId: roleArray,
                        roleListIdStr: str
                    }
                }));
            }

            this.showEditModal(null);
        });
    }

    showResetPasswordModal = (emailAddress) => {
        let emailToReset = "";
        if (!this.state.showResetPassword) {
            emailToReset = emailAddress;
        }

        this.setState({
            showResetPassword: !this.state.showResetPassword, emailToReset: emailToReset
        }, () => {
            if (this.state.showResetPassword)
                ModalView("/resetPassword");
        });
        window.scrollTo(0, 0);
    }

    showEditModal() {
        this.setState({
            showEditConfirm: !this.state.showEditConfirm
        }, () => {
                if (this.state.showEditConfirm)
                    ModalView("editUser");
        });
        window.scrollTo(0, 0);
    }

    onInvite(users) {
        var apiCheck = true;
        var apiRoleId = '3';
        this.setState({ inviteLoading: true }, () => {
            AccountService.getpartnerinfo(this.state.currentPartner.organizationId, true)
                .then(response => {
                    if (response.data.users != null && response.data.users.length > 0) {
                        let existingUsers = response.data.users;
                        // go through each user and see if they match a user in the system already
                        for (let i = 0; i < users.length; ++i) {
                            let currInviteUser = users[i];
                            for (let j = 0; j < existingUsers.length; ++j) {
                                // if there is a match, then check to see if they have 3 which is api in roleListId
                                if (currInviteUser.email.toUpperCase() === existingUsers[j].email.toUpperCase()) {
                                    if (existingUsers[j].roleListIds != null && existingUsers[j].roleListIds.includes(apiRoleId)) {
                                        // if so then we have an error
                                        apiCheck = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                    if (apiCheck) {
                        AccountService.inviteusersloop(this.state.currentPartner.name, this.state.currentPartner.organizationId, users)
                            .then(response => {
                                if (response.data.length > 0) {
                                    this.setState(prevState => ({
                                        currentPartner: {
                                            ...prevState.currentPartner,
                                            users: response.data
                                        }
                                    }));
                                    this.setState({
                                        message: "The new user invites were sent successfully!", showInviteConfirm: !this.state.showInviteConfirm, inviteLoading: false
                                    });
                                    Event("Partner User Invite", "User clicked invite button", "Invite User Clicked");
                                }
                                else {
                                    this.setState({
                                        message: "Failed to send invites to the new users!", showInviteConfirm: !this.state.showInviteConfirm, inviteLoading: false
                                    });
                                }

                                setTimeout(() => {
                                    this.setState({
                                        message: ""
                                    });
                                }, 3000);
                            })
                    } else {
                        this.setState({ modalMessage: `This user email is already being used for the mobile application. Please try another email.`, modalTitle: "Mobile User" }, () => {
                            this.showInviteErrorModal();
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        });
    }

    openInviteUserModal(user) {
        this.showInviteUserModal();
    }

    showInviteUserModal() {
        this.setState({
            showInviteConfirm: !this.state.showInviteConfirm
        }, () => {
                if (this.state.showInviteConfirm)
                    ModalView("inviteUser");
        });
        window.scrollTo(0, 0);
    }

    showInviteErrorModal() {
        this.setState({
            showInviteErrorConfirm: !this.state.showInviteErrorConfirm
        }, () => {
              if (this.state.showInviteErrorConfirm)
                ModalView("inviteError");
        });
        window.scrollTo(0, 0);
    }

    openInviteErrorModal() {
        this.setState({ modalMessage: `This user email is already being used for the mobile application. Please try another email.`, modalTitle: "Mobile User" }, () => {
            this.showInviteErrorModal();
        });
    }

    onFirstNameChange(e) {
        const firstName = e.target.value;

        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                firstName: firstName
            }
        }));
    }

    onLastNameChange(e) {
        const lastName = e.target.value;

        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                lastName: lastName
            }
        }));
    }

    onRoleChange = (selectedOptions) => {
        let roleListId = this.state.currentUser.roleListId;
        let roleList = this.state.currentUser.roleList.split(", ");
        let rolesToRemove = [];       

        if (this.props.isPartnerAdmin) {
            Roles.partnerAdminRoles.forEach(option => {
                if (!selectedOptions.some(o => o.roleId === option.roleId)) {
                    rolesToRemove.push(option);
                }
            });
        } else {            
            Roles.roles.forEach(option => {
                if (!this.props.isPortalAdmin && option.name === "LW-FC Beta Installer") {
                    return;
                } else if (!selectedOptions.some(o => o.roleId === option.roleId)) {
                    rolesToRemove.push(option);
                }                 
            });
        }

        rolesToRemove.forEach(role => {
            let i1 = roleListId.indexOf(role.roleId.toString());

            if (i1 !== -1) {
                roleListId.splice(i1, 1);
            }

            let i2 = roleList.indexOf(role.name);

            if (i2 !== -1) {
                roleList.splice(i2, 1);
            }
        });

        selectedOptions.forEach(option => {
            if (!roleListId.includes(option.roleId.toString())) {
                roleListId.push(option.roleId.toString());
            }

            if (!roleList.includes(option.name)) {
                roleList.push(option.name);
            }
        });
        if(!roleListId.includes(ota.roleId.toString()) ||! roleList.includes(ota.role))
        {
            roleListId.push(ota.roleId.toString());
            roleList.push(ota.role);   
        }
        if(!roleListId.includes(api.roleId.toString()) || !roleList.includes(api.role))
        {
            roleListId.push(api.roleId.toString());
            roleList.push(api.role);  

        }

        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                roleListId: roleListId,
                roleList: roleList.join(", "),
                roleListIds: roleListId.join(", "),
                roleListIdStr: roleListId.join(", ")
            }
        }));      
    }      

    onDelete() {
        this.deleteUser();
        this.setState({ showDeleteConfirm: !this.state.showDeleteConfirm });
    }

    showDeleteModal() {
        this.setState({
            showDeleteConfirm: !this.state.showDeleteConfirm
        }, () => {
                if (this.state.showDeleteConfirm)
                    ModalView("deleteUser");
        });
        window.scrollTo(0, 0);
    }

    openDeleteUserModal(user) {
        this.setState({ modalMessage: `Are you sure you want to remove ${user.firstName} ${user.lastName}?`, modalTitle: "Please Confirm Removal", currentUser: user }, () => {
            this.showDeleteModal();
        });
    }

    deleteUser() {
        AccountService.disable(this.state.currentUser.email, this.state.currentUser.userId, this.props.currentPartnerId)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        message: "The user was deleted successfully!"
                    }, () => {
                            if (this.state.loading === false) {
                                this.setState({
                                    loading: true
                                }, async () => {
                                    await this.getAccount(this.props.currentPartnerId);
                                });
                            }
                    });
                    Event("Partner User Delete", "User clicked delete button for specific user", "Delete User Clicked");
                }
                else {
                    this.setState({
                        message: "Failed to delete the user!"
                    });
                }

                //this.getAccount(this.props.currentPartnerId);

                setTimeout(() => {
                    this.setState({
                        message: ""
                    });
                }, 3000);
            })
            .catch(e => {
                console.log(e);
            });
    }

    getAccount(id) {
            this.setState({ loading: true }, async () => {
                var startTime = performance.now();
                /*const { currentId } = this.props;
                await AccountService.get(currentId)
                    .then(response => {
                        if (this._isMounted)
                            this.setState({
                                currentAccount: response.data
                            }, async () => {
                                await AccountService.getpartnerinfo(this.state.currentAccount.accountId)
                                    .then(response2 => {
                                        if (this._isMounted)
                                            this.setState({
                                                currentPartner: response2.data, loading: false
                                            }, () => {
                                                    var elapsedTime = performance.now() - startTime;
                                                    Timing("Partner Users Loading", "loading", elapsedTime, "Partner Users Loading");
                                            });
                                    })
                            });
                    })
                    .catch(e => {
                        console.log(e);
                    });*/

                this.setState({ currentPartner: jsPartner });
                await AccountService.getpartnerinfo(id)
                    .then(response => {
                        if (this._isMounted)
                        {
                            if (this.props.roles.includes("SUPER ADMIN") || this.props.roles.includes("ADMIN")) {
                                this.setState({ isAdmin: true });
                            }
                            this.setState({
                                currentPartner: response.data, loading: false
                            }, () => {
                                var elapsedTime = performance.now() - startTime;
                                Timing("Partner Users Loading", "loading", elapsedTime, "Partner Users Loading");
                            });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            })
    }

    mapRoles(roleList) {
        var type = "";
        if (roleList == null) {
            return;
        }
        if (roleList.includes("Partner Admin") && roleList.includes("OTA")) {
            type = "Partner Admin";
        }
        else if (roleList.includes("Technician") && roleList.includes("OTA")) {
            type = "Technician";
        }
        else if (roleList.includes("Scheduler") && roleList.includes("OTA")) {
            type = "Scheduler";
        }
        else {
            type = "Other";
        }
        return type;
    }

    checkIfGlobalAdmin() {
        let userRolesList = this.props?.roles;
        if (!Array.isArray(userRolesList)) {
            userRolesList = JSON.parse(userRolesList);
        }
        if (userRolesList?.includes("ADMIN") && userRolesList?.includes("PARTNER ADMIN")) 
        {
            return 3; // 3 for  both Admin && partner admin role 
        }
        else if (userRolesList?.includes("ADMIN"))
        {
            return 2;  // 2 for admin role 
        }
        else if (userRolesList?.includes("PARTNER ADMIN")) 
	    { 
            return 1; // 1 for partner admin role 
	    }
        else {
            return 0;   // 0 for no admin & partner admin role 
        }
    }

    renderUsers(userList) {
        let tableContent =
            userList.length ?
                <tbody>
                    {userList.map((user, index) =>
                        <tr key={`${user.userId}-${index}`} data={index}>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>{this.mapRoles(user.roleList)}</td>
                            <td style={{ textAlign: 'center' }}><ActiveIcon isActive={user.isActive} /></td>
                            {/*<td>{user.lastLogin}</td>*/}

                            <td>
                                {(!this.state.isCustomerSupport) && (
                                    <div>
                                        <button type="button" className="secondary-btn btn-small" onClick={() => this.openDeleteUserModal(user)}>Delete</button>
                                        <button type="button" className="primary-btn btn-small" onClick={() => this.openEditUserModal(user)}>Edit</button>
                                        <button
                                            type="button"
                                            className="secondary-btn btn-small"
                                            onClick={() => this.showResetPasswordModal(user.email)}>Reset Password</button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            :
            <tbody>
                <tr>
                    <td>No Users!</td>
                </tr>
            </tbody>;

        return (
            <div>
                <br/>
                <div className="table-container" style={{ width: '98%' }}>
                <table className='table table-striped table-bordered table-sm table-hover' aria-labelledby="tabelLabel">
                    <thead>
                        <tr >
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Type</th>
                            <th>Is Active</th>
                            {/*<th>Last Login</th>*/}
                            <th> </th>
                        </tr>
                    </thead>
                    {tableContent}
                </table>
                </div>

                <p className="status-msg">{this.state.message}</p>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        let notPartnerAdminMsg = <div style={{ marginLeft: '10px' }}>
            <br />
            <h5>Must be a Partner Admin to access this page!</h5>
        </div>;

        let contents = this.state.loading ?
            <div className={["loading", classes.loader].join(' ')} style = {{ display: "block" }}>
                <div className="loading-wrapper">
                    <div className="modal-body"><Spinner animation="border" variant="light" /></div>
                </div></div>
            : ((this.props.roles.includes("PARTNER ADMIN") || this.props.roles.includes("ADMIN") || this.props.roles.includes("CUSTOMER SUPPORT")) ?
                this.renderUsers(this.state.currentPartner !== null && this.state.currentPartner !== undefined ? this.state.currentPartner.users : [])
                : notPartnerAdminMsg
            );

        return (
            <div className={classes.root}>
                <div className="infoPage">
                    <div className="row no-gutters">
                        <div className="col">
                            <h3 className="pageTitle" id="tableLabel">Active Users</h3>
                        </div>
                        {(!this.state.isCustomerSupport) && (
                            <div className="col pageTitle">
                                <div className="btn-right">
                                    <button type="Add" className="secondary-btn btn-large" style={{ width: 110 + 'px' }} onClick={this.openInviteUserModal}>+ Invite User</button>
                                </div>
                            </div>)}
                    </div>
                    {contents}

                    <ConfirmationModal show={this.state.showDeleteConfirm} onYes={this.onDelete} onClick={this.showDeleteModal} message={this.state.modalMessage} title={this.state.modalTitle}></ConfirmationModal>

                    <UserEditModal onClick={this.showEditModal} editUser={this.state.currentUser} show={this.state.showEditConfirm} onSave={this.onEdit}
                        onFirstNameChange={this.onFirstNameChange} onLastNameChange={this.onLastNameChange} onRoleChange={this.onRoleChange} isPartnerAdmin={this.props.isPartnerAdmin}
                        isPortalAdmin={this.props.isPortalAdmin} isGlobalAdmin={this.checkIfGlobalAdmin()}>
                    </UserEditModal>

                    <InviteUserModal onClick={this.showInviteUserModal} show={this.state.showInviteConfirm} onInvite={this.onInvite} inviteLoading={this.state.inviteLoading} roles={this.props.roles} isCustomerAccount={false}></InviteUserModal>
                    <HelpModal onClick={this.showInviteErrorModal} show={this.state.showInviteErrorConfirm} ModalTitle={this.state.modalTitle} ModalMessage={this.state.modalMessage} />
                    <ForgotPasswordModal onClick={this.showResetPasswordModal} show={this.state.showResetPassword} currentAccount={null} passwordEmailSent={this.props.passwordEmailSent} emailToReset={this.state.emailToReset} endForgotPassword={this.showResetPasswordModal} isMobile={false} isAdmin={this.state.isAdmin} />
                </div>
            </div>
        );
    }
}

class ActiveIcon extends Component {
    render() {
        if (this.props.isActive === "true") {
            return (
                <img src={require('../../../img/icon-good-16.png')} alt="active"/>
            );
        }
        else {
            return (
                <img src={require('../../../img/icon-unknown-16.png')} alt="inactive"/>
            );
        }
    }
}

export default withStyles(styles)(Users);