import React, {useCallback, useEffect, useRef, useState} from 'react';
import useSignalR from "../../hooks/useSignalR";
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import commentsService from "../../services/comments.service";
import authService from "../../services/authService";
import AlertService from "../../services/alerts.service";

const CommentButton = ({assetId, disabled, onClick}) => {
    const [hasNewComment, setHasNewComment] = useState(false);
    const [comment, setComment] = useState('');
    const [conversationId, setConversationId] = useState(0);
    const mounted = useRef(true);
    
    const handleNewComment = useCallback((message) => {
        setComment(message);
        setHasNewComment(true);
    }, []);

    useSignalR({
        hubUrl: `${process.env.REACT_APP_SIGNALR_HUB_URL}?conversationId=${conversationId}`,
        eventName: 'newComment',
        handleNewMessage: handleNewComment,
        authToken: authService.getToken(),
        enabled: authService.getToken().length > 0 && conversationId > 0});
    
    const handleClick = () => {
        setComment('');
        setHasNewComment(false);
        onClick();
    };
    
    useEffect(() => {
        mounted.current = true;
        if (assetId === undefined || assetId === null || assetId === 0) return;
        commentsService.getallassetcomments(parseInt(assetId)).then(response => {
            if(mounted.current && response.data.length > 0) {
                if (response.data.find(c => c.hasAnswer) !== undefined) {
                    setComment('green');
                }
                else if (response.data.find(c => c.hasQuestion) !== undefined) {
                    setComment('red');
                }
                else if (response.data.find(c => c.hasNewComment) !== undefined) {
                    setComment('orchid');
                }
                else {
                    setComment('');
                }
                setConversationId(response.data.ConvoId);
                setHasNewComment(true);
            }else if (mounted.current && !response.data.length) {
                AlertService.getassetconvoid(parseInt(assetId)).then(response => {
                    setConversationId(parseInt(response.data));
                })
            }
        });
        return () => {
            mounted.current = false;
        }
    },[assetId])
    
    return (
        <button
            className='secondary-btn btn-small' 
            onClick={handleClick} 
            style={{ 
                marginRight: '15px', 
                paddingRight: '10px' 
            }}
            disabled={disabled}
        >
            <span>
            {hasNewComment ? <CircleIcon
                style={{
                    padding: '5px',
                    color: comment
                }}/> : <span style={{padding: '5px'}}> </span>}
            </span>
            Comments
        </button>
    );
};

export default CommentButton;